import React, { useEffect, useRef } from "react";

const TermsAndConditions: React.FC = () => {
  const myRef: any = useRef(null);
  const executeScroll = () => {
    if (myRef && myRef.current) {
      myRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  useEffect(() => {
    executeScroll();
  }, []);

  return (
    <>
      <span ref={myRef}></span>
      <section id="about">
        <div className="container">
          <header className="section-header">
            <h3>Terms and Conditions</h3>
            <p></p>
          </header>
          <div className="row about-container">
            <div className="col-lg-12 content order-lg-1 order-2">
              <p>
                <span style={{ fontSize: 16, marginTop: 10 }}>
                  <span style={{ fontWeight: "bold" }}>
                    1.1 ROLE OF COURSEHARBOUR
                  </span>
                  <br />
                  1.1.1 Courseharbour only provides a technology platform that
                  connects intending students/maritime professionals with
                  colleges. It doesn’t conduct/operate any courses or
                  college/institution User. Courseharbour also doesn’t act as an
                  agent of any college / institution in the process of providing
                  the above-mentioned technology platform services.
                  <br />
                  <br />
                  1.1.2 Courseharbour acts as a facilitator connecting intending
                  students/maritime professionals college / institution offering
                  sale of courses. Accordingly, the contract of sale of services
                  on the Website is strictly a bipartite contract between the
                  User and the college / institution listed on the Website.
                  <br />
                  <br />
                  1.1.3 The course booking voucher which Courseharbour issues to
                  a User is solely based on the information provided or updated
                  by the college/institution regarding the seat availability.
                  <br />
                  <br />
                  1.1.4 The amenities, services, fees, course schedule, course
                  type, seat availability and any other details pertaining to
                  the course are provided by the respective college/institution
                  and Courseharbour has no control over such information
                  provided by the college/institution.
                  <br />
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    1.2 LIMITATION OF LIABILITY OF COURSEHARBOUR
                  </span>
                  <br />
                  <br />
                  In its role as a technology platform to facilitate
                  transactions between the college/institution and the Users,
                  Courseharbour shall not be responsible for the operations of
                  the college/institution including, but not limited to the
                  following:
                  <br />
                  <br />
                  1.2.1 Timely conducting of courses offered.
                  <br />
                  <br />
                  1.2.2. The conduct of college/institution employees,
                  representatives, or agents.
                  <br />
                  <br />
                  1.2.3. The condition of the college/institution infrastructure
                  not being up to the student/maritime professional expectation
                  or as per the description provided by the college/institution.
                  <br />
                  <br />
                  1.2.4. Cancellation of the course due to any reasons.
                  <br />
                  <br />
                  1.2.5. Not providing suitable course related material/books of
                  any sort
                  <br />
                  <br />
                  1.2.6. The college/institution changing a student/maritime
                  professional’s booking for any reason whatsoever.
                  <br />
                  <br />
                  1.2.7. Not issuing course completion certificate on time for
                  any reason whatsoever.
                  <br />
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    1.3. RESPONSIBILITIES OF THE USERS
                  </span>
                  <br />
                  <br />
                  1.3.1 Users are advised to call the college/institution to
                  find out the any information which they may need for the
                  purpose of successfully completing the booked course.
                  <br />
                  <br />
                  1.3.2 At the time of course commencement, Users shall furnish
                  a copy of the booking receipt, and valid identity proof like
                  CDC, INDOS, passport, COC not limited to, issued by a
                  government authority.
                  <br />
                  <br />
                  1.3.3. Users are required to reach the college or online
                  session (in case of online course) at least 15 minutes before
                  the scheduled course start time.
                  <br />
                  <br />
                  1.3.4 All bookings issued shall be non-transferable.
                  <br />
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    1.4. CANCELLATION OF BOOKING
                  </span>
                  <br />
                  <br />
                  1.4.1 Cancellation of booking can be done either through the
                  User’s login in the Courseharbour’s website or mobile
                  application, or by calling on the customer care number.
                  <br />
                  <br />
                  1.4.2 Any cancellation is subject to such cancellation charges
                  as mentioned on the booking.
                </span>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TermsAndConditions;
