import React, { useEffect, useRef, useState } from "react";
import "./Backdrop.css";

interface BackDropProps {
  onClose: () => void;
}

const BackDrop: React.FC<BackDropProps> = ({ onClose }) => {
  useEffect(() => {
    let document: any = window.document;
    document.querySelector("body").style.overflow = "hidden";
    return () => {
      document.querySelector("body").style.overflow = "visible";
    };
  }, []);

  return (
    <>
      <div onClick={onClose} className="backdrop" />
    </>
  );
};

export default BackDrop;
