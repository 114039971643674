import { Dispatch } from "redux";
import { ActionType } from "../action-types";
import { Action, univerCityInterface } from "../actions/universityData";

export const univerCity_Data = (data: Array<univerCityInterface>) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UNIVERSITY_DATA,
      payload: data,
    });
  };
};
