import React, { useEffect, useRef, useState, useCallback } from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import TextInput from "../../../components/ui/TextInput";
import { Link, useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/ui/Button";
import useRazorpay, { RazorpayOptions } from "react-razorpay";
import ReactDrawer from "./../../../components/ui/sliderdrawer";
import { useCourse } from "../../../hooks/useCourse";
import CONFIGURATION from "../../../Config";
import { useSelector } from "react-redux";
import { State } from "../../../state";
import schedule from "../../../state/actions/schedules";
import moment from "moment";
import ReactStars from "react-stars";
import swal from "sweetalert";
import Spinner from "./../../../components/ui/Spinner";
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

interface userEditNumberAndEmail {
  mobileNumber: string;
  email: string;
}

interface AllPromocode {
  id: number;
  couponId: string;
  couponName: string;
  couponDescription: string;
  couponValidity: number;
  couponType: string;
  flatDiscount: number;
  discountPercentage: number;
  cutoffDiscount: number;
  offeredBy: string;
  active: boolean;
  validToDate: string;
  validFromDate: string;
}

const CourseBooking: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isOpen, setisOpen] = useState(false);
  const {
    getCollage,
    getPromocode,
    getCreatePaymentOrder,
    getSchedules,
    getCollageReview,
    applyPromoCode,
    PaymentDone,
  } = useCourse();
  const [ShowDetails, setShowDetails] = useState(false);
  const [promoCodeData, setpromoCodeData] = useState<AllPromocode[]>([]);
  const [scheduleData, setscheduleData] = useState<schedule>();
  const currentUser = useSelector((state: State) => state.currentUser);
  const [emailMobileNumber, setemailMobileNumber] =
    useState<userEditNumberAndEmail>();
  const [reviewData, setreviewData] = useState<{ avgRatings: number }>({
    avgRatings: 0,
  });
  const [promoName, setpromoName] = useState("");
  const [promoId, setpromoId] = useState(0);
  const [setFee, setsetFee] = useState(0);
  const [Razorpay] = useRazorpay();
  const [IsLoading, setIsLoading] = useState(false);
  const [IsLoading2, setIsLoading2] = useState(false);
  const myRef: any = useRef(null);
  const executeScroll = () => {
    if (myRef && myRef.current) {
      myRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  // const [minutes, setMinutes] = useState(6);
  // const [seconds, setSeconds] = useState(59);
  // useEffect(() => {
  //     let myInterval = setInterval(() => {
  //         if (seconds > 0) {
  //             setSeconds(seconds - 1);
  //         }
  //         if (seconds === 0) {
  //             if (minutes === 0) {
  //                 clearInterval(myInterval)
  //                 navigate("/book-courses",{replace: true})
  //             } else {
  //                 setMinutes(minutes - 1);
  //                 setSeconds(59);
  //             }
  //         }
  //     }, 1000)
  //     return () => {
  //         clearInterval(myInterval);
  //     };
  // });

  useEffect(() => {
    executeScroll();
    getSchedulesById();
  }, []);

  const applayPromo = async (name: string) => {
    setIsLoading(true);
    try {
      const data = await applyPromoCode(
        id,
        name,
        currentUser.studentDetails.id.toString()
      );
      setTimeout(() => {
        setsetFee(data.feesTobePaid);
      }, 500);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getSchedulesById = async () => {
    setIsLoading2(true);
    try {
      const data = await getSchedules(id);
      if (data) {
        setIsLoading2(false);
        setscheduleData(data);
      }
    } catch (error) {
      setIsLoading2(false);
    }
  };

  const getReview = async (id: string) => {
    try {
      if (id) {
        const data = await getCollageReview(id);
        if (data) {
          setreviewData(data);
        }
      }
    } catch (error) {}
  };

  const getAllPromocode = async () => {
    try {
      const data = await getPromocode();
      if (data) {
        setpromoCodeData(data);
      }
    } catch (error) {}
  };

  const CreateOrder = async () => {
    setIsLoading(true);
    var options = {
      scheduleId: parseInt(id ? id : ""),
      studentId: currentUser.studentDetails.id,
      totalAmount:
        setFee > 0 ? setFee : scheduleData?.originalCourseDetails.fee,
      couponId: promoId,
    };
    var options2 = {
      scheduleId: id,
      studentId: currentUser.studentDetails.id,
      totalAmount: scheduleData?.originalCourseDetails.fee,
    };

    try {
      const data = await getCreatePaymentOrder(
        promoId != 0 ? options : options2
      );
      setIsLoading(false);
      if (data) {
        handlePayment(data);
      }
    } catch (error: any) {
      swal(error.response.data.responseMessage);
      setIsLoading(false);
    }
  };

  const handlePayment = (data: { amountDue: number; orderId: string }) => {
    const options = {
      timeout: 420,
      key: CONFIGURATION.rzPayKey,
      amount: data.amountDue.toString(),
      currency: "INR",
      name:
        currentUser.studentDetails.firstName +
        " " +
        currentUser.studentDetails.lastName,
      //     description: 'Credits towards consultation',
      //      image: 'https://i.imgur.com/3g7nmJC.png',
      order_id: data.orderId,
      handler: async (res: any) => {
        const body = {
          status: "success",
          internal_order_id: data.orderId,
          razorpay_payment_id: res.razorpay_payment_id,
          razorpay_order_id: res.razorpay_order_id,
          razorpay_signature: res.razorpay_signature,
        };
        try {
          setIsLoading(true);
          const datas = await PaymentDone(body);
          if (datas) {
            setIsLoading(false);
            swal("successful", datas.message, "success");
            navigate(`/booking-detail/${datas.data.id}/booking`, {
              replace: true,
            });
          }
        } catch (error: any) {
          setIsLoading(false);
          swal(error.response.data.responseMessage);
        }
      },
      prefill: {
        name:
          currentUser.studentDetails.firstName +
          " " +
          currentUser.studentDetails.lastName,
        email: emailMobileNumber?.email,
        contact: emailMobileNumber?.mobileNumber,
      },
      theme: {
        color: "#015da2",
      },
    };
    const rzpay = new Razorpay(options);
    rzpay.on("payment.failed", function (response: any) {
      swal(response.error.description, {
        icon: "error",
      });
    });
    rzpay.open();
  };

  if (IsLoading2) {
    return <Spinner />;
  } else {
    return (
      <>
        <span ref={myRef}></span>
        <section id="about">
          <div className="container">
            <header>
              <h3 className="mb-1">Course Booking</h3>
              <p>Your are currently booking below selected course!</p>
            </header>
            <div
              className="mb-5 p-3"
              style={{
                backgroundColor: "white",
                borderRadius: 7,
                border: "1px solid lightgrey",
              }}
            >
              <div style={{ display: "flex", paddingBottom: 10 }}>
                <div style={{ width: "12%" }}>
                  <img
                    className="img-fluid"
                    style={{ borderRadius: 150, height: 60, width: 60 }}
                    src={
                      CONFIGURATION.Image_Base_Url +
                      scheduleData?.collegeCourse.college.collegeImgPath
                    }
                  />
                </div>
                <div
                  style={{
                    width: "88%",
                    paddingLeft: 10,
                    paddingRight: 10,
                    paddingTop: 5,
                  }}
                >
                  <h5 style={{ fontWeight: "bold", marginBottom: 5 }}>
                    {scheduleData?.originalCourseDetails.collegeName}
                  </h5>
                  <span>
                    {scheduleData?.originalCourseDetails.city +
                      ", " +
                      scheduleData?.originalCourseDetails.country +
                      " -" +
                      scheduleData?.originalCourseDetails.zipcode}
                  </span>
                </div>
              </div>
              <div className="text-right">
                <span
                  onClick={() => {
                    if (!reviewData.avgRatings) {
                      getReview(
                        scheduleData?.collegeCourse.id.toString()
                          ? scheduleData?.collegeCourse.id.toString()
                          : ""
                      );
                      setShowDetails(!ShowDetails);
                    } else {
                      setShowDetails(!ShowDetails);
                    }
                  }}
                  style={{
                    fontWeight: "bold",
                    cursor: "pointer",
                    color: "#0a385c",
                    fontSize: 14,
                    textDecoration: "underline",
                  }}
                >
                  {ShowDetails ? "Less" : "More"} Details
                </span>
              </div>
              {ShowDetails ? (
                <div
                  className="row mb-2"
                  style={{ paddingTop: 10, borderTop: "1px solid #e2e2e2" }}
                >
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-4">
                        <span style={{ fontSize: 14, fontWeight: "bold" }}>
                          Course Name:
                        </span>
                      </div>
                      <div className="col-8">
                        <span style={{ fontSize: 14 }}>
                          {scheduleData?.originalCourseDetails.courseName}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <span style={{ fontSize: 14, fontWeight: "bold" }}>
                          Mode:
                        </span>
                      </div>
                      <div className="col-8">
                        <span style={{ fontSize: 14 }}>
                          {scheduleData?.originalCourseDetails.mode}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <span style={{ fontSize: 14, fontWeight: "bold" }}>
                          Date:
                        </span>
                      </div>
                      <div className="col-8">
                        {scheduleData?.originalCourseDetails.mode ==
                        "Blended" ? (
                          <>
                            <span style={{ display: "block" }}>
                              <span
                                style={{
                                  fontSize: 14,
                                  fontWeight: 600,
                                  display: "block",
                                }}
                              >
                                Online
                              </span>
                              <span style={{ fontSize: 14, display: "block" }}>
                                {moment(scheduleData.onlFromDate).format(
                                  "MMM DD"
                                )}{" "}
                                to{" "}
                                {moment(scheduleData.onlToDate).format(
                                  "MMM DD,YYYY"
                                )}
                              </span>
                              <span style={{ fontSize: 12, display: "block" }}>
                                {moment(scheduleData.onlFromDate).format(
                                  "hh:mm"
                                )}{" "}
                                to{" "}
                                {moment(scheduleData.onlToDate).format(
                                  "hh:mm A"
                                )}
                              </span>
                            </span>
                            <span style={{ display: "block", marginTop: 10 }}>
                              <span
                                style={{
                                  fontSize: 14,
                                  fontWeight: 600,
                                  display: "block",
                                }}
                              >
                                Physical
                              </span>
                              <span style={{ fontSize: 14, display: "block" }}>
                                {moment(scheduleData.phyFromDate).format(
                                  "MMM DD"
                                )}{" "}
                                to{" "}
                                {moment(scheduleData.phyToDate).format(
                                  "MMM DD,YYYY"
                                )}
                              </span>
                              <span style={{ fontSize: 12, display: "block" }}>
                                {moment(scheduleData.phyFromDate).format(
                                  "hh:mm"
                                )}{" "}
                                to{" "}
                                {moment(scheduleData.phyToDate).format(
                                  "hh:mm A"
                                )}
                              </span>
                            </span>
                          </>
                        ) : scheduleData?.phyFromDate &&
                          scheduleData?.phyToDate ? (
                          <span style={{ display: "block", marginTop: 10 }}>
                            <span
                              style={{
                                fontSize: 14,
                                fontWeight: 600,
                                display: "block",
                              }}
                            >
                              Physical
                            </span>
                            <span style={{ fontSize: 14, display: "block" }}>
                              {moment(scheduleData.phyFromDate).format(
                                "MMM DD"
                              )}{" "}
                              to{" "}
                              {moment(scheduleData.phyToDate).format(
                                "MMM DD,YYYY"
                              )}
                            </span>
                            <span style={{ fontSize: 12, display: "block" }}>
                              {moment(scheduleData.phyFromDate).format("hh:mm")}{" "}
                              to{" "}
                              {moment(scheduleData.phyToDate).format("hh:mm A")}
                            </span>
                          </span>
                        ) : (
                          <span style={{ display: "block" }}>
                            <span
                              style={{
                                fontSize: 14,
                                fontWeight: 600,
                                display: "block",
                              }}
                            >
                              Online
                            </span>
                            <span style={{ fontSize: 14, display: "block" }}>
                              {moment(scheduleData?.onlFromDate).format(
                                "MMM DD"
                              )}{" "}
                              to{" "}
                              {moment(scheduleData?.onlToDate).format(
                                "MMM DD,YYYY"
                              )}
                            </span>
                            <span style={{ fontSize: 12, display: "block" }}>
                              {moment(scheduleData?.onlFromDate).format(
                                "hh:mm"
                              )}{" "}
                              to{" "}
                              {moment(scheduleData?.onlToDate).format(
                                "hh:mm A"
                              )}
                            </span>
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-4">
                        <span style={{ fontSize: 14, fontWeight: "bold" }}>
                          Seats Available:
                        </span>
                      </div>
                      <div className="col-8">
                        <span style={{ fontSize: 14 }}>
                          {scheduleData?.originalCourseDetails.noOfSeats}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <span style={{ fontSize: 14, fontWeight: "bold" }}>
                          Ratings:
                        </span>
                      </div>
                      <div className="col-8">
                        {/* reviewData */}
                        {reviewData?.avgRatings == 0 ||
                        reviewData?.avgRatings == 0.0 ? (
                          <span style={{ fontSize: 14 }}>
                            Yet to be reviwed!
                          </span>
                        ) : (
                          <ReactStars
                            count={5}
                            value={reviewData?.avgRatings}
                            size={20}
                            edit={false}
                            half={false}
                            color2={"#ffd700"}
                          />
                        )}
                        {/* <span style={{ fontSize: 14 }}>Yet to be reviwed!</span> */}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <span style={{ fontSize: 14, fontWeight: "bold" }}>
                          Fees:
                        </span>
                      </div>
                      <div className="col-8">
                        <span style={{ fontSize: 14 }}>
                          ₹ {scheduleData?.originalCourseDetails.fee}/-
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <Formik
              initialValues={{
                name:
                  currentUser.studentDetails.firstName +
                  " " +
                  currentUser.studentDetails.lastName,
                indosNo: currentUser.studentDetails.indosNumber,
                cdcNo: currentUser.studentDetails.cdcNumber,
                mobileNumber: currentUser.studentDetails.phoneNumber,
                email: currentUser.studentDetails.user.email,
                promoCode: "",
              }}
              validationSchema={Yup.object({
                email: Yup.string()
                  .email("please enter valid email address!")
                  .required("please enter email address!"),
                mobileNumber: Yup.string()
                  .matches(phoneRegExp, "Phone number is not valid")
                  .max(10, "please enter your valid mobile number!")
                  .min(10, "please enter your valid mobile number!")
                  .required("please enter your phone number!"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                setemailMobileNumber(values);
                setSubmitting(false);
              }}
            >
              <Form>
                <div className="row">
                  <div className="col-md-6">
                    <h5 style={{ fontWeight: "bold" }}>Student Profile</h5>
                    <div className="mb-3">
                      <TextInput
                        placeHolder="Student's Name"
                        label="Student's Name"
                        type="text"
                        name="name"
                        disabled
                      />
                    </div>
                    <div className="mb-3">
                      <TextInput
                        placeHolder="INDOS No."
                        label="INDOS No."
                        type="text"
                        name="indosNo"
                        disabled
                      />
                    </div>
                    <div className="mb-3">
                      <TextInput
                        placeHolder="CDC No."
                        label="CDC No."
                        type="text"
                        name="cdcNo"
                        disabled
                      />
                    </div>
                    <div style={{ paddingTop: 15 }}>
                      <h5 style={{ fontWeight: "bold", marginBottom: 0 }}>
                        Contact Details
                      </h5>
                      <span
                        className="mb-3"
                        style={{ display: "block", fontSize: 14 }}
                      >
                        You can change these contact details based on your
                        needs!
                      </span>
                    </div>
                    <div className="mb-3">
                      <TextInput
                        placeHolder="Phone No."
                        label="Phone No."
                        type="text"
                        name="mobileNumber"
                        disabled
                      />
                    </div>
                    <div className="mb-3">
                      <TextInput
                        placeHolder="Email Address"
                        label="Email Id"
                        type="text"
                        name="email"
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <h5 style={{ fontWeight: "bold" }}>Promo Code</h5>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="mb-2">
                          <TextInput
                            placeHolder="Coupon Code"
                            label="Coupon Code"
                            type="text"
                            name="promoCode"
                            value={promoName}
                            onChange={() => {}}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        marginTop: 0,
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span
                        onClick={() => {
                          getAllPromocode();
                          setisOpen(true);
                        }}
                        className="text-primary"
                        style={{
                          fontWeight: "bold",
                          fontSize: 14,
                          cursor: "pointer",
                        }}
                      >
                        View Promocodes
                      </span>
                      {promoId > 0 && (
                        <span
                          onClick={() => {
                            setsetFee(0);
                            setpromoId(0);
                            setpromoName("");
                          }}
                          className="text-danger"
                          style={{
                            fontWeight: "bold",
                            fontSize: 14,
                            cursor: "pointer",
                          }}
                        >
                          Clear coupon
                        </span>
                      )}
                    </div>
                    <div style={{ paddingTop: 20 }}>
                      <p style={{ fontSize: 14 }}>
                        By clicking on proceed to pay, user agree that he/she
                        has read and understood the{" "}
                        <Link
                          to={`/terms-and-conditions`}
                          style={{ fontWeight: "bold" }}
                          className="text-primary"
                        >
                          T & C
                        </Link>
                        ,{" "}
                        <Link
                          to={`/terms-and-conditions`}
                          style={{ fontWeight: "bold" }}
                          className="text-primary"
                        >
                          Privacy Policy
                        </Link>{" "}
                        &{" "}
                        <Link
                          to={`/terms-and-conditions`}
                          style={{ fontWeight: "bold" }}
                          className="text-primary"
                        >
                          User Agreement
                        </Link>{" "}
                        listed on CourseHarbour.
                      </p>
                    </div>
                    <div className="row">
                      <div className="col-md-8 col-6">
                        <h6 style={{ fontWeight: "bold" }}>Total Amount:</h6>
                      </div>
                      <div className="col-md-4 col-6 text-right">
                        <label
                          className="label"
                          style={{
                            display: "block",
                            fontWeight: "bold",
                            fontSize: 18,
                          }}
                        >
                          ₹{" "}
                          {setFee > 0
                            ? setFee
                            : scheduleData?.originalCourseDetails.fee}{" "}
                          /-
                        </label>
                      </div>
                    </div>
                    {/* <div className='row' style={{ paddingBottom: 20 }}>
                                            <div className='col-md-12'>
                                                <span>Time Left: <span style={{ fontWeight: "bold",color: "#FF0000" }}>0{minutes}:{seconds < 10 ?  `0${seconds}` : seconds}</span></span>
                                            </div>
                                        </div> */}
                    <Button
                      title="Proceed to Pay"
                      isLoading={IsLoading}
                      className="btn-block"
                      onClick={() => {
                        CreateOrder();
                      }}
                    />
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </section>
        <ReactDrawer onClose={() => setisOpen(false)} isOpen={isOpen}>
          <div className="container">
            <div className="row" style={{ paddingTop: 20 }}>
              <div className="col-md-8">
                <h6 style={{ fontWeight: "bold" }}>Promo Codes</h6>
              </div>
              <div className="col-md-4 text-right">
                <span
                  onClick={() => setisOpen(false)}
                  className="text-primary"
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                >
                  Close
                </span>
              </div>
            </div>
            <div>
              {promoCodeData.map((item, index) => {
                return (
                  <div
                    className="mb-3 p-3"
                    style={{ border: "1px solid #e2e2e2", borderRadius: 14 }}
                  >
                    <p className="mb-1">
                      <span
                        className="text-primary"
                        style={{ fontWeight: "bold" }}
                      >
                        {item.couponName}
                      </span>{" "}
                      -{" "}
                      <span style={{ color: "red" }}>
                        {item.couponType == "FLAT"
                          ? "₹" + item.flatDiscount + " " + item.couponType
                          : item.discountPercentage + "% " + item.couponType}
                      </span>
                    </p>
                    <p className="mb-3">{item.couponDescription}</p>
                    <span
                      onClick={() => {
                        setpromoName(item.couponName);
                        setisOpen(false);
                        applayPromo(item.couponName);
                        setpromoId(item.id);
                      }}
                      className="d-block btn btn-primary"
                    >
                      Apply
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </ReactDrawer>
      </>
    );
  }
};

export default CourseBooking;
