import { Action, userInterface } from "../actions/userActions";
import { ActionType } from "../action-types/index";

const intitialState = {
  collegeId: null,
  collegeName: null,
  refreshToken: "",
  roles: [],
  token: "",
  userId: 0,
  userName: "",
  isLoggedIn: false,
  studentDetails: {
    id: 0,
    studentId: "",
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    gender: "",
    indosNumber: "",
    cdcNumber: "",
    rank: "",
    sidNo: "",
    phoneNumber: "",
    nationality: "",
    passportNumber: "",
    profileUrl: null,
    registeredDate: "",
    user: {
      id: 0,
      username: "",
      email: "",
      role: {
        id: 0,
        roleName: "",
      },
      isVerified: false,
      active: false,
      dailyNotification: false,
    },
    active: false,
  },
};

const userReducer = (state: userInterface = intitialState, action: Action) => {
  switch (action.type) {
    case ActionType.CURRENT_USER:
      return action.payload;
    case ActionType.UPDATE_STUDENT:
      return {
        ...state,
        studentDetails: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
