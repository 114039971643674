import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../../components/ui/Button";
import TextInput from "../../../components/ui/TextInput";
import * as Yup from "yup";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { State, userActionCreator } from "../../../state";
import { useUser } from "../../../hooks/useUser";
import { useToggle } from "../../../hooks/useToggle";
import swal from "sweetalert";
import CONFIGURATION from "../../../Config";
import moment from "moment";

const Login: React.FC = () => {
  const { IsLoading, setLoadingFalse, setLoadingToggle, setLoadingTrue } =
    useToggle();
  const { login, getStundentDetails } = useUser();
  const navigate = useNavigate();
  const [Verified, setVerified] = useState(false);
  const [NotVerified, setNotVerified] = useState(false);
  const dispatch = useDispatch();
  const { updateCurrentUser } = bindActionCreators(userActionCreator, dispatch);
  const currentUser = useSelector((state: State) => state.currentUser);
  const [rememberMe, setrememberMe] = useState(false);
  const myRef: any = useRef(null);
  const executeScroll = () => {
    if (myRef && myRef.current) {
      myRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  useEffect(() => {
    executeScroll();
    if (window.location.hash.substr(1) === "verified") {
      setVerified(true);
      // setTimeout(() => {
      //     setVerified(false)
      // },5000)
    }
    if (window.location.hash.substr(1) === "notverfied") {
      setNotVerified(true);
      // setTimeout(() => {
      //     setNotVerified(false)
      // },5000)
    }
  }, []);

  return (
    <>
      <span ref={myRef}></span>
      <section id="about">
        <div className="container" style={{ paddingTop: 50 }}>
          <div className="row">
            <div className="col-md-6">
              <header>
                <h3>Login</h3>
                <p>Please login into your existing account!</p>
              </header>
              {Verified && (
                <div className="alert alert-success">
                  <h6
                    className="alert-heading mb-1"
                    style={{ fontWeight: "bold" }}
                  >
                    Verified Success!
                  </h6>
                  <p style={{ fontSize: 14 }} className="mb-0">
                    Your account is now verified!
                  </p>
                </div>
              )}
              {NotVerified && (
                <div className="alert alert-danger">
                  <h6
                    className="alert-heading mb-1"
                    style={{ fontWeight: "bold" }}
                  >
                    Verification failed!
                  </h6>
                  <p style={{ fontSize: 14 }} className="mb-0">
                    Your account is not match to neccessary conditions!
                  </p>
                </div>
              )}
              <Formik
                initialValues={{
                  email: "",
                  password: "",
                }}
                validationSchema={Yup.object({
                  email: Yup.string()
                    .email("please enter valid email address!")
                    .required("please enter email address!"),
                  password: Yup.string().required("Please enter password!"),
                })}
                onSubmit={async (values, { setSubmitting }) => {
                  try {
                    setLoadingTrue();
                    let data = await login(values);
                    setLoadingFalse();
                    if (rememberMe) {
                    }
                    localStorage.setItem(
                      "@coureHarbourLogin",
                      JSON.stringify(data)
                    );
                    localStorage.setItem(
                      "@coureHarbourLoginTime",
                      moment().local().format("YYYY-MM-DD HH:mm:ss")
                    );
                    updateCurrentUser({ ...data, isLoggedIn: true });
                    getStundentDetails();
                  } catch (error: any) {
                    setLoadingFalse();
                    swal(error.response.data.responseMessage, {
                      icon: "error",
                    });
                  }
                }}
              >
                <Form>
                  <div className="mb-3">
                    <TextInput
                      placeHolder="Email Address"
                      label="Email"
                      type="email"
                      name="email"
                    />
                  </div>
                  <div className="mb-3">
                    <TextInput
                      placeHolder="Password"
                      label="Password"
                      type="password"
                      name="password"
                    />
                  </div>
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <label style={{ color: "#015da2" }}>
                      <input
                        type="checkbox"
                        defaultChecked={rememberMe}
                        onChange={(text) => setrememberMe(!rememberMe)}
                        style={{ marginRight: 10, height: 15, width: 15 }}
                      />
                      Remember me
                    </label>
                    <div className="mb-3 text-right">
                      <Link to="/forgot-password">Forgot Password?</Link>
                    </div>
                  </div>
                  <div className="mb-4">
                    <Button
                      title="Login"
                      className="btn-block"
                      isLoading={IsLoading}
                    />
                  </div>
                </Form>
              </Formik>
              <span>
                Don't have an account yet? <Link to="/signup">Sign Up</Link>
              </span>
            </div>
            <div className="col-md-6 text-right d-none d-md-block">
              <img
                style={{ maxHeight: 350 }}
                src={require("./../../../assets/img/Group984.png")}
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
