import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { bindActionCreators } from "redux";
import { useCourse } from "../../../hooks/useCourse";
import {
  courseActionCreator,
  State,
  filterData,
  univercityData,
} from "../../../state";
import { CourseDetail } from "../../../state/actions/courseDataActions";
import ReactDrawer from "./../../../components/ui/sliderdrawer";
import AdvanceSearch from "../../components/bookcourses/AdvanceSearch";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Search from "./../../../assets/img/search.png";

export interface CourseDropdownProps {
  label: string;
  value: string;
}

interface selectCourse {
  label: string;
  value: string;
}

const Header: React.FC = () => {
  const { courses, collages, recentSearch, countryState } = useSelector(
    (state: State) => state.courseFilterData
  );
  const navigate = useNavigate();
  const [isOpen, setisOpen] = useState(false);
  const [isValid, setisValid] = useState(true);
  const { getCourses, getRecentCourses, getUnivercity, getUnivercitybyCourse } =
    useCourse();
  const [SearchCourse, setSearchCourse] = useState<CourseDropdownProps[]>([]);
  const [RecentSearchCourse, setRecentSearchCourse] = useState<
    CourseDropdownProps[]
  >([]);
  const dispatch = useDispatch();
  const { updateCourses, updateRecentSearch } = bindActionCreators(
    courseActionCreator,
    dispatch
  );
  const {
    city,
    state,
    year,
    course,
    courseId,
    insitute,
    month,
    institute,
    instituteId,
  } = useSelector((state: State) => state.filterData);
  const [courseName, setcourseName] = useState("");
  const [course_Id, setcourseId] = useState("");
  const { updatefilterData } = bindActionCreators(filterData, dispatch);
  const { univerCity_Data } = bindActionCreators(univercityData, dispatch);

  useEffect(() => {
    getCourse();
    getrecentCourse();
    localStorage.removeItem("courseHarbour_insitute");
    localStorage.removeItem("courseHarbour_state");
    localStorage.removeItem("courseHarbour_city");
    localStorage.removeItem("courseHarbour_year");
    localStorage.removeItem("courseHarbour_month");
    localStorage.removeItem("courseHarbour_course");
    localStorage.removeItem("courseHarbour_courseId");
    localStorage.removeItem("courseHarbour_instituteName");
    localStorage.removeItem("courseHarbour_instituteId");
    var data = {
      insitute: "",
      state: "",
      city: "",
      year: "",
      month: "",
      course: "",
      courseId: "",
      instituteId: "",
      institute: "",
    };
    updatefilterData(data);
  }, []);

  const getrecentCourse = async () => {
    try {
      if (!(recentSearch.length > 0)) {
        let data1 = await getRecentCourses();
        let arrayObjRecent = data1.map(
          (item: { courseId: string; courseName: string }, index: number) => {
            return {
              label: item.courseName,
              value: JSON.stringify(item.courseId),
            };
          }
        );
        updateRecentSearch(arrayObjRecent);
      }
    } catch (error) {}
  };
  const getCourse = async () => {
    if (courses.length > 0) {
      let arrayObj = courses.map((item: CourseDetail, index: number) => {
        return {
          label: item.courseName,
          value: item.id.toString(),
        };
      });
      setSearchCourse(arrayObj);
    } else {
      try {
        let data = await getCourses();
        let arrayObj = data.map((item: CourseDetail, index: number) => {
          return {
            label: item.courseName,
            value: JSON.stringify(item.id),
          };
        });
        setSearchCourse(arrayObj);
        updateCourses(data);
      } catch (error: any) {}
    }
  };

  useEffect(() => {
    setDataInRdux();
  }, [
    city ||
      state ||
      year ||
      course ||
      courseId ||
      insitute ||
      month ||
      instituteId,
  ]);

  const getData = async (e: selectCourse) => {
    var data = {
      insitute: insitute,
      state: state,
      city: city,
      year: year,
      month: month,
      course: e.label,
      courseId: e.value,
      instituteId: "",
      institute: "",
    };
    updatefilterData(data);
    navigate("/book-courses", {
      state: {
        normalSearch: true,
        course: e,
      },
    });
    // try {
    //     let data = await getUnivercitybyCourse(e.value);
    //     if (data.results) {
    //         univerCity_Data(data.results)
    //         navigate("/book-courses",{
    //             state: {
    //                 normalSearch: true,
    //                 course: e
    //             }
    //         })
    //     } else {
    //         univerCity_Data([])
    //     }
    // } catch (error: any) {

    // }
  };

  const setDataInRdux = async () => {
    if (course) {
      setcourseName(course);
    }
    if (courseId) {
      setcourseId(courseId);
    }
  };

  const customStyles = {
    control: (base: any, state: any) => ({
      ...base,
      // state.isFocused can display different borderColor if you need it
      borderColor: state.isFocused ? "#ddd" : isValid ? "#ddd" : "red",
      // overwrittes hover style
      "&:hover": {
        borderColor: state.isFocused ? "#ddd" : isValid ? "#ddd" : "red",
      },
    }),
  };
  return (
    <>
      <section id="intro" className="clearfix">
        <div className="container">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="intro-info">
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  backgroundColor: "white",
                  justifyContent: "space-between",
                  padding: 3,
                  borderRadius: 5,
                }}
              >
                <div style={{ width: "93%" }}>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    isLoading={false}
                    isClearable={true}
                    placeholder="Book/Search Courses"
                    isSearchable={true}
                    name="color"
                    options={[
                      {
                        label: "Recent Searches",
                        options: recentSearch.slice(0, 3),
                      },
                      {
                        label: "All Courses",
                        options: SearchCourse,
                      },
                    ]}
                    value={
                      course_Id && courseName
                        ? {
                            value: course_Id,
                            label: courseName,
                          }
                        : null
                    }
                    onChange={(e: any) => {
                      setisValid(true);
                      setcourseName(e == null ? "" : e.label);
                      setcourseId(e == null ? "" : e.value);
                    }}
                    styles={customStyles}
                  />
                </div>
                <div
                  onClick={() => {
                    if (course_Id) {
                      localStorage.setItem("courseHarbour_course", courseName);
                      localStorage.setItem("courseHarbour_courseId", course_Id);
                      getData({ label: courseName, value: course_Id });
                    } else {
                      setisValid(false);
                    }
                  }}
                  style={{
                    height: 38,
                    cursor: "pointer",
                    borderRadius: 5,
                    width: 38,
                    backgroundColor: "#0a478c",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "inline-flex",
                  }}
                >
                  <img style={{ height: 25, width: 25 }} src={Search} />
                </div>
              </div>
              <span
                onClick={() => setisOpen(true)}
                style={{
                  display: "block",
                  textAlign: "center",
                  marginTop: 10,
                  color: "white",
                  cursor: "pointer",
                }}
              >
                Advanced Search
              </span>
            </div>
          </div>
        </div>
        <span
          className="d-block text-center"
          style={{ display: "block", color: "white" }}
        >
          <i
            className="fa fa-chevron-down object-downup fa-2x"
            aria-hidden="true"
          ></i>
        </span>
      </section>
      <ReactDrawer onClose={() => setisOpen(false)} isOpen={isOpen}>
        <div className="container">
          {isOpen && (
            <AdvanceSearch
              isHome={true}
              isclose={(res: boolean) => {
                setisOpen(res);
              }}
            />
          )}
        </div>
      </ReactDrawer>
    </>
  );
};

export default Header;
