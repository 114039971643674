import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Button from "../../../components/ui/Button";
import TextInput from "../../../components/ui/TextInput";
import * as Yup from "yup";
import moment from "moment";
import RadioButton from "../../../components/ui/RadioButton";
import Select from "../../../components/ui/Select";
import { useToggle } from "../../../hooks/useToggle";
import { useUser } from "../../../hooks/useUser";
import swal from "sweetalert";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const Register: React.FC = () => {
  const navigate = useNavigate();
  const { IsLoading, setLoadingFalse, setLoadingToggle, setLoadingTrue } =
    useToggle();
  const { register } = useUser();
  const myRef: any = useRef(null);
  const executeScroll = () => {
    if (myRef && myRef.current) {
      myRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  useEffect(() => {
    executeScroll();
  }, []);

  return (
    <>
      <span ref={myRef}></span>
      <section id="about">
        <div className="container" style={{ paddingTop: 50 }}>
          <div className="row">
            <div className="col-md-6">
              <header>
                <h3>Sign Up</h3>
                <p>Welcome, create your new account at Courseharbour.</p>
              </header>
              <Formik
                initialValues={{
                  firstName: "",
                  lastName: "",
                  dateOfBirth: "",
                  indosNumber: "",
                  rank: "",
                  cdcNumber: "",
                  sidNo: "",
                  passportNumber: "",
                  email: "",
                  phoneNumber: "",
                  password: "",
                  gender: "Male",
                  nationality: "India",
                }}
                validationSchema={Yup.object({
                  firstName: Yup.string().required(
                    "please enter your first name!"
                  ),
                  lastName: Yup.string().required(
                    "please enter your last name!"
                  ),
                  dateOfBirth: Yup.string().required(
                    "please enter your birth date!"
                  ),
                  indosNumber: Yup.string().required(
                    "please enter your INDOS No.!"
                  ),
                  rank: Yup.string().required("please enter your rank!"),
                  cdcNumber: Yup.string().required(
                    "please enter your CDC No.!"
                  ),
                  sidNo: Yup.string(),
                  passportNumber: Yup.string().required(
                    "please enter your passport number!"
                  ),
                  email: Yup.string()
                    .email("please enter your valid email address!")
                    .required("please enter your email address!"),
                  phoneNumber: Yup.string()
                    .matches(phoneRegExp, "Phone number is not valid")
                    .max(10, "please enter your valid mobile number!")
                    .min(10, "please enter your valid mobile number!")
                    .required("please enter your phone number!"),
                  password: Yup.string()
                    .min(6, "your password required at least 6 character!")
                    .required("please enter your password!"),
                  confirmPassword: Yup.string()
                    .oneOf(
                      [Yup.ref("password"), null],
                      "your password must match"
                    )
                    .required("please confirm your password!"),
                })}
                onSubmit={async (values, { setSubmitting }) => {
                  try {
                    setLoadingTrue();
                    let data = await register({
                      ...values,
                      phoneNumber: values.phoneNumber,
                      dateOfBirth: moment
                        .utc(values.dateOfBirth, "YYYY-MM-DD")
                        .format("YYYY-MM-DDT00:00Z")
                        .toString(),
                    });
                    if (data) {
                      swal("Email verification link send. Check mail box.", {
                        icon: "success",
                      });
                      navigate("/login");
                    }
                    setLoadingFalse();
                  } catch (error: any) {
                    setLoadingFalse();
                    swal(error.response.data.responseMessage, {
                      icon: "error",
                    });
                  }
                }}
              >
                <Form className="row">
                  <div className="col-md-6 mb-3">
                    <TextInput
                      placeHolder="First name"
                      label="First name"
                      type="text"
                      name="firstName"
                      required
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <TextInput
                      placeHolder="Last name"
                      label="Last name"
                      type="text"
                      name="lastName"
                      required
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <TextInput
                      placeHolder="Date of birth"
                      label="Date of Birth"
                      type="date"
                      name="dateOfBirth"
                      required
                      max={moment().subtract(18, "years").format("YYYY-MM-DD")}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="label" style={{ display: "block" }}>
                      Gender
                    </label>
                    <div className="row" style={{ marginTop: 15 }}>
                      <div className="col-6">
                        <RadioButton
                          label="Male"
                          checked={true}
                          name="gender"
                          value="Male"
                        />
                      </div>
                      <div className="col-6">
                        <RadioButton
                          label="Female"
                          name="gender"
                          value="Female"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <TextInput
                      placeHolder="INDOS No."
                      label="INDOS No."
                      type="text"
                      name="indosNumber"
                      required
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <TextInput
                      placeHolder="CDC No."
                      label="CDC No."
                      type="text"
                      name="cdcNumber"
                      required
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <TextInput
                      placeHolder="SID No."
                      label="SID No."
                      type="text"
                      name="sidNo"
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <TextInput
                      placeHolder="Passport No."
                      label="Passport No."
                      type="text"
                      name="passportNumber"
                      required
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <Select required label="Rank" name="rank">
                      <option value="">Select Rank</option>
                      {[
                        "Other",
                        "Master",
                        "Chief Mate",
                        "Second Mate",
                        "Third Mate",
                        "Deck Cadet",
                        "Chief Engineer",
                        "Second Engineer",
                        "Third Engineer",
                        "Fourth Engineer",
                        "Gas Engineer",
                        "Engine Cadet",
                        "Electro technical officer",
                        "Boatswain",
                        "Pump Man",
                        "Able-Bodied Seaman (AB)",
                        "Ordinary Seaman(OS)",
                        "Welder/Fitter",
                        "Oiler",
                        "Wiper",
                        "Motorman",
                        "Chief Cook",
                        "Chief steward",
                        "Steward",
                        "Dynamic positioning officer(DPO)",
                        "Master DPO",
                        "Chief mate DPO",
                        "Second mate DPO",
                        "Crane Operator",
                        "Rigger",
                        "Doctor",
                        "Rig Medic",
                        "Extra Master",
                        "Marine Survayer",
                        "Security Guard",
                        "Superintendent",
                        "Safety Officer",
                        "Navel Architect",
                        "Offshore Installation Manager(OIM)",
                        "Drilling Superintendent",
                        "Assistant Rig Manager",
                        "Toolpusher",
                        "Driller",
                        "Assistant Driller",
                        "Derrick Man",
                        "Floorman/Roughneck",
                        "Chief Mechanic",
                        "Assistant Mechanic",
                        "Storekeeper",
                        "Painter",
                      ].map((item, index) => {
                        return (
                          <option key={index.toString()} value={item}>
                            {item}
                          </option>
                        );
                      })}
                    </Select>
                  </div>
                  <div className="col-md-6 mb-3">
                    <TextInput
                      placeHolder="Email Id"
                      label="Email Id"
                      type="text"
                      name="email"
                      required
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <TextInput
                      placeHolder="Phone Number"
                      label="Phone Number"
                      type="text"
                      name="phoneNumber"
                      required
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <TextInput
                      placeHolder="Password"
                      label="Password"
                      type="password"
                      name="password"
                      required
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <TextInput
                      placeHolder="Confirm Password"
                      label="Confirm Password"
                      type="password"
                      name="confirmPassword"
                      required
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <TextInput
                      placeHolder="Nationality"
                      label="Nationality"
                      disabled
                      type="text"
                      name="nationality"
                    />
                  </div>
                  <div className="com-md-12 mb-3 px-4">
                    <small className="d-block">
                      By clicking on sign up, user agrees that he/she has read
                      and understood the{" "}
                      <Link to={`/terms-and-conditions`}>T&C</Link>,{" "}
                      <Link to={`/privacy-policy`}>Privacy policy</Link> and
                      <Link to="/user-agreement">User agreement</Link> listed on
                      courseharbour.
                    </small>
                  </div>
                  <div className="mb-4 col-md-12">
                    <Button
                      title="Sign Up"
                      className="btn-block"
                      isLoading={IsLoading}
                    />
                  </div>
                </Form>
              </Formik>
              <span>
                Already have an account? <Link to="/login">Login</Link>
              </span>
            </div>
            <div className="col-md-6 text-right d-none d-md-block">
              <img
                style={{ maxHeight: 350 }}
                src={require("./../../../assets/img/Group984.png")}
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Register;
