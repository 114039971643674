import { Action, siteSettingInterface } from "../actions/index";
import { ActionType } from "../action-types/index";

const intitialState = {
  name: "",
  email: "",
  address: "",
  phone: "",
  menus: [],
};

const bankReducer = (
  state: siteSettingInterface = intitialState,
  action: Action
) => {
  switch (action.type) {
    case ActionType.UPDATE_SITE_SETTINGS:
      return action.payload;
    default:
      return state;
  }
};

export default bankReducer;
