import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../../components/ui/Button";
import TextInput from "../../../components/ui/TextInput";
import * as Yup from "yup";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { State, userActionCreator } from "../../../state";
import { useUser } from "../../../hooks/useUser";
import { useToggle } from "../../../hooks/useToggle";
import swal from "sweetalert";

const Login: React.FC = () => {
  const { IsLoading, setLoadingFalse, setLoadingToggle, setLoadingTrue } =
    useToggle();
  const { resetPassword } = useUser();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { updateCurrentUser } = bindActionCreators(userActionCreator, dispatch);
  const currentUser = useSelector((state: State) => state.currentUser);

  const myRef: any = useRef(null);
  const executeScroll = () => {
    if (myRef && myRef.current) {
      myRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  useEffect(() => {
    executeScroll();
  }, []);

  return (
    <>
      <span ref={myRef}></span>
      <section id="about">
        <div className="container" style={{ paddingTop: 50 }}>
          <div className="row">
            <div className="col-md-6">
              <header>
                <h3>Change Password</h3>
                <p>Please change your password after first login!</p>
              </header>
              <Formik
                initialValues={{
                  otp: "",
                  password: "",
                  confirmPassword: "",
                }}
                validationSchema={Yup.object({
                  otp: Yup.string().required("please enter Otp"),
                  password: Yup.string()
                    .min(6, "your password required at least 6 character!")
                    .required("please enter your password!"),
                  confirmPassword: Yup.string()
                    .oneOf(
                      [Yup.ref("password"), null],
                      "your password must match"
                    )
                    .required("please confirm your password!"),
                })}
                onSubmit={async (values, { setSubmitting }) => {
                  try {
                    var url = new URL(window.location.href);
                    var id = url.searchParams.get("id");
                    if (id) {
                      setLoadingTrue();
                      let data = await resetPassword({
                        ...values,
                        device: "web",
                        id: id,
                      });
                      if (data) {
                        swal("Password reset successfully!", {
                          icon: "success",
                        });
                        navigate("/resetPassword/success");
                      }
                      setLoadingFalse();
                    } else {
                      swal("ID Invalid!", {
                        icon: "error",
                      });
                    }
                    // setLoadingTrue()
                    // let data = await login();
                  } catch (error: any) {
                    setLoadingFalse();
                    swal(error.response.data.responseMessage, {
                      icon: "error",
                    });
                    navigate("/resetPassword/failure");
                  }
                }}
              >
                <Form>
                  <div className="mb-3">
                    <TextInput
                      placeHolder="OTP"
                      label="OTP"
                      type="text"
                      name="otp"
                    />
                  </div>
                  <div className="mb-3">
                    <TextInput
                      placeHolder="Password"
                      label="Password"
                      type="password"
                      name="password"
                    />
                  </div>
                  <div className="mb-3">
                    <TextInput
                      placeHolder="Confirm Password"
                      label="Confirm Password"
                      type="password"
                      name="confirmPassword"
                    />
                  </div>
                  {/* <div className='mb-3 text-right'>
                                        <Link to="/forgot-password">Forgot Password?</Link>
                                    </div> */}
                  <div className="mb-4">
                    <Button
                      title="Save"
                      className="btn-block"
                      isLoading={IsLoading}
                    />
                  </div>
                </Form>
              </Formik>
              <span>
                Don't have an account yet? <Link to="/signup">Sign Up</Link>
              </span>
            </div>
            <div className="col-md-6 text-right">
              <img
                style={{ maxHeight: 350 }}
                src={require("./../../../assets/img/Group984.png")}
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
