import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCourse } from "../../../hooks/useCourse";
import upComingData from "../../../state/actions/upComingIterFace";
import Spinner from "./../../../components/ui/Spinner";
const BookingDetail: React.FC = () => {
  const { id, from } = useParams();
  const { getBookingDetails } = useCourse();

  const navigate = useNavigate();
  const [loder, setloder] = useState(false);
  const [data, setdata] = useState<upComingData>();
  const myRef: any = useRef(null);
  const executeScroll = () => {
    if (myRef && myRef.current) {
      myRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  useEffect(() => {
    executeScroll();
    getdetails();
  }, []);

  const getdetails = async () => {
    setloder(true);
    try {
      const data = await getBookingDetails(id);
      setdata(data);
      setloder(false);
    } catch (error) {
      setloder(false);
    }
  };

  if (loder) {
    return <Spinner />;
  } else {
    return (
      <>
        <span ref={myRef}></span>
        <section id="about">
          <div className="container">
            <header>
              <h3 className="mb-1">Booking Details</h3>
              <p style={{ marginBottom: 10 }}>Manage your bookings!</p>
            </header>
            <span
              onClick={() => {
                if (from === "mybooking") {
                  navigate(-1);
                } else {
                  navigate("/my-bookings", { replace: true });
                }
              }}
              style={{
                cursor: "pointer",
                display: "block",
                marginBottom: 20,
                color: "#004289",
              }}
            >
              <i className="fa fa-arrow-left" aria-hidden="true"></i>
              <span style={{ marginLeft: 10 }}>Go back</span>
            </span>
            <div className="row">
              <div className="col-md-6">
                <div
                  className="mb-3"
                  style={{
                    borderRadius: 14,
                    overflow: "hidden",
                    border: "1px solid lightgrey",
                  }}
                >
                  <div className="p-3" style={{ backgroundColor: "#ebebeb" }}>
                    <h6 style={{ fontWeight: "bold", marginBottom: 0 }}>
                      Date
                    </h6>
                  </div>
                  <div className="p-3">
                    {data?.schedule.originalCourseDetails.mode == "Blended" ? (
                      <>
                        <span style={{ display: "block" }}>
                          <span
                            style={{
                              fontSize: 14,
                              fontWeight: 600,
                              display: "block",
                            }}
                          >
                            Online
                          </span>
                          <span style={{ fontSize: 14, display: "block" }}>
                            {moment(data?.schedule.onlFromDate).format(
                              "MMM DD"
                            )}{" "}
                            to{" "}
                            {moment(data?.schedule.onlToDate).format(
                              "MMM DD,YYYY"
                            )}
                          </span>
                          <span style={{ fontSize: 12, display: "block" }}>
                            {moment(data?.schedule.onlFromDate).format(
                              "hh:mm A"
                            )}{" "}
                            to{" "}
                            {moment(data?.schedule.onlToDate).format("hh:mm A")}
                          </span>
                        </span>
                        <span style={{ display: "block", marginTop: 10 }}>
                          <span
                            style={{
                              fontSize: 14,
                              fontWeight: 600,
                              display: "block",
                            }}
                          >
                            Physical
                          </span>
                          <span style={{ fontSize: 14, display: "block" }}>
                            {moment(data?.schedule.phyFromDate).format(
                              "MMM DD"
                            )}{" "}
                            to{" "}
                            {moment(data?.schedule.phyToDate).format(
                              "MMM DD,YYYY"
                            )}
                          </span>
                          <span style={{ fontSize: 12, display: "block" }}>
                            {moment(data?.schedule.phyFromDate).format(
                              "hh:mm A"
                            )}{" "}
                            to{" "}
                            {moment(data?.schedule.phyToDate).format("hh:mm A")}
                          </span>
                        </span>
                      </>
                    ) : data?.schedule?.phyFromDate &&
                      data?.schedule?.phyToDate ? (
                      <span style={{ display: "block", marginTop: 10 }}>
                        <span
                          style={{
                            fontSize: 14,
                            fontWeight: 600,
                            display: "block",
                          }}
                        >
                          Physical
                        </span>
                        <span style={{ fontSize: 14, display: "block" }}>
                          {moment(data?.schedule.phyFromDate).format("MMM DD")}{" "}
                          to{" "}
                          {moment(data?.schedule.phyToDate).format(
                            "MMM DD,YYYY"
                          )}
                        </span>
                        <span style={{ fontSize: 12, display: "block" }}>
                          {moment(data?.schedule.phyFromDate).format("hh:mm A")}{" "}
                          to{" "}
                          {moment(data?.schedule.phyToDate).format("hh:mm A")}
                        </span>
                      </span>
                    ) : (
                      <span style={{ display: "block" }}>
                        <span
                          style={{
                            fontSize: 14,
                            fontWeight: 600,
                            display: "block",
                          }}
                        >
                          Online
                        </span>
                        <span style={{ fontSize: 14, display: "block" }}>
                          {moment(data?.schedule?.onlFromDate).format("MMM DD")}{" "}
                          to{" "}
                          {moment(data?.schedule?.onlToDate).format(
                            "MMM DD,YYYY"
                          )}
                        </span>
                        <span style={{ fontSize: 12, display: "block" }}>
                          {moment(data?.schedule?.onlFromDate).format(
                            "hh:mm A"
                          )}{" "}
                          to{" "}
                          {moment(data?.schedule?.onlToDate).format("hh:mm A")}
                        </span>
                      </span>
                    )}
                  </div>
                </div>
                <div
                  className="mb-3"
                  style={{
                    borderRadius: 14,
                    overflow: "hidden",
                    border: "1px solid lightgrey",
                  }}
                >
                  <div className="p-3" style={{ backgroundColor: "#ebebeb" }}>
                    <h6 style={{ fontWeight: "bold", marginBottom: 0 }}>
                      Course & College Name
                    </h6>
                  </div>
                  <div className="p-3">
                    <h6 style={{ marginBottom: 5 }}>
                      {data?.schedule.originalCourseDetails.collegeName}
                    </h6>
                    <span style={{ fontSize: 14 }}>
                      {data?.schedule.originalCourseDetails.courseName}
                    </span>
                  </div>
                </div>
                <div
                  className="mb-3"
                  style={{
                    borderRadius: 14,
                    overflow: "hidden",
                    border: "1px solid lightgrey",
                  }}
                >
                  <div className="p-3" style={{ backgroundColor: "#ebebeb" }}>
                    <h6 style={{ fontWeight: "bold", marginBottom: 0 }}>
                      College Address
                    </h6>
                  </div>
                  <div className="p-3">
                    <h6 style={{ marginBottom: 5 }}>
                      {data?.schedule.originalCourseDetails.city +
                        ", " +
                        data?.schedule.originalCourseDetails.state +
                        " -" +
                        data?.schedule.originalCourseDetails.zipcode}
                    </h6>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className="mb-3"
                  style={{
                    borderRadius: 14,
                    overflow: "hidden",
                    border: "1px solid lightgrey",
                  }}
                >
                  <div className="p-3" style={{ backgroundColor: "#ebebeb" }}>
                    <h6 style={{ fontWeight: "bold", marginBottom: 0 }}>
                      Booking ID
                    </h6>
                  </div>
                  <div className="p-3">
                    <h6 style={{ marginBottom: 5 }}>{data?.bookingId}</h6>
                  </div>
                </div>
                <div
                  className="mb-3"
                  style={{
                    borderRadius: 14,
                    overflow: "hidden",
                    border: "1px solid lightgrey",
                  }}
                >
                  <div className="p-3" style={{ backgroundColor: "#ebebeb" }}>
                    <h6 style={{ fontWeight: "bold", marginBottom: 0 }}>
                      Booked On
                    </h6>
                  </div>
                  <div className="p-3">
                    <h6 style={{ marginBottom: 5 }}>
                      {moment(data?.bookedTime).format("DD-MM-YYYY hh:mm A")}
                    </h6>
                  </div>
                </div>
                <div
                  className="mb-3"
                  style={{
                    borderRadius: 14,
                    overflow: "hidden",
                    border: "1px solid lightgrey",
                  }}
                >
                  <div className="p-3" style={{ backgroundColor: "#ebebeb" }}>
                    <h6 style={{ fontWeight: "bold", marginBottom: 0 }}>
                      Student Details
                    </h6>
                  </div>
                  <div className="p-3">
                    <p style={{ marginBottom: 0 }}>
                      {" "}
                      <span style={{ fontWeight: "bold" }}>Name:</span>{" "}
                      {data?.student.firstName + " " + data?.student.lastName}
                    </p>
                    <p style={{ marginBottom: 0 }}>
                      {" "}
                      <span style={{ fontWeight: "bold" }}>INDOS No:</span>{" "}
                      {data?.student.indosNumber}
                    </p>
                    <p style={{ marginBottom: 0 }}>
                      {" "}
                      <span style={{ fontWeight: "bold" }}>CDC No:</span>{" "}
                      {data?.student.cdcNumber}
                    </p>
                  </div>
                </div>
                <div
                  className="mb-3"
                  style={{
                    borderRadius: 14,
                    overflow: "hidden",
                    border: "1px solid lightgrey",
                  }}
                >
                  <div className="p-3" style={{ backgroundColor: "#ebebeb" }}>
                    <h6 style={{ fontWeight: "bold", marginBottom: 0 }}>
                      Payment Details
                    </h6>
                  </div>
                  <div className="p-3">
                    <p style={{ marginBottom: 0 }}>
                      {" "}
                      <span style={{ fontWeight: "bold" }}>
                        Payment Method:
                      </span>{" "}
                      {data?.paymentMode}
                    </p>
                    <p style={{ marginBottom: 0 }}>
                      {" "}
                      <span style={{ fontWeight: "bold" }}>
                        Total Amount:
                      </span>{" "}
                      ₹{data?.totalAmount} /-
                    </p>
                  </div>
                </div>
                {data?.cancellation && (
                  <div
                    className="mb-3"
                    style={{
                      borderRadius: 14,
                      overflow: "hidden",
                      border: "1px solid lightgrey",
                    }}
                  >
                    <div className="p-3" style={{ backgroundColor: "#ebebeb" }}>
                      <h6 style={{ fontWeight: "bold", marginBottom: 0 }}>
                        Return Details
                      </h6>
                    </div>
                    <div className="p-3">
                      <p style={{ marginBottom: 0 }}>
                        {" "}
                        <span style={{ fontWeight: "bold" }}>
                          Cancelled Date:
                        </span>{" "}
                        {moment(data.cancelledTime).format(
                          "DD MMM, YYYY hh:mm A"
                        )}
                      </p>
                      <p style={{ marginBottom: 0 }}>
                        {" "}
                        <span style={{ fontWeight: "bold" }}>
                          Refund Amount:
                        </span>{" "}
                        ₹ {data.cancellation.refundAmount}/-
                      </p>
                      <p style={{ marginBottom: 0 }}>
                        {" "}
                        <span style={{ fontWeight: "bold" }}>
                          Refund Status:
                        </span>{" "}
                        {data.cancellation.refundStatus}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
};

export default BookingDetail;
