import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../../components/ui/Button";
import TextInput from "../../../components/ui/TextInput";
import * as Yup from "yup";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { State, userActionCreator } from "../../../state";
import { useUser } from "../../../hooks/useUser";
import { useToggle } from "../../../hooks/useToggle";
import swal from "sweetalert";

const ForgotPassword: React.FC = () => {
  const { IsLoading, setLoadingFalse, setLoadingToggle, setLoadingTrue } =
    useToggle();
  const { login, getStundentDetails, sendForgotLink } = useUser();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { updateCurrentUser } = bindActionCreators(userActionCreator, dispatch);
  const currentUser = useSelector((state: State) => state.currentUser);

  const myRef: any = useRef(null);
  const executeScroll = () => {
    if (myRef && myRef.current) {
      myRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  useEffect(() => {
    executeScroll();
  }, []);

  return (
    <>
      <span ref={myRef}></span>
      <section id="about">
        <div className="container" style={{ paddingTop: 50 }}>
          <div className="row">
            <div className="col-md-6">
              <header>
                <h3>Forgot Password</h3>
                <p>Reset your account password if you not remembered</p>
              </header>
              <Formik
                initialValues={{
                  email: "",
                }}
                validationSchema={Yup.object({
                  email: Yup.string()
                    .email("please enter valid email address!")
                    .required("please enter email address!"),
                })}
                onSubmit={async (values, { setSubmitting }) => {
                  try {
                    setLoadingTrue();
                    let data = await sendForgotLink({
                      email: values.email,
                      device: "web",
                    });
                    setLoadingFalse();
                    swal(data.responseMessage, {
                      icon: "success",
                    });
                    navigate("/");
                  } catch (error: any) {
                    setLoadingFalse();
                    swal(error.response.data.responseMessage, {
                      icon: "error",
                    });
                  }
                }}
              >
                <Form>
                  <div className="mb-3">
                    <TextInput
                      placeHolder="Email Address"
                      label="Email"
                      type="email"
                      name="email"
                    />
                  </div>
                  <div className="mb-4">
                    <Button
                      title="Send Link"
                      className="btn-block"
                      isLoading={IsLoading}
                    />
                  </div>
                </Form>
              </Formik>
            </div>
            <div className="col-md-6 text-right">
              <img
                style={{ maxHeight: 350 }}
                src={require("./../../../assets/img/Group984.png")}
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ForgotPassword;
