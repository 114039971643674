import React, { FormEvent, useEffect, useRef, useState } from "react";
import StickyBox from "react-sticky-box";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { default as SelectSimple } from "../../../components/ui/Select";
import Select, { ActionMeta, Options } from "react-select";
import Button from "../../../components/ui/Button";
import { CourseDropdownProps } from "../home/Header";
import {
  courseActionCreator,
  State,
  filterData,
  univercityData,
} from "../../../state";
import { useDispatch, useSelector } from "react-redux";
import { CourseDetail } from "../../../state/actions/courseDataActions";
import { useCourse } from "../../../hooks/useCourse";
import { bindActionCreators } from "redux";
import moment from "moment";
import { Link, useLocation, useNavigate } from "react-router-dom";
import swal from "sweetalert";

const monthdata = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

interface sideBarProps {
  isclose: Function;
  isHome?: boolean;
}
interface selectCourse {
  normalSearch: boolean;
  course: {
    label: string;
    value: string;
  };
}

const AdvanceSearch: React.FC<sideBarProps> = (props) => {
  let location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    getCourses,
    getRecentCourses,
    getCollages,
    getUnivercity,
    getUnivercitybyCourse,
  } = useCourse();
  const [SearchCourse, setSearchCourse] = useState<CourseDropdownProps[]>([]);
  const [RecentSearchCourse, setRecentSearchCourse] = useState<
    CourseDropdownProps[]
  >([]);
  const [Cities, setCities] = useState<string[]>([]);
  const [Year, setYear] = useState<number[]>([]);
  const [CollageLoading, setCollageLoading] = useState(false);
  const [Month, setMonth] = useState<string[]>([]);
  const { updateCourses, updateCollages, updateRecentSearch } =
    bindActionCreators(courseActionCreator, dispatch);
  const { updatefilterData } = bindActionCreators(filterData, dispatch);
  const { univerCity_Data } = bindActionCreators(univercityData, dispatch);
  const { courses, collages, recentSearch, countryState } = useSelector(
    (state: State) => state.courseFilterData
  );
  const [courseName, setcourseName] = useState("");
  const [course_Id, setcourseId] = useState(
    localStorage.getItem("courseHarbour_courseId") || ""
  );
  const { universityData } = useSelector(
    (state: State) => state.universityData
  );
  const [InstituteName, setInstituteName] = useState<string>(
    localStorage.getItem("courseHarbour_instituteName") || ""
  );
  const [Institute_Id, setInstituteId] = useState<string>(
    localStorage.getItem("courseHarbour_instituteId") || ""
  );
  const {
    city,
    state,
    year,
    course,
    courseId,
    insitute,
    month,
    institute,
    instituteId,
  } = useSelector((state: State) => state.filterData);
  const [IsLoading, setIsLoading] = useState(false);
  const myRef: any = useRef(null);
  const executeScroll = () => {
    if (myRef && myRef.current) {
      myRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  useEffect(() => {
    executeScroll();
    fillCourses();
    if (!location.state) {
      getUnivercitydataAsync({
        state,
        city,
        year,
        month,
      });
    } else {
      getData(location.state);
    }
    getrecentCourse();
  }, []);

  const getData = async (e: any) => {
    if (e && e.normalSearch) {
      var data = {
        insitute: insitute,
        state: state,
        city: city,
        year: year,
        month: month,
        course: e.course.label,
        courseId: e.course.value,
        instituteId: "",
        institute: "",
      };
      updatefilterData(data);
      try {
        let data = await getUnivercitybyCourse(e.course.value);
        if (data.results) {
          univerCity_Data(data.results);
        } else {
          univerCity_Data([]);
        }
      } catch (error: any) {}
    }
  };

  const getrecentCourse = async () => {
    try {
      if (!(recentSearch.length > 0)) {
        let data1 = await getRecentCourses();
        let arrayObjRecent = data1.map(
          (item: { courseId: string; courseName: string }, index: number) => {
            return {
              label: item.courseName,
              value: JSON.stringify(item.courseId),
            };
          }
        );
        updateRecentSearch(arrayObjRecent);
      }
    } catch (error) {}
  };
  const fillCourses = async () => {
    let currentYear = new Date().getFullYear();
    let myYear = [currentYear, currentYear + 1, currentYear + 2];
    setYear(myYear);
    if (courses.length > 0) {
      let arrayObj = courses.map((item: CourseDetail, index: number) => {
        return {
          label: item.courseName,
          value: JSON.stringify(item.id),
        };
      });
      setSearchCourse(arrayObj);
    } else {
      try {
        let data = await getCourses();
        let arrayObj = data.map((item: CourseDetail, index: number) => {
          return {
            label: item.courseName,
            value: JSON.stringify(item.id),
          };
        });
        setSearchCourse(arrayObj);
        updateCourses(data);
      } catch (error: any) {}
    }
  };

  useEffect(() => {
    if (state) {
      let filterCity = countryState.filter(
        (item, index) => item.name === state
      );
      setCities(filterCity[0].city);
    }
    if (year) {
      let myMonth = [];
      if (new Date().getFullYear() === parseInt(year)) {
        for (var i = 0; i < monthdata.length; i++) {
          if (new Date().getMonth() <= i) {
            myMonth.push(monthdata[i]);
          }
        }
        setMonth(myMonth);
      } else {
        setMonth(monthdata);
      }
    } else {
      let myMonth = [];
      if (
        new Date().getFullYear() === parseInt(moment(new Date()).format("YYYY"))
      ) {
        for (var i = 0; i < monthdata.length; i++) {
          if (new Date().getMonth() <= i) {
            myMonth.push(monthdata[i]);
          }
        }
        setMonth(myMonth);
      } else {
        setMonth(monthdata);
      }
    }
    setcourseId(courseId);
    setcourseName(course);
  }, [
    city ||
      state ||
      year ||
      course ||
      courseId ||
      insitute ||
      month ||
      instituteId,
  ]);

  const fillCollages = async () => {
    if (!(collages.length > 0)) {
      try {
        setCollageLoading(true);
        let data = await getCollages();
        updateCollages(data);
        setCollageLoading(false);
      } catch (error: any) {
        setCollageLoading(false);
      }
    }
  };

  const saveFilterDatainRedux = async (dataFilter: any) => {
    const data: any = {
      ...dataFilter,
      course: courseName,
      courseId: course_Id,
      institute: InstituteName,
      instituteId: Institute_Id,
    };
    localStorage.setItem("courseHarbour_insitute", data.insitute);
    localStorage.setItem("courseHarbour_state", data.state);
    localStorage.setItem("courseHarbour_city", data.city);
    localStorage.setItem("courseHarbour_year", data.year);
    localStorage.setItem("courseHarbour_month", data.month);
    localStorage.setItem("courseHarbour_course", data.course);
    localStorage.setItem("courseHarbour_courseId", data.courseId);
    localStorage.setItem("courseHarbour_instituteName", data.institute);
    localStorage.setItem("courseHarbour_instituteId", data.instituteId);
    updatefilterData(data);
  };

  const ResetRedixData = async () => {
    const data: any = {
      insitute: "",
      state: "",
      city: "",
      year: "",
      month: "",
      course: "",
      courseId: "",
      institute: "",
      instituteId: "",
    };
    localStorage.removeItem("courseHarbour_insitute");
    localStorage.removeItem("courseHarbour_state");
    localStorage.removeItem("courseHarbour_city");
    localStorage.removeItem("courseHarbour_year");
    localStorage.removeItem("courseHarbour_month");
    localStorage.removeItem("courseHarbour_course");
    localStorage.removeItem("courseHarbour_courseId");
    localStorage.removeItem("courseHarbour_instituteName");
    localStorage.removeItem("courseHarbour_instituteId");
    setcourseId("");
    setcourseName("");
    setInstituteName("");
    setInstituteId("");
    updatefilterData(data);
  };

  const getUnivercitydataAsync = async (data: {
    state: string;
    city: string;
    year: string;
    month: string;
  }) => {
    console.log("Milan: ", universityData);

    var arr = [];
    if (data.year) {
      arr.push(`year=${data.year}`);
    }
    if (data.year == "") {
      arr.push(`year=${moment(new Date()).format("YYYY")}`);
    }
    if (data.month) {
      arr.push(`month=${data.month}`);
    }
    if (data.city) {
      arr.push(`city=${data.city}`);
    }
    if (data.state) {
      arr.push(`state=${data.state}`);
    }
    if (course_Id) {
      arr.push(`courseId=${course_Id}`);
    }
    if (Institute_Id) {
      arr.push(`collegeId=${Institute_Id}`);
    }
    if (course_Id || Institute_Id) {
      var join = arr.join("&");
      try {
        let data = await getUnivercity(join);
        if (data.results) {
          setIsLoading(false);
          // props.isclose(false)
          univerCity_Data(data.results);
        } else {
          univerCity_Data([]);
          setIsLoading(false);
          // props.isclose(false)
        }
      } catch (error: any) {}
    }
  };

  const getUnivercitydata = async (data: {
    state: string;
    city: string;
    year: string;
    month: string;
  }) => {
    var arr = [];
    if (data.year) {
      arr.push(`year=${data.year}`);
    }
    if (data.year == "") {
      arr.push(`year=${moment(new Date()).format("YYYY")}`);
    }
    if (data.month) {
      arr.push(`month=${data.month}`);
    }
    if (data.city) {
      arr.push(`city=${data.city}`);
    }
    if (data.state) {
      arr.push(`state=${data.state}`);
    }
    if (course_Id) {
      arr.push(`courseId=${course_Id}`);
    }
    if (Institute_Id) {
      arr.push(`collegeId=${Institute_Id}`);
    }
    if (course_Id || Institute_Id) {
      var join = arr.join("&");
      try {
        let data = await getUnivercity(join);
        if (data.results) {
          setIsLoading(false);
          props.isclose(false);
          univerCity_Data(data.results);
          navigate("/book-courses");
        } else {
          univerCity_Data([]);
          setIsLoading(false);
          props.isclose(false);
        }
      } catch (error: any) {}
    }
  };

  return (
    <>
      <StickyBox
        offsetTop={80}
        style={{ backgroundColor: "white", padding: 10, borderRadius: 7 }}
        offsetBottom={20}
      >
        <div className="row" style={{ marginTop: 10 }}>
          <div className="col-6">
            <span style={{ fontWeight: "bold", display: "block" }}>
              Advance Search
            </span>
          </div>
          <div className="col-6 text-right">
            {props.isHome && (
              <span
                onClick={() => props.isclose(false)}
                className="text-primary"
                style={{ textDecoration: "underline", cursor: "pointer" }}
              >
                Close
              </span>
            )}
          </div>
        </div>
        <div style={{ marginTop: 20 }}>
          <div className="mb-3">
            <label className="label" style={{ display: "block" }}>
              Course
            </label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              isLoading={false}
              isClearable={true}
              placeholder="Book/Search Courses"
              isSearchable={true}
              name="course"
              options={[
                {
                  label: "Recent Searches",
                  options: recentSearch.slice(0, 3),
                },
                {
                  label: "All Courses",
                  options: SearchCourse,
                },
              ]}
              value={
                course_Id && courseName
                  ? {
                      value: course_Id,
                      label: courseName,
                    }
                  : null
              }
              onChange={(e: any) => {
                setcourseName(e == null ? "" : e.label);
                setcourseId(e == null ? "" : e.value);
              }}
            />
          </div>
          <div className="mb-3">
            <label className="label" style={{ display: "block" }}>
              Institute
            </label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              isLoading={CollageLoading}
              isClearable={true}
              onFocus={() => fillCollages()}
              placeholder="Select Institute"
              isSearchable={true}
              name="insitute"
              options={collages}
              value={
                InstituteName && Institute_Id
                  ? {
                      value: Institute_Id,
                      label: InstituteName,
                    }
                  : null
              }
              onChange={(e: any) => {
                setInstituteName(e == null ? "" : e.label);
                setInstituteId(e == null ? "" : e.value);
              }}
            />
          </div>
          <Formik
            initialValues={{
              state: state,
              city: city,
              year: year ? year : moment(new Date()).format("YYYY"),
              month: month,
            }}
            validationSchema={Yup.object({
              state: Yup.string().notRequired(),
              city: Yup.string().notRequired(),
              year: Yup.string().notRequired(),
              month: Yup.string().notRequired(),
            })}
            onSubmit={(values, { setSubmitting }) => {
              if (Institute_Id || course_Id) {
                setIsLoading(true);
                saveFilterDatainRedux(values);
                if (!props.isHome) {
                  getUnivercitydata(values);
                } else {
                  navigate("/book-courses");
                }
                setSubmitting(false);
              } else {
                swal("Please select either course or institute.", {
                  icon: "error",
                });
              }
            }}
            enableReinitialize
          >
            {({ setFieldValue, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                {/* <div className='mb-3'>
                                    <SelectSimple
                                        label='Insitute'
                                        name="insitute"
                                    >
                                        <option value="">Select Insitute</option>
                                        {collages.map((item, index) => <option key={index.toString()} value={item.id}>{item.collegeName}</option>)}
                                    </SelectSimple>
                                </div> */}
                <div className="mb-3">
                  <SelectSimple
                    label="State"
                    name="state"
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                      let filterCity = countryState.filter(
                        (item, index) => item.name === e.currentTarget.value
                      );
                      setFieldValue("state", e.currentTarget.value);
                      setCities(filterCity[0].city);
                    }}
                  >
                    <option value="">Select State</option>
                    {countryState.map((item, index) => (
                      <option key={index.toString()} value={item.name}>
                        {item.name}
                      </option>
                    ))}
                  </SelectSimple>
                </div>
                <div className="mb-3">
                  <SelectSimple label="City" name="city">
                    <option value="">Select City</option>
                    {Cities.map((item, index) => (
                      <option key={index.toString()} value={item}>
                        {item}
                      </option>
                    ))}
                  </SelectSimple>
                </div>
                <div className="mb-3">
                  <SelectSimple
                    label="Year"
                    name="year"
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                      let myMonth = [];
                      if (
                        new Date().getFullYear() ===
                        parseInt(e.currentTarget.value)
                      ) {
                        for (var i = 0; i < monthdata.length; i++) {
                          if (new Date().getMonth() <= i) {
                            myMonth.push(monthdata[i]);
                          }
                        }
                        setMonth(myMonth);
                      } else {
                        setMonth(monthdata);
                      }
                      setFieldValue("year", e.currentTarget.value);
                    }}
                  >
                    <option value="">Select Year</option>
                    {Year.map((item, index) => (
                      <option key={index.toString()} value={item}>
                        {item}
                      </option>
                    ))}
                  </SelectSimple>
                </div>
                <div className="mb-3">
                  <SelectSimple label="In the month of" name="month">
                    <option value="">Select Month</option>
                    {Month.map((item, index) => (
                      <option key={index.toString()} value={item}>
                        {item}
                      </option>
                    ))}
                  </SelectSimple>
                </div>
                <div className="row">
                  <div className="col-6">
                    <Button
                      title="Search"
                      className="btn-block"
                      isLoading={IsLoading}
                      onClick={() => {
                        executeScroll();
                      }}
                    />
                  </div>
                  <div className="col-6">
                    <span
                      onClick={() => {
                        ResetRedixData();
                        setFieldValue("state", "");
                        setFieldValue("insitute", "");
                        setFieldValue("city", "");
                        setFieldValue(
                          "month",
                          moment(new Date()).format("MMMM")
                        );
                        setFieldValue(
                          "year",
                          moment(new Date()).format("YYYY")
                        );
                      }}
                      className="btn btn-default btn-block text-danger text-right"
                    >
                      Reset
                    </span>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </StickyBox>
    </>
  );
};

export default AdvanceSearch;
