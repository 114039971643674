import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import ReactStars from "react-stars";
import swal from "sweetalert";
import ReactDrawer from "./../../../components/ui/sliderdrawer";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import TextInput from "../../../components/ui/TextInput";
import TextareaInput from "../../../components/ui/TextareaInput";
import Button from "../../../components/ui/Button";
import upcommingData from "./../../../state/actions/upComingIterFace";
import CONFIGURATION from "../../../Config";
import moment from "moment";
import { State } from "../../../state";
import { useSelector } from "react-redux";
import { useCourse } from "../../../hooks/useCourse";
import axios from "axios";
interface CourseCardProps {
  share?: boolean;
  cancelled?: boolean;
  review?: boolean;
  refund?: boolean;
  item: upcommingData;
  reloadData: Function;
}

const CourseCard: React.FC<CourseCardProps> = ({
  share,
  cancelled,
  refund,
  review,
  item,
  reloadData,
}) => {
  const { deleteBooking, exportBooking, reviewSubmit } = useCourse();
  const [IsLoading, setIsLoading] = useState(false);
  const [IsLoadingForSharing, setIsLoadingForSharing] = useState(false);
  const [isOpen, setisOpen] = useState(false);
  const [reviewNumber, setreviewNumber] = useState(0);
  const [reviewData, setreviewData] = useState<{
    title: string;
    message: string;
  }>();
  const currentUser = useSelector((state: State) => state.currentUser);
  const delete_Booking = async () => {
    setIsLoading(true);
    try {
      var body = {
        studentId: currentUser.studentDetails.id,
        bookingId: item.id,
      };
      const data = await deleteBooking(body);
      setIsLoading(false);
      if (data) {
        reloadData(true);
        swal(data.responseMessage, {
          icon: "success",
        });
      }
    } catch (error: any) {
      setIsLoading(false);
    }
  };

  const submitReview = async (data: { title: string; message: string }) => {
    const body = {
      title: data?.title,
      description: data?.message,
      stars: reviewNumber,
      schedule: {
        id: item.schedule.id,
      },
    };
    if (reviewNumber > 0) {
      try {
        const data = await reviewSubmit(body);
        if (data) {
          setisOpen(false);
          setIsLoading(false);
          reloadData(true);
          swal("Review submited successfully", {
            icon: "success",
          });
        }
      } catch (error) {
        setisOpen(false);
        setIsLoading(false);
      }
    } else {
      swal("Please select stars!");
    }
  };

  const getBokkingPdf = async (filename: string) => {
    let getstorageData = localStorage.getItem("@coureHarbourLogin") || "";
    setIsLoadingForSharing(true);
    try {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/bookings/exportBooking/${item.id}/${currentUser.studentDetails.id}`,
          {
            responseType: "arraybuffer",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/pdf",
              Authorization: "Bearer " + JSON.parse(getstorageData).token,
            },
          }
        )
        .then((response) => {
          setIsLoadingForSharing(false);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${filename}.pdf`); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          setIsLoadingForSharing(false);
        });
    } catch (error) {
      setIsLoadingForSharing(false);
    }
  };

  return (
    <>
      <div
        className="mb-3"
        style={{
          backgroundColor: "white",
          borderRadius: 7,
          overflow: "hidden",
          border: "1px solid lightgrey",
        }}
      >
        <div className="p-3">
          <div style={{ display: "flex", paddingBottom: 10 }}>
            <div style={{ width: "12%" }}>
              {item.schedule.collegeCourse.college.collegeImgPath ? (
                <img
                  className="img-fluid"
                  style={{ borderRadius: 150, height: 55, width: 55 }}
                  src={
                    CONFIGURATION.Image_Base_Url +
                    item.schedule.collegeCourse.college.collegeImgPath
                  }
                />
              ) : (
                <span
                  style={{
                    height: 55,
                    width: 55,
                    borderRadius: 150,
                    backgroundColor: "#0a385c",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: 22,
                    color: "white",
                  }}
                >
                  {item.schedule.collegeCourse.college.collegeName.substring(
                    0,
                    1
                  )}
                </span>
              )}
            </div>
            <div
              style={{
                width: "88%",
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 5,
              }}
            >
              <h5 style={{ fontWeight: "bold", marginBottom: 5 }}>
                {item.schedule.collegeCourse.college.collegeName}
              </h5>
              <span>
                {item.schedule.collegeCourse.college.city +
                  ", " +
                  item.schedule.collegeCourse.college.state +
                  " - " +
                  item.schedule.collegeCourse.college.zipCode}
              </span>
            </div>
          </div>
          <div
            className="row mb-2"
            style={{ paddingTop: 10, borderTop: "1px solid #e2e2e2" }}
          >
            <div className="col-md-6">
              <div className="row">
                <div className="col-4">
                  <span style={{ fontSize: 14, fontWeight: "bold" }}>
                    Course Name:
                  </span>
                </div>
                <div className="col-8">
                  <span style={{ fontSize: 14 }}>
                    {item.schedule.collegeCourse.course.courseName}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <span style={{ fontSize: 14, fontWeight: "bold" }}>
                    Mode:
                  </span>
                </div>
                <div className="col-8">
                  <span style={{ fontSize: 14 }}>
                    {item.schedule.originalCourseDetails.mode}
                  </span>
                </div>
              </div>
              {!item.schedule?.collegeCourse?.isLead &&
                !item.schedule?.collegeCourse?.specialCourse && (
                  <div className="row">
                    <div className="col-4">
                      <span style={{ fontSize: 14, fontWeight: "bold" }}>
                        Date:
                      </span>
                    </div>
                    <div className="col-8">
                      {item.schedule.originalCourseDetails.mode == "Blended" ? (
                        <>
                          <span style={{ display: "block" }}>
                            <span
                              style={{
                                fontSize: 14,
                                fontWeight: 600,
                                display: "block",
                              }}
                            >
                              Online
                            </span>
                            <span style={{ fontSize: 14, display: "block" }}>
                              {moment(item.schedule.onlFromDate).format(
                                "MMM DD"
                              )}{" "}
                              to{" "}
                              {moment(item.schedule.onlToDate).format(
                                "MMM DD,YYYY"
                              )}
                            </span>
                            <span style={{ fontSize: 12, display: "block" }}>
                              {moment(item.schedule.onlFromDate).format(
                                "hh:mm A"
                              )}{" "}
                              to{" "}
                              {moment(item.schedule.onlToDate).format(
                                "hh:mm A"
                              )}
                            </span>
                          </span>
                          <span style={{ display: "block", marginTop: 10 }}>
                            <span
                              style={{
                                fontSize: 14,
                                fontWeight: 600,
                                display: "block",
                              }}
                            >
                              Physical
                            </span>
                            <span style={{ fontSize: 14, display: "block" }}>
                              {moment(item.schedule.phyFromDate).format(
                                "MMM DD"
                              )}{" "}
                              to{" "}
                              {moment(item.schedule.phyToDate).format(
                                "MMM DD,YYYY"
                              )}
                            </span>
                            <span style={{ fontSize: 12, display: "block" }}>
                              {moment(item.schedule.phyFromDate).format(
                                "hh:mm A"
                              )}{" "}
                              to{" "}
                              {moment(item.schedule.phyToDate).format(
                                "hh:mm A"
                              )}
                            </span>
                          </span>
                        </>
                      ) : item.schedule?.phyFromDate &&
                        item.schedule?.phyToDate ? (
                        <span style={{ display: "block", marginTop: 10 }}>
                          <span
                            style={{
                              fontSize: 14,
                              fontWeight: 600,
                              display: "block",
                            }}
                          >
                            Physical
                          </span>
                          <span style={{ fontSize: 14, display: "block" }}>
                            {moment(item.schedule.phyFromDate).format("MMM DD")}{" "}
                            to{" "}
                            {moment(item.schedule.phyToDate).format(
                              "MMM DD,YYYY"
                            )}
                          </span>
                          <span style={{ fontSize: 12, display: "block" }}>
                            {moment(item.schedule.phyFromDate).format(
                              "hh:mm A"
                            )}{" "}
                            to{" "}
                            {moment(item.schedule.phyToDate).format("hh:mm A")}
                          </span>
                        </span>
                      ) : (
                        <span style={{ display: "block" }}>
                          <span
                            style={{
                              fontSize: 14,
                              fontWeight: 600,
                              display: "block",
                            }}
                          >
                            Online
                          </span>
                          <span style={{ fontSize: 14, display: "block" }}>
                            {moment(item.schedule?.onlFromDate).format(
                              "MMM DD"
                            )}{" "}
                            to{" "}
                            {moment(item.schedule?.onlToDate).format(
                              "MMM DD,YYYY"
                            )}
                          </span>
                          <span style={{ fontSize: 12, display: "block" }}>
                            {moment(item.schedule?.onlFromDate).format(
                              "hh:mm A"
                            )}{" "}
                            to{" "}
                            {moment(item.schedule?.onlToDate).format("hh:mm A")}
                          </span>
                        </span>
                      )}
                    </div>
                  </div>
                )}
              {item?.schedule?.collegeCourse?.specialCourse && (
                <div className="row">
                  <div className="col-4">
                    <span style={{ fontSize: 14, fontWeight: "bold" }}>
                      Remark:
                    </span>
                  </div>
                  <div className="col-8">
                    <span
                      style={{
                        fontSize: 14,
                        fontWeight: "bold",
                        display: "block",
                      }}
                    >
                      From:
                    </span>
                    <span style={{ fontSize: 14, display: "block" }}>
                      {item?.schedule?.collegeCourse?.fromRemarks}
                    </span>
                    <span
                      style={{
                        fontSize: 14,
                        fontWeight: "bold",
                        display: "block",
                      }}
                    >
                      To:
                    </span>
                    <span style={{ fontSize: 14, display: "block" }}>
                      {item?.schedule?.collegeCourse?.toRemarks}
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div className="col-md-6">
              {/* <div className='row'>
                                <div className='col-4'>
                                    <span style={{ fontSize: 14, fontWeight: "bold" }}>Seats Available:</span>
                                </div>
                                <div className='col-8'>
                                    <span style={{ fontSize: 14 }}>{item.schedule.originalCourseDetails.noOfSeats}</span>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-4'>
                                    <span style={{ fontSize: 14, fontWeight: "bold" }}>Fees:</span>
                                </div>
                                <div className='col-8'>
                                    <span style={{ fontSize: 14 }}>₹ {item.schedule.originalCourseDetails.fee}/-</span>
                                </div>
                            </div> */}
              <div className="row">
                <div className="col-4">
                  <span style={{ fontSize: 14, fontWeight: "bold" }}>
                    Booking ID:
                  </span>
                </div>
                <div className="col-8">
                  <span style={{ fontSize: 14 }}>{item.bookingId}</span>
                </div>
              </div>
              {refund && (
                <div className="row">
                  <div className="col-4">
                    <span style={{ fontSize: 14, fontWeight: "bold" }}>
                      Refund status:
                    </span>
                  </div>
                  <div className="col-8">
                    {item?.cancellation?.refundStatus}
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col-4">
                  <span style={{ fontSize: 14, fontWeight: "bold" }}>
                    Booked On:
                  </span>
                </div>
                <div className="col-8">
                  <span style={{ fontSize: 14 }}>
                    {moment(item?.bookedTime).format("DD-MM-YYYY hh:mm A")}
                  </span>
                </div>
              </div>
            </div>
          </div>
          {review && (
            <div>
              <div
                className="row mb-2"
                style={{ paddingTop: 10, borderTop: "1px solid #e2e2e2" }}
              >
                <div className="col-md-12">
                  {item.review && (
                    <>
                      <ReactStars
                        count={5}
                        value={item.review.stars}
                        size={24}
                        edit={false}
                        half={false}
                        color2={"#ffd700"}
                      />
                      <h6 style={{ fontWeight: "bold", marginBottom: 0 }}>
                        {item.review.title}
                      </h6>
                      <span className="d-block mt-1" style={{ fontSize: 14 }}>
                        {item.review.description}
                      </span>
                    </>
                  )}
                  {!item.review && (
                    <span
                      onClick={() => setisOpen(true)}
                      style={{ fontSize: 14 }}
                    >
                      Rating not given yet{" "}
                      <span
                        style={{ fontWeight: "bold", cursor: "pointer" }}
                        className="text-primary"
                      >
                        Click to submit!
                      </span>{" "}
                    </span>
                  )}
                </div>
              </div>
            </div>
          )}

          <div
            className="row"
            style={{
              paddingTop: 10,
              borderTop: "1px solid #e2e2e2",
              justifyContent: "space-between",
            }}
          >
            {share && (
              <div className="col-4">
                <button
                  disabled={IsLoadingForSharing}
                  onClick={() => {
                    getBokkingPdf(item.bookingId);
                  }}
                  className="btn btn-primary btn-block btn-sm"
                >
                  {IsLoadingForSharing ? "Processing..." : "Download"}
                </button>
              </div>
            )}
            {cancelled && (
              <div className="col-4 text-center">
                <button
                  onClick={() => {
                    swal({
                      title: "Are you sure?",
                      text: "Once cancelled, you will not be able to recover your booking!",
                      icon: "warning",
                      buttons: ["Cancel", true],
                      dangerMode: true,
                    }).then((willDelete) => {
                      if (willDelete) {
                        delete_Booking();
                      } else {
                        swal("Your booking is safe");
                      }
                    });
                  }}
                  disabled={IsLoading}
                  className="btn btn-danger btn-block btn-sm"
                >
                  {IsLoading ? "Processing..." : "Cancel"}
                </button>
              </div>
            )}
            <div className="col-4 text-right">
              <Link
                to={`/booking-detail/${item.id}/mybooking`}
                className="btn btn-block btn-info btn-sm"
              >
                View Details{" "}
                <i className="fa fa-chevron-right" aria-hidden="true"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <ReactDrawer onClose={() => setisOpen(false)} isOpen={isOpen}>
        <div className="container">
          <div className="row" style={{ paddingTop: 20 }}>
            <div className="col-md-8">
              <h6 style={{ fontWeight: "bold", marginBottom: 0 }}>
                Submit Review
              </h6>
              <span style={{ fontSize: 14 }}>
                {item.schedule.originalCourseDetails.collegeName}
              </span>
            </div>
            <div className="col-md-4 text-right">
              <span
                onClick={() => setisOpen(false)}
                className="text-primary"
                style={{ textDecoration: "underline", cursor: "pointer" }}
              >
                Close
              </span>
            </div>
          </div>
          <div className="mt-4">
            <Formik
              initialValues={{
                title: "",
                message: "",
              }}
              validationSchema={Yup.object({
                title: Yup.string().required("please enter title!"),
                message: Yup.string().required("Please enter your message!"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                setIsLoading(true);
                submitReview(values);
                setreviewData(values);
                setSubmitting(false);
              }}
            >
              <Form>
                <div className="mb-3">
                  <label
                    className="label"
                    style={{ display: "block", marginBottom: 0 }}
                  >
                    Rating
                  </label>
                  <ReactStars
                    count={5}
                    size={24}
                    edit={true}
                    half={false}
                    color2={"#ffd700"}
                    onChange={(number) => {
                      setreviewNumber(number);
                    }}
                  />
                </div>
                <div className="mb-3">
                  <TextInput
                    placeHolder="Title"
                    label="Title"
                    type="text"
                    name="title"
                  />
                </div>
                <div className="mb-3">
                  <TextareaInput
                    placeHolder="Type your message..."
                    label="Message"
                    type="text"
                    name="message"
                  />
                </div>
                <div className="mb-4">
                  <Button
                    title="Submit Review"
                    className="btn-block"
                    isLoading={IsLoading}
                  />
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </ReactDrawer>
    </>
  );
};

export default CourseCard;
