import { Action, universityStateData } from "../actions/universityData";
import { ActionType } from "../action-types/index";

const intitialState = {
  universityData: [],
};

const universityDataReducer = (
  state: universityStateData = intitialState,
  action: Action
) => {
  switch (action.type) {
    case ActionType.UNIVERSITY_DATA:
      return action.payload;
    default:
      return state;
  }
};

export default universityDataReducer;
