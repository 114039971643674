import React, { useEffect, useRef, useState } from "react";

interface TextInputProps {
  type?: string;
  title: string;
  className?: string;
  isLoading: boolean;
  onClick?: () => void;
}

const TextInput: React.FC<TextInputProps> = ({
  type,
  title,
  className,
  onClick,
  isLoading,
}) => {
  return (
    <>
      <button
        disabled={isLoading}
        onClick={onClick}
        className={`btn btn-${type ? type : "primary"} ${className || ""}`}
      >
        {isLoading ? "Processing..." : title}
      </button>
    </>
  );
};

export default TextInput;
