import React, { useEffect } from "react";
import Select from "react-select";

const Features: React.FC = () => {
  return (
    <>
      <section id="services" className="section-bg">
        <div className="container">
          <header className="section-header">
            <h3>WE THRIVE TO DELIVER</h3>
            <p></p>
          </header>
          <div className="row">
            <div className="col-md-4 col-lg-4">
              <div className="box">
                <img
                  src={require("./../../../assets/img/download_3.png")}
                  className="img-fluid"
                />
                <h4 className="title">
                  <a>Unparalleled Customer Support</a>
                </h4>
                <p className="description">
                  We take our customer experience very seriously we always
                  thrive to serve our community better.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-lg-4">
              <div className="box">
                <img
                  src={require("./../../../assets/img/download_2.png")}
                  className="img-fluid"
                />
                <h4 className="title">
                  <a>Seamless Booking Experiance</a>
                </h4>
                <p className="description">
                  Maritime course booking experience like never before! Indulge
                  in a hassle-free, clean course booking from start to finish.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-lg-4">
              <div className="box">
                <img
                  src={require("./../../../assets/img/download.png")}
                  className="img-fluid"
                />
                <h4 className="title">
                  <a>Across All Devices</a>
                </h4>
                <p className="description">
                  Access Courseharbour and manage your bookings across all
                  devices (Android, Apple & desktop) in a single login.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Features;
