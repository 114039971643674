import React, { useEffect, useRef } from "react";

const UserAgreement: React.FC = () => {
  const myRef: any = useRef(null);
  const executeScroll = () => {
    if (myRef && myRef.current) {
      myRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  useEffect(() => {
    executeScroll();
  }, []);

  return (
    <>
      <span ref={myRef}></span>
      <section id="about">
        <div className="container">
          <header className="section-header">
            <h3>User Agreement</h3>
            <p></p>
          </header>
          <div className="row about-container">
            <div className="col-lg-12 content order-lg-1 order-2">
              <p>
                <span style={{ fontSize: 16, marginTop: 10 }}>
                  <br />
                  <br />
                  <span style={{ fontWeight: "bold" }}>1. APPLICABILITY</span>
                  <br />
                  <br />
                  1. This User Agreement along with Terms of Service
                  (collectively, the "User Agreement”) forms the terms and
                  conditions for the use of services and products of
                  Courseharbour, which is a brand owned, managed, and operated
                  by MATELOTS CORP.
                  <br />
                  <br />
                  2. Any person ("User") who inquiries about or purchases any
                  products or services of CourseHarbour through its websites,
                  mobile applications, sales persons, offices, call centres,
                  branch offices, franchisees, agents etc. (all the aforesaid
                  platforms collectively referred to as “Sales Channels”) agree
                  to be governed by this User Agreement. The websites and the
                  mobile applications of CourseHarbour are collectively referred
                  to as ‘Website’.
                  <br />
                  <br />
                  3. Both User and CourseHarbour are individually referred to as
                  'Party' and collectively referred to as 'Parties' to the User
                  Agreement.
                  <br />
                  <br />
                  4. “Terms of Service” available on CourseHarbour’s website
                  details out terms & conditions applicable on various services
                  or products facilitated by CourseHarbour. The User should
                  refer to the relevant Terms of Service applicable for the
                  given product or courses/service as booked by the User. Such
                  Terms of Service are binding on the User.
                  <br />
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    2. ELIGIBILITY TO USE
                  </span>
                  <br />
                  <br />
                  1. The User must be at least 18 years of age and must possess
                  the legal authority to enter into an agreement so as become a
                  User and use the services of CourseHarbour. If you are a minor
                  or are below the age of 18 years, you shall not register as a
                  User of the Website and shall not transact on or use the
                  Website.
                  <br />
                  <br />
                  2. As a minor if you wish to use or transact on the Website,
                  such use or transaction shall only be made by a person of
                  legal contracting age (legal guardian or parents). We reserve
                  the right to terminate your membership and/or block access to
                  the Website if it is discovered that you are a minor or
                  incompetent to contract according to the law or any
                  information pertaining to your age entered at the time of
                  creation of account is false.
                  <br />
                  <br />
                  3. Before using the Website, approaching any Sales Channels or
                  procuring the services of CourseHarbour, the Users shall
                  compulsorily read and understand this User Agreement, and
                  shall be deemed to have accepted this User Agreement as a
                  binding document that governs User’s dealings and transactions
                  with CourseHarbour. If the User does not agree with any part
                  of this Agreement, then the User must not avail
                  CourseHarbour's services and must not access or approach the
                  Sales Channels of CourseHarbour.
                  <br />
                  <br />
                  4. All rights and liabilities of the User and CourseHarbour
                  with respect to any services or product facilitated by
                  CourseHarbour shall be restricted to the scope of this User
                  Agreement.
                  <br />
                  <br />
                  <span style={{ fontWeight: "bold" }}>3. CONTENT</span>
                  <br />
                  <br />
                  1. All content provided through various Sales Channels,
                  including but not limited to audio, images, software, text,
                  icons and such similar content ("Content"), are registered by
                  CourseHarbour and protected under applicable intellectual
                  property laws. User cannot use this Content for any other
                  purpose, except as specified herein.
                  <br />
                  <br />
                  2. User agrees to follow all instructions provided by
                  CourseHarbour which will prescribe the way such User may use
                  the Content.
                  <br />
                  <br />
                  3. There are a number of proprietary logos, service marks and
                  trademarks displayed on the Website and through other Sales
                  Channels of CourseHarbour, as may be applicable. CourseHarbour
                  does not grant the User a license, right or authority to
                  utilize such proprietary logos, service marks, or trademarks
                  in any manner. Any un-authorized use of the Content, will be
                  in violation of the applicable law.
                  <br />
                  <br />
                  4. CourseHarbour respects the intellectual property rights of
                  others. If you notice any act of infringement on the Website,
                  you are requested to send us a written notice/ intimation
                  which must include the following information;
                  <br />
                  <br />
                  1. clear identification of such copyrighted work that you
                  claim has been infringed;
                  <br />
                  <br />
                  2. location of the material on the Website, including but not
                  limited to the link of the infringing material;
                  <br />
                  <br />
                  3. The proof that the alleged copyrighted work is owned by
                  you;
                  <br />
                  <br />
                  4. Contact information;
                  <br />
                  <br />
                  5. The aforesaid notices can be sent to Courseharbour by email
                  at ipr@ courseharbour.com.
                  <br />
                  <br />
                  <span style={{ fontWeight: "bold" }}>4. WEBSITE</span>
                  <br />
                  <br />
                  1. The Website is meant to be used by bonafide User(s) for a
                  lawful use.
                  <br />
                  <br />
                  2. User shall not distribute exchange, modify, sell or
                  transmit anything from the Website, including but not limited
                  to any text, images, audio and video, for any business,
                  commercial or public purpose.
                  <br />
                  <br />
                  3. The User Agreement grants a limited, non-exclusive,
                  non-transferable right to use this Website as expressly
                  permitted in this User Agreement. The User agrees not to
                  interrupt or attempt to interrupt the operation of the Website
                  in any manner whatsoever.
                  <br />
                  <br />
                  4. Access to certain features of the Website may only be
                  available to registered User(s). The process of registration,
                  may require the User to answer certain questions or provide
                  certain information that may or may not be personal in nature.
                  Some such fields may be mandatory or optional. User represents
                  and warrants that all information supplied to CourseHarbour is
                  true and accurate.
                  <br />
                  <br />
                  5. CourseHarbour reserves the right, in its sole discretion,
                  to terminate the access to the Website and the services
                  offered on the same or any portion thereof at any time,
                  without notice, for general maintenance or any other reason
                  whatsoever.
                  <br />
                  <br />
                  6. CourseHarbour will always make its best endeavours to
                  ensure that the content on its websites or other sales
                  channels are free of any virus or such other malwares.
                  However, any data or information downloaded or otherwise
                  obtained through the use of the Website or any other Sales
                  Channel is done entirely at the User’s own discretion and risk
                  and they will be solely responsible for any damage to their
                  computer systems or loss of data that may result from the
                  download of such data or information.
                  <br />
                  <br />
                  7. CourseHarbour reserves the right to periodically make
                  improvements or changes in its website at any time without any
                  prior notice to the User.
                  <br />
                  <br />
                  User(s) are requested to report any content on the Website
                  which is deemed to be unlawful, objectionable, libellous,
                  defamatory, obscene, harassing, invasive to privacy, abusive,
                  fraudulent, against any religious beliefs, spam, or is
                  violative of any applicable law to report@courseharbour.com.
                  On receiving such report, CourseHarbour reserves the right to
                  investigate and/or take such action as the Company may deem
                  appropriate.
                  <br />
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    5. LIMITED LIABILITY OF CourseHarbour
                  </span>
                  <br />
                  <br />
                  1. Unless CourseHarbour explicitly acts as a reseller in
                  certain scenarios, CourseHarbour always acts as a facilitator
                  by connecting the User with the respective service providers
                  i.e. colleges/educational institutions, training centres etc.
                  (collectively referred to as “Service Providers”).
                  CourseHarbour’s liability is limited to providing the User
                  with a confirmed booking as selected by the User.
                  <br />
                  <br />
                  2. Any issues or concerns faced by the User after booking the
                  course, shall be the sole responsibility of the Service
                  Provider. CourseHarbour will have no liability with respect to
                  the acts, omissions, errors, representations, warranties,
                  breaches or negligence on part of any Service Provider.
                  <br />
                  <br />
                  3. Unless explicitly committed by CourseHarbour as a part of
                  any product or service:
                  <br />
                  <br />
                  1. CourseHarbour assumes no liability for the standard of
                  services as provided by the respective Service Providers.
                  <br />
                  <br />
                  2. CourseHarbour provides no guarantee with regard to their
                  quality or fitness as represented.
                  <br />
                  <br />
                  3. CourseHarbour doesn’t guarantee the availability of any
                  services as listed by a Service Provider.
                  <br />
                  <br />
                  4. By making a booking, User understands CourseHarbour merely
                  provides a technology platform for booking of courses and the
                  ultimate liability rests on the respective Service Provider
                  and not CourseHarbour. Thus, the ultimate contract of service
                  is between User and Service Provider.
                  <br />
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    6. USER’S RESPONSIBILITY
                  </span>
                  <br />
                  <br />
                  1. Users are advised to check the description of the courses,
                  the details of the educational Institutions providing the
                  aforesaid courses, services and products carefully before
                  making a booking. User(s) agree to be bound by all the
                  conditions as contained in booking confirmation or as laid out
                  in the confirmed booking voucher. These conditions are also to
                  be read in consonance with the User Agreement.
                  <br />
                  <br />
                  2. If a User intends to make a booking on behalf of another
                  person, it shall be the responsibility of the User to inform
                  such person about the terms of this Agreement, including all
                  rules and restrictions applicable thereto.
                  <br />
                  <br />
                  3. The User undertakes to abide by all procedures and
                  guidelines, as modified from time to time, in connection with
                  the use of the services available through CourseHarbour. The
                  User further undertakes to comply with all applicable laws,
                  regulations, orders, directions etc. issued by either the
                  Central Government, State Government, District Authorities or
                  any other statutory body empowered to do so w.r.t use of
                  services or for each transaction.
                  <br />
                  <br />
                  4. The services are provided on an "as is" and "as available"
                  basis. CourseHarbour may change the features or functionality
                  of the services being provided at any time, in its sole
                  discretion, without any prior notice. CourseHarbour expressly
                  disclaims all warranties of any kind, whether express or
                  implied, including, but not limited to the implied warranties
                  of merchantability, reasonably fit for all purposes. No advice
                  or information, whether oral or written, which the User
                  obtains from CourseHarbour or through the services opted shall
                  create any warranty not expressly made herein or in the terms
                  and conditions of the services.
                  <br />
                  <br />
                  5. User also authorizes CourseHarbour’s representative to
                  contact such user over phone, message and e-mail. This consent
                  shall supersede any preferences set by such User through
                  national customer preference register (NCPR) or any other
                  similar preferences.
                  <br />
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    7. SECURITY AND ACCOUNT RELATED INFORMATION:
                  </span>
                  <br />
                  <br />
                  1. While registering on the Website, the User will have to
                  choose a password to access that User’s account and User shall
                  be solely responsible for maintaining the confidentiality of
                  both the password and the account as well as for all
                  activities on the account. It is the duty of the User to
                  notify CourseHarbour immediately in writing of any
                  unauthorized use of their password or account or any other
                  breach of security. CourseHarbour will not be liable for any
                  loss that may be incurred by the User as a result of
                  unauthorized use of the password or account, either with or
                  without the User’s knowledge. The User shall not use anyone
                  else's account at any time.
                  <br />
                  <br />
                  2. User understands that any information that is provided to
                  this Website may be read or intercepted by others due to any
                  breach of security at the User’s end.
                  <br />
                  <br />
                  3. CourseHarbour keeps all the data in relation to credit
                  card, debit card, bank information etc. secured and in an
                  encrypted form in compliance with the applicable laws and
                  regulations. However, for cases of fraud detection, offering
                  bookings on credit (finance) etc., CourseHarbour may at times
                  verify certain information of its Users like their credit
                  score, as and when required
                  <br />
                  <br />
                  4. CourseHarbour adopts the best industry standard to secure
                  the information as provided by the User. However,
                  CourseHarbour cannot guarantee that there will never be any
                  security breach of its systems which may have an impact on
                  User’s information too.
                  <br />
                  <br />
                  5. The data of the User as available with CourseHarbour may be
                  shared with concerned law enforcement agencies for any lawful
                  or investigation purpose without the consent of the User.
                  <br />
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    8. FEES AND PAYMENT
                  </span>
                  <br />
                  <br />
                  1. In addition to the cost of booking as charged by the
                  Service Providers, CourseHarbour reserves the right to charge
                  certain fees in the nature of convenience fees or service
                  fees. CourseHarbour further reserves the right to alter any
                  and all fees from time to time. Any such additional fees,
                  including fee towards any modifications thereof, will be
                  displayed to the User before confirming the booking or
                  collecting the payment from such User.
                  <br />
                  <br />
                  2. In cases of short charging of the booking amount, taxes,
                  statutory fee, convenience fee etc., owing to any technical
                  error or other reason, CourseHarbour shall reserve the right
                  to deduct, charge or claim the balance amount from the User
                  and the User shall pay such balance amount to CourseHarbour.
                  In cases where the short charge is claimed prior to the
                  utilization of the booking, CourseHarbour will be at liberty
                  to cancel such bookings if the amount is not paid before the
                  utilization date.
                  <br />
                  <br />
                  3. Any increase in the price charged by CourseHarbour on
                  account of change in rate of taxes or imposition of new taxes,
                  levies by Government shall have to be borne by the User. Such
                  imposition of taxes, levies may be without prior notice and
                  could also be retrospective but will always be as per
                  applicable law.
                  <br />
                  <br />
                  4. In the rare circumstance of a booking not getting confirmed
                  for any reason whatsoever, CourseHarbour will process the
                  refund of the booking amount paid by the User and intimate the
                  User about the same. CourseHarbour is not under any obligation
                  to provide an alternate booking in lieu of or to compensate or
                  replace the unconfirmed booking. All subsequent bookings will
                  be treated as new transactions. Any applicable refund will be
                  processed as per the defined policies of the service provider
                  and CourseHarbour as the case may be.
                  <br />
                  <br />
                  5. The User shall be completely responsible for all charges,
                  fees, duties, taxes, and assessments arising out of the use of
                  the service, as per the applicable laws.
                  <br />
                  <br />
                  6. The User agrees and understands that all payments shall
                  only be made to bank accounts of CourseHarbour. CourseHarbour
                  or its agents, representatives or employees shall never ask a
                  customer to transfer money to any private account or to an
                  account not held in the name of CourseHarbour. The User agrees
                  that if that user transfers any amount against any booking or
                  transaction to any bank account that is not legitimately held
                  by CourseHarbour or to any personal account of any person,
                  CourseHarbour shall not be held liable for the same. User
                  shall not hold any right to recover from CourseHarbour any
                  amount which is transferred by the User to any third party.
                  <br />
                  <br />
                  7. The User shall not share his personal sensitive information
                  like credit/debit card number, CVV, OTP, card expiry date,
                  user IDs, passwords etc. with any person including the agents,
                  employees or representatives of Courseharbour. The User shall
                  immediately inform CourseHarbour if such details are demanded
                  by any of its agents’ employees or representatives.
                  CourseHarbour shall not be liable for any loss that the User
                  incurs for sharing the aforesaid details.
                  <br />
                  <br />
                  8. Refunds, if any, on cancelled bookings will always be
                  processed to the respective account or the banking instrument
                  (credit card, wallet etc.) from which payment was made for
                  that booking.
                  <br />
                  <br />
                  9. Booking(s) made by the User through CourseHarbour are
                  subject to the applicable cancellation policy as set out on
                  the booking page or as communicated to the customers in
                  writing.
                  <br />
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    9. USAGE OF THE MOBILE NUMBER, COMMUNICATION DETAILS OF THE
                    USER BY COURSEHARBOUR
                  </span>
                  <br />
                  <br />
                  1. CourseHarbour will send booking confirmation, cancellation,
                  payment confirmation, refund status, schedule change or any
                  such other information relevant for the transaction or booking
                  made by the User, via SMS, internet-based messaging
                  applications like WhatsApp, voice call, e-mail or any other
                  alternate communication detail provided by the User at the
                  time of booking.
                  <br />
                  <br />
                  2. CourseHarbour may also contact the User through the modes
                  mentioned above for any pending or failed bookings, to know
                  the preference of the User for concluding the booking and also
                  to help the User for the same.
                  <br />
                  <br />
                  3. The User hereby unconditionally consents that such
                  communications via SMS, internet-based messaging applications
                  like WhatsApp, voice call, e-mail or any other mode by
                  CourseHarbour are:
                  <br />
                  <br />
                  1. upon the request and authorization of the User;
                  <br />
                  <br />
                  2. ‘transactional’ and not an ‘unsolicited commercial
                  communication’ as per the guidelines of Telecom Regulation
                  Authority of India (TRAI), and
                  <br />
                  <br />
                  3. in compliance with the relevant guidelines of TRAI or such
                  other authority in India and abroad.
                  <br />
                  <br />
                  4. The User will indemnify CourseHarbour against all types of
                  losses and damages incurred by CourseHarbour due to any action
                  taken by TRAI, Access Providers (as per TRAI regulations) or
                  any other authority due to any erroneous complaint raised by
                  the User on CourseHarbour with respect to the communications
                  mentioned above or due to a wrong mobile number or e-mail id
                  being provided by the User for any reason whatsoever.
                  <br />
                  <br />
                  <span style={{ fontWeight: "bold" }}>10. FORCE MAJEURE</span>
                  <br />
                  <br />
                  1. There can be exceptional circumstances where CourseHarbour
                  and / or the Service Providers may be unable to honour the
                  confirmed bookings due to various reasons like act of God,
                  labour unrest, insolvency, business exigencies, government
                  decisions, terrorist activity, global pandemic situations, any
                  operational and technical issues, route etc. or any other
                  reason beyond the control of CourseHarbour. If CourseHarbour
                  has advance knowledge of any such situations where dishonour
                  of bookings may happen, it will make its best efforts to
                  provide similar alternative to the User or refund the booking
                  amount after deducting applicable service charges, if
                  supported and refunded by that respective service operators.
                  The User agrees that CourseHarbour being merely a facilitator
                  of the services and products booked, cannot be held
                  responsible for any such Force Majeure circumstance. The User
                  has to contact the Service Provider directly for any further
                  resolutions and refunds.
                  <br />
                  <br />
                  2. The User agrees that in the event of non-confirmation of
                  booking due to any technical reasons (like network downtime,
                  disconnection with third party platforms such as payment
                  gateways, banks etc.) or any other similar failures,
                  CourseHarbour’s obligation shall be limited refunding the
                  booking amount, if any, received from the customer. Such
                  refund shall completely discharge CourseHarbour from all
                  liabilities with respect to that transaction. Additional
                  liabilities, if any, shall be borne by the User.
                  <br />
                  <br />
                  3. In no event shall CourseHarbour be liable for any direct,
                  indirect, punitive, incidental, special or consequential
                  damages, and any other damages like damages for loss of use,
                  data or profits, arising out of or in any way connected with
                  the use or performance of the Website or any other Sales
                  Channel.
                  <br />
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    11. ADVERTISERS ON COURSEHARBOUR, AND LINKED WEBSITES
                  </span>
                  <br />
                  <br />
                  1. The Website may contain links to third party websites.
                  CourseHarbour does not control such websites and is not
                  responsible for its contents. If a User accesses any
                  third-party website, the same shall be done entirely at the
                  User’s risk and CourseHarbour shall assume no liability for
                  the same.
                  <br />
                  <br />
                  2. CourseHarbour is not responsible for any errors, omissions
                  or representations on any of its pages, links or any linked
                  website pages to the extent such information is updated or
                  provided directly by the Service Providers or the advertisers.
                  <br />
                  <br />
                  3. CourseHarbour does not endorse any advertisers on its
                  website, or any linked sites in any manner. The Users are
                  requested to verify the accuracy of all information provided
                  on the third-party web pages.
                  <br />
                  <br />
                  4. The linked sites are not under the control of CourseHarbour
                  and hence CourseHarbour is not responsible for the contents of
                  any linked site(s) or any further links on such site(s), or
                  any changes or updates to such sites. CourseHarbour is
                  providing these links to the Users only as a convenience.
                  <br />
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    12. RIGHT TO REFUSE
                  </span>
                  <br />
                  <br />
                  1. Right to Refusal by CourseHarbour:
                  <br />
                  <br />
                  1. CourseHarbour at its sole discretion reserves the right to
                  not accept any booking without assigning any reason thereof.
                  <br />
                  <br />
                  2. CourseHarbour will not provide any service or share
                  confirmed booking details till such time the complete
                  consideration is received from the User
                  <br />
                  <br />
                  3. In addition to other remedies and recourse available to
                  CourseHarbour under this User Agreement or under applicable
                  law, CourseHarbour may limit the User's activity, warn other
                  users of the User's actions, immediately suspend or terminate
                  the User's registration, or refuse to provide the User with
                  access to the Website if:
                  <br />
                  <br />
                  1. The User is in breach of this User Agreement; or
                  <br />
                  <br />
                  2. CourseHarbour is unable to verify or authenticate any
                  information provided by the User, or
                  <br />
                  <br />
                  3. CourseHarbour believes that the User's actions may infringe
                  on any third-party rights or breach any applicable law or
                  otherwise result in any liability for the User, other users of
                  CourseHarbour, or CourseHarbour itself.
                  <br />
                  <br />
                  4. Once a User has been suspended or terminated, such User
                  shall not register or attempt to register with CourseHarbour
                  with different credentials, or use the Website in any manner
                  whatsoever until such User is reinstated by CourseHarbour.
                  CourseHarbour may at any time in its sole discretion reinstate
                  suspended users.
                  <br />
                  <br />
                  5. If a User breaches this User Agreement, CourseHarbour
                  reserves the right to recover any amounts due to be paid by
                  the User to CourseHarbour, and to take appropriate legal
                  action as it deems necessary.
                  <br />
                  <br />
                  6. The User shall not write or send any content to
                  CourseHarbour which is, or communicate with CourseHarbour
                  using language or content which is:
                  <br />
                  <br />
                  1. abusive, threatening, offensive, defamatory, coercive,
                  obscene, belligerent, glorifying violence, vulgar, sexually
                  explicit, pornographic, illicit or otherwise objectionable;
                  <br />
                  <br />
                  2. contrary to any applicable law;
                  <br />
                  <br />
                  3. violates third parties' intellectual property rights;
                  <br />
                  <br />
                  4. a spam; or
                  <br />
                  <br />
                  5. in breach of any other part of these terms and conditions
                  of use.
                  <br />
                  <br />
                  7. If the User violates any of the aforesaid terms,
                  CourseHarbour shall be at liberty to take appropriate legal
                  action against the User.
                  <br />
                  <br />
                  2. Right to Refusal by the Service Provider
                  <br />
                  <br />
                  1. User agrees that that the end service provider may refuse
                  to provide the service(s), agreed to be provided to the User,
                  for any booking made through courseharbour with/without
                  assigning any reasons or owing to reasons including but not
                  limited to behavioural issues, public safety, health hazards,
                  infectious diseases, government orders/notification etc. In
                  such scenarios of refusal, the liability that may ensue shall
                  solely vest with the end service provider as it is outside the
                  purview of CourseHarbour and the User agrees that any claim,
                  refund or damages against such refusal shall lie solely
                  against the end service provider and not CourseHarbour.
                  <br />
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    13. RIGHT TO CANCEL
                  </span>
                  <br />
                  <br />
                  1. The User expressly undertakes to provide CourseHarbour with
                  correct and valid information while making use of the Website
                  under this User Agreement, and not to make any
                  misrepresentation of facts. Any default on part of the User
                  would disentitle the User from availing the services from
                  CourseHarbour.
                  <br />
                  <br />
                  2. In case CourseHarbour discovers or has reasons to believe
                  at any time during or after receiving a request for services
                  from the User that the request for services is either
                  unauthorized or the information provided by the User is not
                  correct or that any fact has been misrepresented by that User,
                  CourseHarbour shall be entitled to appropriate legal remedies
                  against the User, including cancellation of the bookings made
                  by the user, without any prior intimation to the User. In such
                  an event, CourseHarbour shall not be responsible or liable for
                  any loss or damage that may be caused to the User or any other
                  person in the booking, as a consequence of such cancellation
                  of booking or services.
                  <br />
                  <br />
                  3. If any judicial, quasi-judicial, investigation agency,
                  government authority approaches CourseHarbour to cancel any
                  booking, CourseHarbour will cancel the same without
                  approaching the concerned User whose booking has been
                  cancelled.
                  <br />
                  <br />
                  4. The User shall not hold CourseHarbour responsible for any
                  loss or damage arising out of measures taken by CourseHarbour
                  for safeguarding its own interest and that of its genuine
                  customers. This would also include CourseHarbour denying or
                  cancelling any bookings on account of suspected fraud
                  transactions.
                  <br />
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    14. FAKE CALLS AND OTHER SIMILAR PHISHING, SPAMMING OR
                    FRAUDULENT ACTIVITIES
                  </span>
                  <br />
                  <br />
                  1. CourseHarbour’s employees or authorized representatives
                  will never contact a User asking for his/ her credit or debit
                  card number, expiry date, CVV, net banking login, passwords,
                  OTP etc. nor will they ever request for a fund transfer to a
                  personal or an individual bank account. Further, they will
                  also not ask a User to install any third-party applications
                  that enable them to view a User’s mobile or computer screen.
                  <br />
                  <br />
                  2. Acting on any of these requests may make you a victim of
                  fraud and may potentially lead to loss of your valuable money
                  or information.
                  <br />
                  <br />
                  3. If you are ever asked for any of the aforesaid information,
                  please report it immediately on exposefraud@courseharbour.com.
                  <br />
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    15. INDEMNIFICATION
                  </span>
                  <br />
                  <br />
                  1. The User agrees to indemnify, defend and hold harmless
                  CourseHarbour, its affiliates and their respective officers,
                  owners, lawful successors and assigns from and against any and
                  all losses, liabilities, claims, damages, costs and expenses
                  (including legal fees and disbursements in connection
                  therewith and interest chargeable thereon) asserted against or
                  incurred by such indemnified persons, that arise out of,
                  result from, or may be payable by virtue of, any breach of any
                  representation or warranty provided by the User, or
                  non-performance of any covenant by the User.
                  <br />
                  <br />
                  2. The User shall be solely liable for any breach of any
                  country specific rules and regulations or general code of
                  conduct and CourseHarbour cannot be held responsible for the
                  same.
                  <br />
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    16. COVID-19 RELATED TERMS & CONDITIONS
                  </span>
                  <br />
                  <br />
                  User agrees that due to recent outbreak of COVID-19 pandemic,
                  which has been declared as a national disaster by the
                  Government of India, there may be instances, where the end
                  service provider either cancels the booking or refuses to
                  provide the services, as agreed with the User, by assigning or
                  not assigning any specific reason for such refusal. In such
                  cases CourseHarbour will assume no liability for the refusal
                  of service by the end service provider. The User also agrees
                  that refund, if any, for such bookings will be processed by
                  CourseHarbour to the User subject to receipt of the same from
                  the end service provider.
                  <br />
                  <br />
                  <span style={{ fontWeight: "bold" }}>17. MISCLLEANEOUS</span>
                  <br />
                  <br />
                  1. SEVERABILITY: If any provision of this User Agreement is
                  determined to be invalid or unenforceable in whole or in part,
                  such invalidity or unenforceability shall attach only to such
                  provision or part of such provision and the remaining part of
                  such provision and all other provisions of this User Agreement
                  shall continue to be in full force and effect.
                  <br />
                  <br />
                  2. JURISDICTION: This Agreement is subject to interpretation
                  as per the laws of India, and the parties shall refer any
                  unresolved disputes to the exclusive jurisdiction of courts in
                  Chennai
                  <br />
                  <br />
                  3. AMENDMENT TO THE USER AGREEMENT: CourseHarbour reserves the
                  right to change or amend the User Agreement from time to time.
                  The User is responsible for regularly reviewing the User
                  Agreement.
                  <br />
                  <br />
                  4. CONFIDENTIALITY: Any information which is specifically
                  mentioned by CourseHarbour as confidential shall be maintained
                  confidentially by the User and shall not be disclosed unless
                  as required by law or to serve the purpose of this User
                  Agreement and the obligations of both the parties herein.
                  <br />
                  <br />
                  5. FEEDBACK FROM CUSTOMER: CourseHarbour would like to know
                  the feedback of the Users for improving its services. The User
                  hereby authorizes CourseHarbour to contact the User for their
                  feedback on various services offered by CourseHarbour. Such
                  feedback may be collected through e-mails, telephone calls,
                  SMS or any other medium from time to time. In case the User
                  chooses not to be contacted, such User shall write to
                  CourseHarbour for specific exclusion at
                  privacy@courseharbour.com.
                  <br />
                  <br />
                  6. PRIVACY POLICY: User shall also refer to
                  CourseHarbour’s privacy policy available on CourseHarbour’s
                  website which governs use of the Websites. By using the
                  Website, User agrees to the terms of the Privacy Policy and
                  accordingly consents to the use of the User’s personal
                  information by CourseHarbour and its affiliates in accordance
                  with the terms of the Privacy Policy.
                </span>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UserAgreement;
