import React, { useEffect, useRef } from "react";

const PrivacyPolicy: React.FC = () => {
  const myRef: any = useRef(null);
  const executeScroll = () => {
    if (myRef && myRef.current) {
      myRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  useEffect(() => {
    executeScroll();
  }, []);

  return (
    <>
      <span ref={myRef}></span>
      <section id="about">
        <div className="container">
          <header className="section-header">
            <h3>Privacy Policy</h3>
            <p></p>
          </header>
          <div className="row about-container">
            <div className="col-lg-12 content order-lg-1 order-2">
              <p>
                <span style={{ fontSize: 16, marginTop: 10 }}>
                  <br />
                  <span style={{ fontWeight: "bold" }}>A. INTRODUCTION:</span>
                  <br />
                  <br />
                  Matelots corp. (hereinafter “CourseHarbour”) recognizes the
                  importance of privacy of its users and also of maintaining
                  confidentiality of the information provided by its users as a
                  responsible data controller and data processor.
                  <br />
                  <br />
                  This Privacy Policy provides for the practices for handling
                  and securing user's Personal Information (defined hereunder)
                  by CourseHarbour and its affiliates.
                  <br />
                  <br />
                  This Privacy Policy is applicable to any person (‘User’) who
                  books/purchases, intends to book/purchase, or inquire about
                  any product(s) or service(s) made available by CourseHarbour
                  through any of CourseHarbour’s customer interface channels
                  including its website, mobile site, mobile app & offline
                  channels including call centres and offices (collectively
                  referred herein as "Sales Channels").
                  <br />
                  <br />
                  For the purpose of this Privacy Policy, wherever the context
                  so requires "you" or "“your" shall mean User and the term
                  "we", "us", "our" shall mean CourseHarbour. For the purpose of
                  this Privacy Policy, Website means the website(s), mobile
                  site(s) and mobile app(s).
                  <br />
                  <br />
                  By using or accessing the Website or other Sales Channels, the
                  User hereby agrees with the terms of this Privacy Policy and
                  the contents herein. If you disagree with this Privacy Policy,
                  please do not use or access our website or other Sales
                  Channels.
                  <br />
                  <br />
                  This Privacy Policy does not apply to any website(s), mobile
                  sites and mobile apps of third parties, even if their
                  websites/products are linked to our website. User should take
                  note that information and privacy practices of CourseHarbour’s
                  business partners, advertisers, sponsors or other sites to
                  which CourseHarbour provides hyperlink(s), may be materially
                  different from this Privacy Policy. Accordingly, it is
                  recommended that you review the privacy statements and
                  policies of any such third parties with whom they interact.
                  <br />
                  <br />
                  This Privacy Policy is an integral part of your User Agreement
                  with CourseHarbour and all capitalized terms used, but not
                  otherwise defined herein, shall have the respective meanings
                  as ascribed to them in the User Agreement.
                  <br />
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    B. USERS OUTSIDE THE GEOGRAPHICAL LIMITS OF INDIA
                  </span>
                  <br />
                  <br />
                  Please note that the data shared with CourseHarbour shall be
                  primarily processed in India and such other jurisdictions
                  where a third party engaged by CourseHarbour may process the
                  data on CourseHarbour’ behalf. By agreeing to this policy, you
                  are providing CourseHarbour with your explicit consent to
                  process your personal information for the purpose(s) defined
                  in this policy. The data protection regulations in India or
                  such other jurisdictions mentioned above may differ from those
                  of your country of residence.
                  <br />
                  <br />
                  If you have any concerns in the processing your data and wish
                  to withdraw your consent, you may do so by writing to the
                  following email id: privacy@courseharbour.com. However, if
                  such processing of data is essential for us to be able to
                  provide service to you, then we may not be able to serve or
                  confirm your bookings after your withdrawal of consent. For
                  instance, if you want to book a course, then certain personal
                  information of yours like contact details, gender, seaman
                  document details etc. may have to be shared by us with the
                  colleges/institutions registered with us and they may further
                  process this information for making suitable arrangements for
                  your indented course.
                  <br />
                  <br />A withdrawal of consent by you for us to process your
                  information may:
                  <br />
                  <br />
                  1. severely inhibit our ability to serve you properly and in
                  such case, we may have to refuse the booking altogether, or
                  <br />
                  <br />
                  2. unreasonably restrict us to service your booking (if a
                  booking is already made) or may compel us to cancel your
                  booking.
                  <br />
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    C. TYPE OF INFORMATION WE COLLECT AND ITS LEGAL BASIS
                  </span>
                  <br />
                  <br />
                  The information as detailed below is collected for us to be
                  able to provide the services chosen by you and also to fulfil
                  our legal obligations as well as our obligations towards third
                  parties as per our User Agreement.
                  <br />
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    "Personal Information"
                  </span>
                   of User shall include the information shared by the User and
                  collected by us for the following purposes:
                  <br />
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    1. Registration on the Website:
                  </span>
                   Information which you provide while subscribing to or
                  registering on the Website, including but not limited to
                  information about your personal identity such as name, gender,
                  age etc., your contact details such as your email address,
                  postal addresses, telephone (mobile or otherwise) and/or fax
                  numbers. The information may also include information such as
                  your banking details (including credit/debit card) and any
                  other information relating to your income and/or lifestyle;
                  billing information payment history etc. (as shared by you).
                  <br />
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    2. Other information:
                  </span>
                   We may also collect some other information and documents
                  including but not limited to:
                  <br />
                  <br />
                  1. Transactional history (other than banking details) about
                  your e-commerce activities, buying behaviour.
                  <br />
                  <br />
                  2. Your usernames, passwords, email addresses and other
                  security-related information used by you in relation to our
                  Services.
                  <br />
                  <br />
                  3. Data either created by you or by a third party and which
                  you wish to store on our servers such as image files,
                  documents etc.
                  <br />
                  <br />
                  4. Data available in public domain or received from any third
                  party including social media channels, including but not
                  limited to personal or non-personal information from your
                  linked social media channels (like name, email address, friend
                  list, profile pictures or any other information that is
                  permitted to be received as per your account settings) as a
                  part of your account information.
                  <br />
                  <br />
                  5. Information pertaining any other students/marine
                  professional(s) for who you make a booking through your
                  registered Courseharbour account. In such case, you must
                  confirm and represent that each of the other students/marine
                  professional(s) for whom a booking has been made, has agreed
                  to have the information shared by you disclosed to us and
                  further be shared by us with the concerned service
                  provider(s).
                  <br />
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    D. HOW WE USE YOUR PERSONAL INFORMATION
                  </span>
                  <br />
                  <br />
                  The Personal Information collected maybe used in the following
                  manner:
                  <br />
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    1. While booking a course:
                  </span>
                  <br />
                  <br />
                  While booking a course, we may use Personal Information
                  including, payment details which include cardholder name,
                  credit/debit card number (in encrypted form) with expiration
                  date, banking details, wallet details etc. as shared and
                  allowed to be stored by you. We may also use the information
                  of students/marine professional(s) list as available in or
                  linked with your account. This information is presented to the
                  User at the time of booking a course to enable you to complete
                  your bookings expeditiously.
                  <br />
                  <br />
                  we will also use the Personal Information you provide to
                  facilitate the service(s) requested. For example, if you email
                  us with a question, we will use your email address, name,
                  nature of the question, etc. to respond to your question. We
                  may also store such Personal Information to assist us in
                  making the Website the better and easier to use for our Users.
                  <br />
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    2. We may also use your Personal Information for several
                    reasons including but not limited to:
                  </span>
                  <br />
                  <br />
                  1. keep you informed of the transaction status.
                  <br />
                  <br />
                  2. Your usernames, passwords, email addresses and other
                  security-related information used by you in relation to our
                  Services.
                  <br />
                  <br />
                  3. send booking confirmations either via SMS or WhatsApp or
                  any other messaging service.
                  <br />
                  <br />
                  4. send any updates or changes to your booking(s).
                  <br />
                  <br />
                  5. allow our customer service to contact you, if necessary.
                  <br />
                  <br />
                  6. customize the content of our website, mobile site and
                  mobile app.
                  <br />
                  <br />
                  7. request for reviews of products or services or any other
                  improvements.
                  <br />
                  <br />
                  8. send verification message(s) or email(s).
                  <br />
                  <br />
                  9. Validate/authenticate your account and to prevent any
                  misuse or abuse.
                  <br />
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    HOW LONG DO WE KEEP YOUR PERSONAL INFORMATION?
                  </span>
                  <br />
                  <br />
                  Courseharbour will retain your Personal Information on its
                  servers for as long as is reasonably necessary for the
                  purposes listed in this policy. In some circumstances we may
                  retain your Personal Information for longer periods of time,
                  for instance where we are required to do so in accordance with
                  any legal, regulatory, tax or accounting requirements.
                  <br />
                  <br />
                  <span style={{ fontWeight: "bold" }}>SURVEYS:</span>
                  <br />
                  <br />
                  We value opinions and comments from our Users and frequently
                  conduct surveys, both online and offline. Participation in
                  these surveys is entirely optional. Typically, the information
                  received is aggregated, and used to make improvements to
                  Website, other Sales Channels, services and to develop
                  appealing content, features and promotions for members based
                  on the results of the surveys. Identity of the survey
                  participants is anonymous unless otherwise stated in the
                  survey.
                  <br />
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    USER GENERATED CONTENT (UGC)
                  </span>
                  <br />
                  <br />
                  Courseharbour provides an option to its users to post their
                  experiences by way of review, ratings, and general poll
                  questions. Courseharbour may also hire a third party to
                  contact you and gather feedback about your recent booking with
                  Courseharbour. Though the participation in the feedback
                  process is purely optional, you may still receive emails,
                  notifications (SMS, WhatsApp, or any other messaging service)
                  for you to share your feedback. The reviews may be written or
                  in a video format. The reviews written or posted may also be
                  visible on other maritime related platforms.
                  <br />
                  <br />
                  The UGC that Courseharbour collect may be of the following
                  kinds:
                  <br />
                  <br />
                  1. Review and Ratings
                  <br />
                  <br />
                  2. Question and Answers
                  <br />
                  <br />
                  3. Crowd Source Data Collection (poll questions).
                  <br />
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    F. COOKIES AND SESSION DATA
                  </span>
                  <br />
                  <br />
                  <span style={{ fontWeight: "bold" }}>1. Cookies</span>
                  <br />
                  <br />
                  Courseharbour uses cookies to personalize your experience on
                  the Website and the advertisements that maybe displayed.
                  Courseharbour’s use of cookies is similar to that of any other
                  reputable online companies.
                  <br />
                  <br />
                  Cookies are small pieces of information that are stored by
                  your browser on your device's hard drive. Cookies allow us to
                  serve you better and more efficiently. Cookies also allow ease
                  of access, by logging you in without having to type your login
                  name each time (only your password is needed); we may also use
                  such cookies to display any advertisement(s) to you while you
                  are on the Website or to send you offers (or similar emails –
                  provided you have not opted out of receiving such emails)
                  focusing on destinations which may be of your interest.
                  <br />
                  <br />A cookie may also be placed by our advertising servers,
                  or third party advertising companies. Such cookies are used
                  for purposes of tracking the effectiveness of advertising
                  served by us on any website, and also to use aggregated
                  statistics about your visits to the Website in order to
                  provide advertisements in the Website or any other website
                  about services that may be of potential interest to you. The
                  third party advertising companies or advertisement providers
                  may also employ technology that is used to measure the
                  effectiveness of the advertisements. All such information is
                  anonymous. This anonymous information is collected through the
                  use of a pixel tag, which is an industry standard technology
                  and is used by all major websites. They may use this anonymous
                  information about your visits to the Website in order to
                  provide advertisements about goods and services of potential
                  interest to you. No Personal Information is collected during
                  this process. The information so collected during this
                  process, is anonymous, and does not link online actions to a
                  User.
                  <br />
                  <br />
                  Most web browsers automatically accept cookies. Of course, by
                  changing the options on your web browser or using certain
                  software programs, you can control how and whether cookies
                  will be accepted by your browser. Courseharbour supports your
                  right to block any unwanted Internet activity, especially that
                  of unscrupulous websites. However, blocking Courseharbour
                  cookies may disable certain features on the Website, and may
                  hinder an otherwise seamless experience to purchase or use
                  certain services available on the Website. Please note that it
                  is possible to block cookie activity from certain websites
                  while permitting cookies from websites you trust.
                  <br />
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    2. Automatic Logging of Session Data:
                  </span>
                  <br />
                  <br />
                  Each time you access the Website your session data gets
                  logged. Session data may consist of various aspects like the
                  IP address, operating system and type of browser software
                  being used and the activities conducted by the User while on
                  the Website. We collect session data because it helps us
                  analyze User’s choices, browsing pattern including the
                  frequency of visits and duration for which a User is logged
                  on. It also helps us diagnose problems with our servers and
                  lets us better administer our systems. The aforesaid
                  information cannot identify any User personally. However, it
                  may be possible to determine a User's Internet Service
                  Provider (ISP), and the approximate geographic location of
                  User's point of connectivity through the above session data
                  <br />
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    G. WITH WHOM YOUR PERSONAL INFORMATION IS SHARED
                  </span>
                  <br />
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    1. Service Providers and suppliers:
                  </span>
                  <br />
                  <br />
                  Your information shall be shared with the end service
                  providers i.e. college/institution/training facility who are
                  responsible for fulfilling your booking. You may note that
                  while making a booking with Courseharbour you authorize us to
                  share your information with the said service providers and
                  suppliers. It is pertinent to note that Courseharbour does not
                  authorize the end service provider to use your information for
                  any other purpose(s) except as may be for fulfilling their
                  part of service. However, how the said service
                  providers/suppliers use the information shared with them is
                  beyond the purview and control of Courseharbour as they
                  process Personal Information as independent data controllers,
                  and hence we cannot be made accountable for the same. You are
                  therefore advised to review the privacy policies of the
                  respective service provider or supplier whose services you
                  choose to avail.
                  <br />
                  <br />
                  Courseharbour does not sell or rent individual customer names
                  or other Personal Information of Users to third parties except
                  sharing of such information with our business / alliance
                  partners or vendors who are engaged by us for providing
                  various referral services and for sharing promotional and
                  other benefits to our customers from time-to-time basis based
                  on their booking history with us.
                  <br />
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    3. BUSINESS PARTNERS AND THIRD-PARTY VENDORS:
                  </span>
                  <br />
                  <br />
                  We may also share certain filtered Personal Information to our
                  corporate affiliates or business partners who may contact the
                  customers to offer certain products or services, which may
                  include free or paid products / services, which will enable
                  the customer to have better course completing experience or to
                  avail certain benefits specially made for Courseharbour
                  customers. If you choose to avail any such services offered by
                  our business partners, the services so availed will be
                  governed by the privacy policy of the respective service
                  provider.
                  <br />
                  <br />
                  Courseharbour may share your Personal Information to third
                  party that Courseharbour may engage to perform certain tasks
                  on its behalf, including but not limited to payment
                  processing, data hosting, and data processing platforms.
                  <br />
                  <br />
                  We use non-identifiable Personal Information of Users in
                  aggregate or anonymized form to build higher quality, more
                  useful online services by performing statistical analysis of
                  the collective characteristics and behaviour of our customers
                  and visitors, and by measuring demographics and interests
                  regarding specific areas of the Website. We may provide
                  anonymous statistical information based on this data to
                  colleges, advertisers, affiliates, and other current and
                  potential business partners. We may also use such aggregate
                  data to inform these third parties as to the number of people
                  who have seen and clicked on links to their websites. Any
                  Personal Information which we collect and which we may use in
                  an aggregated format is our property. We may use it, in our
                  sole discretion and without any compensation to you, for any
                  legitimate purpose including without limitation the commercial
                  sale thereof to third parties.
                  <br />
                  <br />
                  Occasionally, Courseharbour will hire a third party for market
                  research, surveys etc. and will provide information to these
                  third parties specifically for use in connection with these
                  projects. The information (including aggregate cookie and
                  tracking information) we provide to such third parties,
                  alliance partners, or vendors are protected by confidentiality
                  agreements and such information is to be used solely for
                  completing the specific project, and in compliance with the
                  applicable regulations.
                  <br />
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    4. DISCLOSURE OF INFORMATION
                  </span>
                  <br />
                  <br />
                  In addition to the circumstances described above,
                  Courseharbour may disclose User's Personal Information if
                  required to do so:
                  <br />
                  <br />
                  1. by law, required by any enforcement authority for
                  investigation, by court order or in reference to any legal
                  process.
                  <br />
                  <br />
                  2. to conduct our business.
                  <br />
                  <br />
                  3. for regulatory, internal compliance and audit exercise(s)
                  <br />
                  <br />
                  4. to secure our systems; or
                  <br />
                  <br />
                  5. to enforce or protect our rights or properties of
                  Courseharbour or any or all its affiliates, associates,
                  employees, directors or officers or when we have reason to
                  believe that disclosing Personal Information of User(s) is
                  necessary to identify, contact or bring legal action against
                  someone who may be causing interference with our rights or
                  properties, whether intentionally or otherwise, or when anyone
                  else could be harmed by such activities.
                  <br />
                  <br />
                  Such disclosure and storage may take place without your
                  knowledge. In that case, we shall not be liable to you or any
                  third party for any damages howsoever arising from such
                  disclosure and storage.
                  <br />
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    H. HOW CAN YOU OPT-OUT OF RECEIVING OUR PROMOTIONAL E-MAILS?
                  </span>
                  <br />
                  <br />
                  You will occasionally receive e-mail updates from us about
                  special offers, new Courseharbour services, and other
                  noteworthy items. We hope you will find these updates
                  interesting and informative. If you wish not to receive them,
                  please click on the "unsubscribe" link or follow the
                  instructions in each e-mail message.
                  <br />
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    1. PERMISSIONS REQUIRED FOR USING OUR MOBILE APPLICATIONS
                  </span>
                  <br />
                  <br />
                  When the courseharbour app is installed on your phone or
                  tablet, a list of permissions appears and are needed for the
                  app to function effectively. There is no option to customize
                  the list. The permissions that courseharbour requires and the
                  data that shall be accessed and its use is as below:
                  <br />
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    1. Android permissions:
                  </span>
                  <br />
                  <br />
                  1.{" "}
                  <span style={{ fontWeight: "bold" }}>
                    Device and App history:
                  </span>{" "}
                  We need your device permission to get information about your
                  device, like OS (operating system) name, OS version, mobile
                  network, hardware model, unique device identifier, preferred
                  language, etc. Basis these inputs, we intend to optimize your
                  course booking experience.
                  <br />
                  <br />
                  2.<span style={{ fontWeight: "bold" }}> Identity:</span> This
                  permission enables us to know about details of your account(s)
                  on your mobile device. We use this info to auto-fill your
                  email ID’s and provide a typing free experience. It also
                  allows facilitating your Facebook and Google+ login.
                  <br />
                  <br />
                  3.{" "}
                  <span style={{ fontWeight: "bold" }}>
                    Wi-Fi connection information:
                  </span>{" "}
                  When you allow us the permission to detect your Wi-Fi
                  connection, we optimize your bandwidth usage for multimedia
                  uploads.
                  <br />
                  <br />
                  4.{" "}
                  <span style={{ fontWeight: "bold" }}>
                    Device ID and Call information:
                  </span>{" "}
                  This permission is used to detect your Android ID through
                  which we can uniquely identify users. It also lets us know
                  your contact details using which we pre-populate specific
                  fields to ensure a seamless booking experience.
                  <br />
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    2. IOS permissions:
                  </span>
                  <br />
                  <br />
                  1. <span style={{ fontWeight: "bold" }}>
                    Notifications:
                  </span>{" "}
                  If you opt in for notifications, it enables us to send across
                  exclusive deals, promotional offers, course related updates,
                  etc. on your device. If you do not opt for this, updates for
                  your course booking confirmation, refund (in case of
                  cancellation), etc. will be sent through SMS.
                  <br />
                  <br />
                  2. <span style={{ fontWeight: "bold" }}>Contacts:</span> This
                  permission enables us to know about details of your account(s)
                  on your mobile device. We use this info to auto-fill your
                  email ID’s and provide a typing free experience. It also
                  allows facilitating your Facebook and Google+ login.
                  <br />
                  <br />
                  3.{" "}
                  <span style={{ fontWeight: "bold" }}>
                    Photo/ Media/ Files:
                  </span>{" "}
                  The libraries in the app use these permissions to allow users
                  to save and upload multimedia reviews.
                  <br />
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    J. HOW WE PROTECT YOUR PERSONAL INFORMATION?
                  </span>
                  <br />
                  <br />
                  All payments on the Website are secured. This means all
                  Personal Information you provide is transmitted using TLS
                  (Transport Layer Security) encryption. TLS is a proven coding
                  system that lets your browser automatically encrypt, or
                  scramble, data before you send it to us. Website has stringent
                  security measures in place to protect the loss, misuse, and
                  alteration of the information under our control. Whenever you
                  change or access your account information, we offer the use of
                  a secure server. Once your information is in our possession we
                  adhere to strict security guidelines, protecting it against
                  unauthorized access.
                  <br />
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    K. WITHDRAWAL OF CONSENT AND PERMISSION
                  </span>
                  <br />
                  <br />
                  You may withdraw your consent to submit any or all Personal
                  Information or decline to provide any permissions on its
                  website as covered above at any time. In case, you choose to
                  do so then your access to the Website may be limited, or we
                  might not be able to provide the services to you. You may
                  withdraw your consent by sending an email to
                  privacy@courseharbour.com
                  <br />
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    L. YOUR RIGHTS QUA PERSONAL INFORMATION
                  </span>
                  <br />
                  <br />
                  You may access your Personal Information from your user
                  account with Courseharbour. You may also correct your personal
                  information or delete such information (except some mandatory
                  fields) from your user account directly. If you don’t have
                  such a user account, then you write to
                  privacy@courseharbour.com
                  <br />
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    M. ELIGIBILITY TO TRANSACT WITH Courseharbour
                  </span>
                  <br />
                  <br />
                  You must be at least 18 years of age to transact directly with
                  Courseharbour and also to consent to the processing of your
                  personal data.
                  <br />
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    N. CHANGES TO THE PRIVACY POLICY
                  </span>
                  <br />
                  <br />
                  We reserve the rights to revise the Privacy Policy from time
                  to time to suit various legal, business and customer
                  requirement. We will duly notify the users as may be
                  necessary.
                  <br />
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    You may always submit concerns regarding this Privacy Policy
                    via email to us at privacy@courseharbour.com. Courseharbour
                    shall endeavour to respond to all reasonable concerns and
                    inquiries.
                  </span>
                </span>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicy;
