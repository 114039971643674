import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import CONFIGURATION from "../../Config";
import { State, userActionCreator } from "../../state";

const Navbar: React.FC = () => {
  const location = useLocation();
  const { pathname } = location;
  const [isOpen, setisOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { updateCurrentUser } = bindActionCreators(userActionCreator, dispatch);
  const currentUser = useSelector((state: State) => state.currentUser);

  return (
    <>
      <header id="header" className="fixed-top">
        <div className="container">
          <div className="logo float-left">
            <Link className={`${pathname === "/" && "active"}`} to="/">
              <img
                src={require("./../../assets/img/logowithname.png")}
                alt=""
                className="img-fluid"
              />
            </Link>
          </div>
          <nav className="main-nav float-right d-none d-lg-block">
            <ul>
              <li>
                <Link className={`${pathname === "/" && "active"}`} to="/">
                  Home
                </Link>
              </li>
              <li>
                <Link
                  className={`${pathname === "/about-us" && "active"}`}
                  to="/about-us"
                >
                  About Us
                </Link>
              </li>
              <li>
                <Link
                  className={`${pathname === "/blogs" && "active"}`}
                  to="/blogs"
                >
                  Blogs
                </Link>
              </li>
              <li>
                <Link
                  className={`${pathname === "/contact-us" && "active"}`}
                  to="/contact-us"
                >
                  Contact Us
                </Link>
              </li>
              {currentUser.isLoggedIn ? (
                <li className="drop-down">
                  <Link
                    className={`${
                      (pathname === "/profile" ||
                        pathname === "/book-courses" ||
                        pathname === "/my-bookings") &&
                      "active"
                    }`}
                    to="#"
                  >
                    Hi,{" "}
                    {currentUser.studentDetails &&
                      `${currentUser.studentDetails.firstName} ${currentUser.studentDetails.lastName}`}
                  </Link>
                  <ul>
                    <li>
                      <Link to="/profile">My Profile</Link>
                    </li>
                    <li>
                      <Link to="/">Book Courses</Link>
                    </li>
                    <li>
                      <Link
                        onClick={() =>
                          localStorage.setItem(
                            "@courseHarbour_activeTab",
                            "Upcoming"
                          )
                        }
                        to="/my-bookings"
                      >
                        My Bookings
                      </Link>
                    </li>
                    <li>
                      <a
                        onClick={() => {
                          localStorage.removeItem("@coureHarbourLogin");
                          localStorage.removeItem("@coureHarbourLoginTime");
                          window.location.href = "/";
                        }}
                        style={{ color: "red" }}
                        href="#"
                      >
                        Logout
                      </a>
                    </li>
                  </ul>
                </li>
              ) : (
                <li>
                  <Link
                    className={`${pathname === "/login" && "active"}`}
                    to="/login"
                  >
                    Login / Sign Up
                  </Link>
                </li>
              )}
            </ul>
          </nav>
        </div>
      </header>
      <button
        onClick={() => {
          if (isOpen) {
            let document: any = window.document;
            document
              .querySelector("body")
              .classList.remove("mobile-nav-active");
            setisOpen(false);
          } else {
            let document: any = window.document;
            document.querySelector("body").classList.add("mobile-nav-active");
            setisOpen(true);
          }
        }}
        type="button"
        className="mobile-nav-toggle d-lg-none"
      >
        {isOpen ? (
          <i className="fa fa-times"></i>
        ) : (
          <i className="fa fa-bars"></i>
        )}
      </button>
      <nav className="mobile-nav d-lg-none">
        {currentUser.isLoggedIn ? (
          <div style={{ display: "flex", padding: 15 }}>
            <div style={{ width: "20%" }}>
              {currentUser.studentDetails &&
              currentUser.studentDetails.profileUrl ? (
                <img
                  src={`${
                    CONFIGURATION.Image_Base_Url +
                    currentUser.studentDetails.profileUrl
                  }`}
                  className="img-fluid"
                />
              ) : (
                <img
                  src={
                    "https://cdn.pixabay.com/photo/2020/07/01/12/58/icon-5359553_1280.png"
                  }
                  className="img-fluid"
                />
              )}
            </div>
            <div style={{ width: "80%" }}>
              <Link
                style={{ paddingTop: 0, paddingBottom: 0 }}
                onClick={() => {
                  let document: any = window.document;
                  document
                    .querySelector("body")
                    .classList.remove("mobile-nav-active");
                  setisOpen(false);
                }}
                to="/profile"
              >
                {currentUser.studentDetails &&
                  `${currentUser.studentDetails.firstName} ${currentUser.studentDetails.lastName}`}
              </Link>
              <span style={{ fontSize: 12, display: "block", paddingLeft: 20 }}>
                Click to view profile!
              </span>
            </div>
          </div>
        ) : (
          <div style={{ display: "flex", padding: 15 }}>
            <div style={{ width: "20%" }}>
              <img
                src={
                  "https://cdn.pixabay.com/photo/2020/07/01/12/58/icon-5359553_1280.png"
                }
                className="img-fluid"
              />
            </div>
            <div style={{ width: "80%" }}>
              <Link
                style={{ paddingTop: 0, paddingBottom: 0 }}
                onClick={() => {
                  let document: any = window.document;
                  document
                    .querySelector("body")
                    .classList.remove("mobile-nav-active");
                  setisOpen(false);
                }}
                to="/login"
              >
                Login / Sign Up
              </Link>
              <span style={{ fontSize: 12, display: "block", paddingLeft: 20 }}>
                Click to login or signup!
              </span>
            </div>
          </div>
        )}

        <ul>
          <li className="active">
            <Link
              onClick={() => {
                let document: any = window.document;
                document
                  .querySelector("body")
                  .classList.remove("mobile-nav-active");
                setisOpen(false);
              }}
              to="/"
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                let document: any = window.document;
                document
                  .querySelector("body")
                  .classList.remove("mobile-nav-active");
                setisOpen(false);
              }}
              to="/about-us"
            >
              About Us
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                let document: any = window.document;
                document
                  .querySelector("body")
                  .classList.remove("mobile-nav-active");
                setisOpen(false);
              }}
              to="/contact-us"
            >
              Contact Us
            </Link>
          </li>
          {currentUser.isLoggedIn ? (
            <li className="">
              <ul>
                <li>
                  <Link
                    onClick={() => {
                      let document: any = window.document;
                      document
                        .querySelector("body")
                        .classList.remove("mobile-nav-active");
                      setisOpen(false);
                    }}
                    to="/"
                  >
                    Book Courses
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      localStorage.setItem(
                        "@courseHarbour_activeTab",
                        "Upcoming"
                      );
                      let document: any = window.document;
                      document
                        .querySelector("body")
                        .classList.remove("mobile-nav-active");
                      setisOpen(false);
                    }}
                    to="/my-bookings"
                  >
                    My Bookings
                  </Link>
                </li>
                <li>
                  <a
                    onClick={() => {
                      localStorage.removeItem("@coureHarbourLogin");
                      localStorage.removeItem("@coureHarbourLoginTime");
                      window.location.href = "/";
                    }}
                    style={{ color: "red" }}
                    href="#"
                  >
                    Logout
                  </a>
                </li>
              </ul>
            </li>
          ) : null}
        </ul>
        <span className="socialiconcontainer-d">
          <i
            onClick={() => {
              window.open(
                `https://www.facebook.com/Course-Harbour-107042685188268`,
                "_blank"
              );
            }}
            className="fa fa-facebook"
            aria-hidden="true"
          ></i>
          <i
            onClick={() => {
              window.open(`https://twitter.com/CourseHarbour`, "_blank");
            }}
            className="fa fa-twitter"
            aria-hidden="true"
          ></i>
          <i
            onClick={() => {
              window.open(`https://www.instagram.com/courseharbour/`, "_blank");
            }}
            className="fa fa-instagram"
            aria-hidden="true"
          ></i>
          {/* <i onClick={() => {
                                window.open(``,'_blank')
                            }} className="fa fa-linkedin" aria-hidden="true"></i> */}
        </span>
      </nav>
      <div
        onClick={() => {
          let document: any = window.document;
          document.querySelector("body").classList.remove("mobile-nav-active");
          setisOpen(false);
        }}
        className="mobile-nav-overly"
        style={{ display: isOpen ? "block" : "none" }}
      ></div>
    </>
  );
};

export default Navbar;
