import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import TextInput from "../../../components/ui/TextInput";
import moment from "moment";
import Select from "../../../components/ui/Select";
import Button from "../../../components/ui/Button";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { State, userActionCreator } from "../../../state";
import { useUser } from "../../../hooks/useUser";
import { useToggle } from "../../../hooks/useToggle";
import swal from "sweetalert";
import CONFIGURATION from "../../../Config";

const phoneRegExp = /^(\+\d{1,3}[- ]?)?\d{10}$/;

const Profile: React.FC = () => {
  let refParam = useRef(null);
  let inputRef: any;
  const fileInput = useRef(null);
  const [selectedImage, setSelectedImage] = useState<any>();
  const [imageUri, setimageUri] = useState("");
  const dispatch = useDispatch();
  const { updateCurrentUser } = bindActionCreators(userActionCreator, dispatch);
  const currentUser = useSelector((state: State) => state.currentUser);
  const {
    updateProfile,
    ImageUpload,
    getStundentDetails,
    ImageUploadUpdateData,
  } = useUser();
  const { IsLoading, setLoadingFalse, setLoadingToggle, setLoadingTrue } =
    useToggle();
  const myRef: any = useRef(null);
  const executeScroll = () => {
    if (myRef && myRef.current) {
      myRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  useEffect(() => {
    executeScroll();
  }, []);

  const uploadImage = async (data: any) => {
    const formData = new FormData();
    formData.append("file", data, data.name);
    try {
      const data = await ImageUpload(formData);
      setimageUri(data.url);
      getUserData(data);
    } catch (error: any) {}
  };

  const getUserData = async (uriData: any) => {
    try {
      const data = await ImageUploadUpdateData(
        uriData,
        currentUser.studentDetails.id
      );
    } catch (error: any) {}
  };

  useEffect(() => {
    setimageUri(
      currentUser.studentDetails.profileUrl
        ? currentUser.studentDetails.profileUrl
        : ""
    );
  }, []);

  return (
    <>
      <span ref={myRef}></span>
      <section id="about">
        <div className="container">
          <header>
            <h3 className="mb-1">My Profile</h3>
            <p>You can see your profile details and edit as well.</p>
          </header>
          <div className="row">
            <div className="col-md-6">
              <div style={{ display: "flex", paddingBottom: 10 }}>
                <div style={{ width: "12%" }}>
                  {imageUri && (
                    <img
                      className="img-fluid"
                      style={{ borderRadius: 150, maxHeight: 60 }}
                      src={CONFIGURATION.Image_Base_Url + imageUri}
                    />
                  )}
                  {!imageUri && (
                    <div
                      style={{
                        height: 60,
                        width: 60,
                        borderRadius: 150,
                        backgroundColor: "lightgray",
                      }}
                    ></div>
                  )}
                </div>
                <div
                  style={{
                    width: "88%",
                    paddingLeft: 10,
                    paddingRight: 10,
                    paddingTop: 5,
                  }}
                >
                  <h5 style={{ fontWeight: "bold", marginBottom: 5 }}>
                    {currentUser.studentDetails.firstName +
                      " " +
                      currentUser.studentDetails.lastName}
                  </h5>
                  <input
                    type="file"
                    hidden={true}
                    accept="image/jpeg, image/png"
                    ref={(refParam) => (inputRef = refParam)}
                    onChange={(data: any) => {
                      setSelectedImage(data.target.files[0]);
                      uploadImage(data.target.files[0]);
                    }}
                  />
                  <span
                    style={{
                      color: "gray",
                      textDecorationLine: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      inputRef.click();
                    }}
                  >
                    Edit Profile Picture
                  </span>
                </div>
              </div>
            </div>
          </div>
          <Formik
            initialValues={{
              firstName: currentUser.studentDetails.firstName,
              lastName: currentUser.studentDetails.lastName,
              dateOfBirth: moment
                .utc(currentUser.studentDetails.dateOfBirth)
                .format("YYYY-MM-DD"),
              indosNumber: currentUser.studentDetails.indosNumber,
              rank: currentUser.studentDetails.rank,
              sidNo: currentUser.studentDetails.sidNo,
              cdcNumber: currentUser.studentDetails.cdcNumber,
              passportNumber: currentUser.studentDetails.passportNumber,
              email: currentUser.studentDetails.user.email,
              phoneNumber: currentUser.studentDetails.phoneNumber,
              nationality: currentUser.studentDetails.nationality,
            }}
            validationSchema={Yup.object({
              firstName: Yup.string().required("please enter your first name!"),
              lastName: Yup.string().required("please enter your last name!"),
              dateOfBirth: Yup.string().required(
                "please enter your birth date!"
              ),
              indosNumber: Yup.string().required(
                "please enter your INDOS No.!"
              ),
              rank: Yup.string().required("please enter your rank!"),
              sidNo: Yup.string().required("please enter your rank!"),
              cdcNumber: Yup.string().required("please enter your CDC No.!"),
              passportNumber: Yup.string().required(
                "please enter your passport number!"
              ),
              email: Yup.string()
                .email("please enter your valid email address!")
                .required("please enter your email address!"),
              phoneNumber: Yup.string()
                .matches(phoneRegExp, "Phone number is not valid")
                .required("please enter your phone number!"),
            })}
            onSubmit={async (values, { setSubmitting }) => {
              if (
                currentUser.studentDetails.rank != values.rank ||
                currentUser.studentDetails.phoneNumber != values.phoneNumber ||
                currentUser.studentDetails.passportNumber !=
                  values.passportNumber
              ) {
                try {
                  setLoadingTrue();
                  let data = await updateProfile(
                    {
                      rank: values.rank,
                      phoneNumber: values.phoneNumber,
                      passportNumber: values.passportNumber,
                    },
                    currentUser.studentDetails.id
                  );
                  setLoadingFalse();
                  swal("Your profile updated succesfully!", {
                    icon: "success",
                  });
                } catch (error: any) {
                  setLoadingFalse();
                }
              } else {
                swal("Your profile updated succesfully!", {
                  icon: "success",
                });
              }
            }}
          >
            <Form>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <TextInput
                      placeHolder="First name"
                      label="First name"
                      type="text"
                      name="firstName"
                      disabled
                    />
                  </div>
                  <div className="mb-3">
                    <TextInput
                      placeHolder="Last name"
                      label="Last name"
                      type="text"
                      name="lastName"
                      disabled
                    />
                  </div>
                  <div className="mb-3">
                    <TextInput
                      placeHolder="Date of birth"
                      label="Date of Birth"
                      type="date"
                      name="dateOfBirth"
                      disabled
                      max={moment().subtract(18, "years").format("YYYY-MM-DD")}
                    />
                  </div>
                  <div className="mb-3">
                    <TextInput
                      placeHolder="INDOS No."
                      label="INDOS No."
                      type="text"
                      name="indosNumber"
                      disabled
                    />
                  </div>
                  <div className="mb-3">
                    <TextInput
                      placeHolder="CDC No."
                      label="CDC No."
                      type="text"
                      name="cdcNumber"
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <TextInput
                      placeHolder="SID No."
                      label="SID No."
                      type="text"
                      name="sidNo"
                      disabled
                    />
                  </div>
                  <div className="mb-3">
                    <TextInput
                      placeHolder="Passport No."
                      label="Passport No."
                      type="text"
                      name="passportNumber"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="label" style={{ display: "block" }}>
                      Rank
                    </label>
                    <Field className="form-control" name="rank" as="select">
                      <option value="">Select Rank</option>
                      {[
                        "Other",
                        "Master",
                        "Chief Mate",
                        "Second Mate",
                        "Third Mate",
                        "Deck Cadet",
                        "Chief Engineer",
                        "Second Engineer",
                        "Third Engineer",
                        "Fourth Engineer",
                        "Gas Engineer",
                        "Engine Cadet",
                        "Electro technical officer",
                        "Boatswain",
                        "Pump Man",
                        "Able-Bodied Seaman (AB)",
                        "Ordinary Seaman(OS)",
                        "Welder/Fitter",
                        "Oiler",
                        "Wiper",
                        "Motorman",
                        "Chief Cook",
                        "Chief steward",
                        "Steward",
                        "Dynamic positioning officer(DPO)",
                        "Master DPO",
                        "Chief mate DPO",
                        "Second mate DPO",
                        "Crane Operator",
                        "Rigger",
                        "Doctor",
                        "Rig Medic",
                        "Extra Master",
                        "Marine Survayer",
                        "Security Guard",
                        "Superintendent",
                        "Safety Officer",
                        "Navel Architect",
                        "Offshore Installation Manager(OIM)",
                        "Drilling Superintendent",
                        "Assistant Rig Manager",
                        "Toolpusher",
                        "Driller",
                        "Assistant Driller",
                        "Derrick Man",
                        "Floorman/Roughneck",
                        "Chief Mechanic",
                        "Assistant Mechanic",
                        "Storekeeper",
                        "Painter",
                      ].map((item, index) => {
                        return (
                          <option key={index.toString()} value={item}>
                            {item}
                          </option>
                        );
                      })}
                    </Field>
                  </div>
                  <div className="mb-3">
                    <TextInput
                      placeHolder="Email Id"
                      label="Email Id"
                      type="text"
                      name="email"
                      disabled
                    />
                  </div>
                  <div className="mb-3">
                    <TextInput
                      placeHolder="Phone Number"
                      label="Phone Number"
                      type="text"
                      name="phoneNumber"
                    />
                  </div>
                  <div className="mb-4">
                    <TextInput
                      placeHolder="Nationality"
                      label="Nationality"
                      disabled
                      type="text"
                      name="nationality"
                    />
                  </div>
                </div>
                <div className="col-md-3"></div>
                <div className="col-md-6">
                  <div className="mb-4">
                    <Button
                      title="Save"
                      className="btn-block"
                      isLoading={IsLoading}
                    />
                  </div>
                </div>
                <div className="col-md-3"></div>
              </div>
            </Form>
          </Formik>
        </div>
      </section>
    </>
  );
};

export default Profile;
