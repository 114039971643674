import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from "react-router-dom";
import { bindActionCreators } from "redux";
import Spinner from "./components/ui/Spinner";
import { useToggle } from "./hooks/useToggle";
import { useUser } from "./hooks/useUser";
import Layout from "./layout";
import { State, userActionCreator } from "./state";
import AboutUs from "./views/pages/aboutus";
import AboutusMore from "./views/pages/aboutusmore";
import Blog from "./views/pages/blog";
import Blogs from "./views/pages/blogs";
import BookCourses from "./views/pages/bookcourses";
import BookingDetail from "./views/pages/bookingdetail";
import ChangePassWord from "./views/pages/changePassword/index";
import ContactUs from "./views/pages/contactus";
import CourseBooking from "./views/pages/coursebooking";
import ForgotFailure from "./views/pages/forgotfailure";
import ForgotPassword from "./views/pages/forgotpassword";
import ForgotSuccess from "./views/pages/forgotsuccess";
import Help from "./views/pages/help";
import Home from "./views/pages/home";
import Login from "./views/pages/login";
import MyBookings from "./views/pages/mybookings";
import PrivacyPolicy from "./views/pages/privacypolicy";
import Profile from "./views/pages/profile";
import Register from "./views/pages/register";
import TermsAndConditions from "./views/pages/termsandconditions";
import UserAgreement from "./views/pages/useragreement";
const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const dispatch = useDispatch();
  let location = useLocation();
  const { updateCurrentUser } = bindActionCreators(userActionCreator, dispatch);
  const currentUser = useSelector((state: State) => state.currentUser);
  if (!currentUser.isLoggedIn) {
    localStorage.setItem(`courseLoginRemembered`, location.pathname);
    return <Navigate to="/login" state={{ from: location }} />;
  }
  return children;
};

const CheckAuth = ({ children }: { children: JSX.Element }) => {
  const dispatch = useDispatch();
  let location = useLocation();
  const { updateCurrentUser } = bindActionCreators(userActionCreator, dispatch);
  const currentUser = useSelector((state: State) => state.currentUser);
  if (currentUser.isLoggedIn) {
    return <Navigate to="/" state={{ from: location }} />;
  }
  return children;
};

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route
          element={
            <Layout>
              <Home />
            </Layout>
          }
          path="/"
        />
        <Route
          element={
            <Layout>
              <AboutUs />
            </Layout>
          }
          path="/about-us"
        />
        <Route
          element={
            <Layout>
              <Blogs />
            </Layout>
          }
          path="/blogs"
        />
        <Route
          element={
            <Layout>
              <AboutusMore />
            </Layout>
          }
          path="/about-us-more"
        />
        <Route
          element={
            <Layout>
              <PrivacyPolicy />
            </Layout>
          }
          path="/privacy-policy"
        />
        <Route
          element={
            <Layout>
              <TermsAndConditions />
            </Layout>
          }
          path="/terms-and-conditions"
        />
        <Route
          element={
            <Layout>
              <UserAgreement />
            </Layout>
          }
          path="/user-agreement"
        />
        <Route
          element={
            <Layout>
              <Help />
            </Layout>
          }
          path="/help"
        />
        <Route
          element={
            <Layout>
              <Blog />
            </Layout>
          }
          path="/blog/:id"
        />
        <Route
          element={
            <CheckAuth>
              <Layout>
                <Login />
              </Layout>
            </CheckAuth>
          }
          path="/login"
        />
        <Route
          element={
            <CheckAuth>
              <Layout>
                <ForgotPassword />
              </Layout>
            </CheckAuth>
          }
          path="/forgot-password"
        />
        <Route
          element={
            <CheckAuth>
              <Layout>
                <ChangePassWord />
              </Layout>
            </CheckAuth>
          }
          path="/resetPassword"
        />
        <Route
          element={
            <CheckAuth>
              <Layout>
                <ForgotSuccess />
              </Layout>
            </CheckAuth>
          }
          path="/resetPassword/success"
        />
        <Route
          element={
            <CheckAuth>
              <Layout>
                <ForgotFailure />
              </Layout>
            </CheckAuth>
          }
          path="/resetPassword/failure"
        />
        <Route
          element={
            <CheckAuth>
              <Layout>
                <Register />
              </Layout>
            </CheckAuth>
          }
          path="/signup"
        />
        <Route
          element={
            <Layout>
              <ContactUs />
            </Layout>
          }
          path="/contact-us"
        />
        <Route
          element={
            <RequireAuth>
              <Layout>
                <Profile />
              </Layout>
            </RequireAuth>
          }
          path="/profile"
        />
        <Route
          element={
            <RequireAuth>
              <Layout>
                <MyBookings />
              </Layout>
            </RequireAuth>
          }
          path="/my-bookings"
        />
        <Route
          element={
            <Layout>
              <BookCourses />
            </Layout>
          }
          path="/book-courses"
        />
        <Route
          element={
            <RequireAuth>
              <Layout>
                <CourseBooking />
              </Layout>
            </RequireAuth>
          }
          path="/course-booking/:id"
        />
        <Route
          element={
            <RequireAuth>
              <Layout>
                <BookingDetail />
              </Layout>
            </RequireAuth>
          }
          path="/booking-detail/:id/:from"
        />
      </Routes>
    </Router>
  );
};

const AppFinalContainer: React.FC = () => {
  const dispatch = useDispatch();
  const { IsLoading, setLoadingFalse, setLoadingToggle, setLoadingTrue } =
    useToggle(false);
  const { updateCurrentUser, updateStudentDetails } = bindActionCreators(
    userActionCreator,
    dispatch
  );
  const { refreshToken, getStundentDetails } = useUser();

  useEffect(() => {
    let getstorageData = localStorage.getItem("@coureHarbourLogin") || "";
    if (getstorageData) {
      getSession();
    } else {
      setLoadingTrue();
    }
  }, []);

  const getSession = async () => {
    try {
      let coureHarbourLoginTime =
        localStorage.getItem("@coureHarbourLoginTime") || "";
      var duration = moment.duration(
        moment().local().diff(moment(coureHarbourLoginTime))
      );
      var minutes = duration.asMinutes();
      if (minutes > 150) {
        let data = await refreshToken();
        localStorage.setItem("@coureHarbourLogin", JSON.stringify(data));
        updateCurrentUser({ ...data, isLoggedIn: true });
        setLoadingTrue();
      } else {
        let data1 = localStorage.getItem("@coureHarbourLogin") || "{}";
        let data = await getStundentDetails();
        let data2 = JSON.parse(data1);
        updateCurrentUser({ ...data2, isLoggedIn: true });
        updateStudentDetails(data);
        setLoadingTrue();
      }
    } catch (error: any) {
      setLoadingTrue();
    }
  };

  return IsLoading ? <App /> : <Spinner />;
};

export default AppFinalContainer;
