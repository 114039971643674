import React, {
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
  Ref,
} from "react";
import ReactDOM from "react-dom";
import { Formik, Form, useField } from "formik";

const TextareaInput = ({
  type,
  className,
  placeHolder,
  leftIcon,
  label,
  ...props
}: any) => {
  const [field, meta] = useField(props);

  return (
    <>
      <label className="label" style={{ display: "block" }}>
        {label}
      </label>
      <div className="input-group">
        <textarea
          className={`form-control ${className}`}
          placeholder={placeHolder}
          aria-label={placeHolder}
          {...field}
          {...props}
        />
        {leftIcon && (
          <div className="input-group-append">
            <span className="input-group-text" id="basic-addon2">
              <span className={leftIcon || ""} aria-hidden="true"></span>
            </span>
          </div>
        )}
      </div>
      {meta.touched && meta.error ? (
        <div className="text-danger" style={{ fontSize: 12, marginTop: 5 }}>
          {meta.error}
        </div>
      ) : null}
    </>
  );
};

export default TextareaInput;
