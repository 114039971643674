import { Dispatch } from "redux";
import { ActionType } from "../action-types";
import { Action, StudentDetails, userInterface } from "../actions/userActions";

export const updateCurrentUser = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.CURRENT_USER,
      payload: data,
    });
  };
};

export const updateStudentDetails = (data: StudentDetails) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_STUDENT,
      payload: data,
    });
  };
};
