import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const Footer: React.FC = () => {
  return (
    <footer
      id="footer"
      className={`${window.location.pathname === "/book-courses" && "d-none"}`}
    >
      <div className={`container`}>
        <div className="row pt-4">
          <div className="col-md-5">
            <span style={{ display: "block" }}>
              <span style={{ display: "inline-block" }}>
                <img
                  style={{ maxHeight: 60 }}
                  src={require("./../../assets/img/Group -31.png")}
                  className="img-fluid"
                />
              </span>
              <span
                className="pl-3"
                style={{
                  color: "white",
                  textTransform: "uppercase",
                  display: "inline-block",
                  fontSize: 20,
                }}
              >
                courseharbour
              </span>
            </span>
            <p style={{ marginBottom: 20 }} className="pt-3">
              Courseharbour is a first of its kind course booking platform
              exclusively developed to serve the maritime community of India and
              soon around the world.
            </p>
            <span className="socialiconcontainer">
              <i
                onClick={() => {
                  window.open(
                    `https://www.facebook.com/Course-Harbour-107042685188268`,
                    "_blank"
                  );
                }}
                className="fa fa-facebook"
                aria-hidden="true"
              ></i>
              <i
                onClick={() => {
                  window.open(`https://twitter.com/CourseHarbour`, "_blank");
                }}
                className="fa fa-twitter"
                aria-hidden="true"
              ></i>
              <i
                onClick={() => {
                  window.open(
                    `https://www.instagram.com/courseharbour/`,
                    "_blank"
                  );
                }}
                className="fa fa-instagram"
                aria-hidden="true"
              ></i>
              {/* <i onClick={() => {
                                window.open(``,'_blank')
                            }} className="fa fa-linkedin" aria-hidden="true"></i> */}
            </span>
            <p style={{ marginBottom: 0 }}>
              Copyright &copy; {new Date().getFullYear()} CourseHarbour, All
              rights reserved.
            </p>
          </div>
          <div className="col-md-5"></div>
          <div className="col-md-2">
            <span style={{ display: "block" }} className="mb-2 mt-4">
              <Link style={{ color: "white" }} to={`/about-us-more`}>
                About Us
              </Link>
            </span>
            <span style={{ display: "block" }} className="mb-2">
              <Link style={{ color: "white" }} to={`/blogs`}>
                Blogs
              </Link>
            </span>
            <span style={{ display: "block" }} className="mb-2">
              <Link style={{ color: "white" }} to={`/contact-us`}>
                Contact Us
              </Link>
            </span>
            <span style={{ display: "block" }} className="mb-2">
              <Link style={{ color: "white" }} to={`/terms-and-conditions`}>
                Terms and Conditions
              </Link>
            </span>
            <span style={{ display: "block" }} className="mb-2">
              <Link style={{ color: "white" }} to={`/privacy-policy`}>
                Privacy Policy
              </Link>
            </span>
            <span style={{ display: "block" }} className="mb-2">
              <Link style={{ color: "white" }} to={`/user-agreement`}>
                User Agreement
              </Link>
            </span>
            <span style={{ display: "block" }} className="mb-2">
              <Link style={{ color: "white" }} to={`/help`}>
                Help
              </Link>
            </span>
            <span style={{ display: "block", marginTop: 20 }}>
              <span
                onClick={() => {
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.courseharbour",
                    "_blank"
                  );
                }}
                style={{ display: "block", cursor: "pointer" }}
              >
                <img
                  src={require("./../../assets/img/google-play-badge.png")}
                  style={{ height: 30 }}
                  className="img-fluid"
                />
              </span>
              <span
                onClick={() => {
                  window.open(
                    "https://apps.apple.com/in/app/course-harbour/id1594047327",
                    "_blank"
                  );
                }}
                style={{ display: "block", marginTop: 10, cursor: "pointer" }}
              >
                <img
                  src={require("./../../assets/img/download-on-the-app-store.png")}
                  style={{ height: 34 }}
                  className="img-fluid"
                />
              </span>
            </span>
          </div>
        </div>
      </div>
      {/* <div className="container">
                <div className="row">
                    <div className="col-md-6 col-6 col-lg-6 text-center" style={{paddingTop: 20}}>
                        <Link style={{color: "white"}} to={`/about-us-more`}>About Us</Link>
                    </div>
                    <div className="col-md-6 col-6 col-lg-6 text-center" style={{paddingTop: 20}}>
                        <Link style={{color: "white"}} to={`/contact-us`}>Contact Us</Link>
                    </div>
                    <div className="col-md-6 col-6 col-lg-6 text-center" style={{paddingTop: 20}}>
                        <Link style={{color: "white"}} to={`/terms-and-conditions`}>Terms and Conditions</Link>
                    </div>
                    <div className="col-md-6 col-6 col-lg-6 text-center" style={{paddingTop: 20}}>
                        <Link style={{color: "white"}} to={`/privacy-policy`}>Privacy Policy</Link>
                    </div>
                </div>
                <div className="copyright">
                    Copyright &copy; {(new Date()).getFullYear()} Courseharbour All Rights Reserved
                </div>
            </div> */}
    </footer>
  );
};

export default Footer;
