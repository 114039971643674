import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../../components/ui/Button";
import TextInput from "../../../components/ui/TextInput";
import * as Yup from "yup";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { State, userActionCreator } from "../../../state";
import { useUser } from "../../../hooks/useUser";
import { useToggle } from "../../../hooks/useToggle";
import swal from "sweetalert";

const ForgotFailure: React.FC = () => {
  const { IsLoading, setLoadingFalse, setLoadingToggle, setLoadingTrue } =
    useToggle();
  const { login, getStundentDetails, sendForgotLink } = useUser();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { updateCurrentUser } = bindActionCreators(userActionCreator, dispatch);
  const currentUser = useSelector((state: State) => state.currentUser);

  const myRef: any = useRef(null);
  const executeScroll = () => {
    if (myRef && myRef.current) {
      myRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  useEffect(() => {
    executeScroll();
  }, []);

  return (
    <>
      <span ref={myRef}></span>
      <section id="about" style={{ minHeight: "90vh" }}>
        <div className="container" style={{ paddingTop: 50 }}>
          <div className="row">
            <div className="col-md-12">
              <header className="alert alert-danger">
                <h3
                  style={{ fontWeight: "bold", fontSize: 18, marginBottom: 5 }}
                >
                  Password Reset Failure
                </h3>
                <p style={{ marginBottom: 0 }}>Password reseting was failed!</p>
              </header>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ForgotFailure;
