import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Button from "../../../components/ui/Button";
import TextInput from "../../../components/ui/TextInput";
import * as Yup from "yup";
import TextareaInput from "../../../components/ui/TextareaInput";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { State, userActionCreator } from "../../../state";
import { useUser } from "../../../hooks/useUser";
import { useToggle } from "../../../hooks/useToggle";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";

const ContactUs: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { updateCurrentUser } = bindActionCreators(userActionCreator, dispatch);
  const currentUser = useSelector((state: State) => state.currentUser);
  const { sendContactMail } = useUser();
  const { IsLoading, setLoadingFalse, setLoadingToggle, setLoadingTrue } =
    useToggle();
  const myRef: any = useRef(null);
  const executeScroll = () => {
    if (myRef && myRef.current) {
      myRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  useEffect(() => {
    executeScroll();
  }, []);

  return (
    <>
      <span ref={myRef}></span>
      <section id="about">
        <div className="container" style={{ paddingTop: 50 }}>
          <div className="row">
            <div className="col-md-12">
              <header>
                <h3>Contact Us</h3>
                <p>
                  Fill in and send your queries/concerns below, we will get back
                  to you right away!
                </p>
              </header>
            </div>
            <div className="col-md-6 order-1" style={{ marginTop: 20 }}>
              <Formik
                initialValues={{
                  name: currentUser.isLoggedIn
                    ? `${currentUser.studentDetails.firstName} ${currentUser.studentDetails.lastName}`
                    : "",
                  email: currentUser.isLoggedIn
                    ? `${currentUser.studentDetails.user.email}`
                    : "",
                  message: "",
                }}
                validationSchema={Yup.object({
                  name: Yup.string().required("please enter your full name!"),
                  email: Yup.string()
                    .email("please enter valid email address!")
                    .required("please enter email address!"),
                  message: Yup.string()
                    .max(2000)
                    .required("Please enter your message!"),
                })}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  try {
                    setLoadingTrue();
                    let data = await sendContactMail(values);
                    swal(data.message, {
                      icon: "success",
                    });
                    resetForm();
                    setLoadingFalse();
                  } catch (error: any) {
                    setLoadingFalse();
                    swal(error.response.data.responseMessage);
                  }
                }}
              >
                <Form>
                  <div className="mb-3">
                    <TextInput
                      placeHolder="Full name"
                      label="Full name"
                      type="text"
                      name="name"
                    />
                  </div>
                  <div className="mb-3">
                    <TextInput
                      placeHolder="Email Address"
                      label="Email"
                      type="email"
                      name="email"
                    />
                  </div>
                  <div className="mb-3">
                    <TextareaInput
                      placeHolder="Type your message..."
                      label="Message"
                      type="text"
                      name="message"
                      maxLength={2000}
                    />
                    <small>Max 2000 characters</small>
                  </div>
                  <div className="mb-4">
                    <Button
                      title={"Send"}
                      className="btn-block"
                      isLoading={IsLoading}
                    />
                  </div>
                </Form>
              </Formik>
            </div>
            <div className="col-md-6 text-right order-0">
              <img
                style={{ maxHeight: 350 }}
                src={require("./../../../assets/img/Group761.png")}
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
