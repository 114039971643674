import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Spinner from "../../../components/ui/Spinner";
import { useCourse } from "../../../hooks/useCourse";
import moment from "moment";

const Blogs: React.FC = () => {
  const { getBlogDetail } = useCourse();
  const myRef: any = useRef(null);
  const [loder, setloder] = useState(true);
  const [Blogs, setBlogs] = useState<any>(null);
  const { getAllBlogs } = useCourse();
  const navigate = useNavigate();
  const executeScroll = () => {
    if (myRef && myRef.current) {
      myRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  useEffect(() => {
    executeScroll();
    getdetails();
  }, []);

  const getdetails = async () => {
    setloder(true);
    try {
      setloder(true);
      const data = await getAllBlogs();
      if (data) {
        setBlogs(data);
        setloder(false);
      }
    } catch (error) {
      setloder(false);
      console.log(error);
    }
  };
  return (
    <>
      <span ref={myRef}></span>
      {loder ? (
        <Spinner />
      ) : (
        <section id="about" style={{ minHeight: "100vh" }}>
          <div className="container">
            <header className="section-header">
              <h3>Blogs</h3>
              <p></p>
            </header>
            <div className="row blogs">
              {Blogs.map((item: any, index: number) => (
                <>
                  <div
                    className="col-1"
                    onClick={() => {
                      navigate(`/blog/${item.id}`);
                    }}
                  >
                    <span className="h1 text-primary" style={{ opacity: 0.3 }}>
                      {index + 1}
                    </span>
                  </div>
                  <div
                    className="col-5"
                    onClick={() => {
                      navigate(`/blog/${item.id}`);
                    }}
                  >
                    <div className="d-flex">
                      <img
                        src={require("./../../../assets/img/roundlogocourse.png")}
                        className="img-fluid inline-block"
                        style={{ height: 25, borderRadius: 25 }}
                      />
                      <span
                        className="inline-block ml-2 d-flex"
                        style={{ fontWeight: 500, fontSize: 12 }}
                      >
                        {item.displayFirstName} {item.displayLastName}
                      </span>
                    </div>
                    <h4 className="title mb-0 max-twoline">{item.title}</h4>
                    <span
                      style={{ fontSize: 12 }}
                      className="d-block mt-1 mb-2 max-twoline-description"
                    >
                      {item.description}
                    </span>
                    <p className="description" style={{ fontSize: 12 }}>
                      {moment(item.postUpdatedDate, "DD-MM-YYYY HH:mm").format(
                        "DD MMM YY"
                      )}{" "}
                      ·{" "}
                      {moment(item.postUpdatedDate, "DD-MM-YYYY HH:mm").format(
                        "hh:mm A"
                      )}
                    </p>
                  </div>
                </>
              ))}
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Blogs;
