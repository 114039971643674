import { combineReducers } from "redux";
import courseFilterDataReducer from "./courseFilterDataReducer";
import siteReducer from "./siteReducer";
import userReducer from "./userReducer";
import filterDataReducer from "./filterData";
import UniversityData from "./universityData";
const reducers = combineReducers({
  site: siteReducer,
  currentUser: userReducer,
  courseFilterData: courseFilterDataReducer,
  filterData: filterDataReducer,
  universityData: UniversityData,
});

export default reducers;

export type State = ReturnType<typeof reducers>;
