import React, { useEffect, useRef, useState } from "react";
import CourseCard from "../../components/mybookings/CourseCard";
import { useCourse } from "../../../hooks/useCourse";
import upcommingData from "./../../../state/actions/upComingIterFace";
import Spinner from "./../../../components/ui/Spinner";
const MyBookings: React.FC = () => {
  const [ActiveTab, setActiveTab] = useState(
    localStorage.getItem("@courseHarbour_activeTab")
      ? localStorage.getItem("@courseHarbour_activeTab")
      : "Upcoming"
  );
  const { getMyBooking } = useCourse();
  const myRef: any = useRef(null);
  const [onGoingData, setonGoingData] = useState([]);
  const [upComingData, setupComingData] = useState<upcommingData[]>([]);
  const [completedData, setcompletedData] = useState([]);
  const [canelledData, setcanelledData] = useState([]);
  const [loder, setloder] = useState(false);
  const executeScroll = () => {
    if (myRef && myRef.current) {
      myRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  useEffect(() => {
    executeScroll();
  }, []);

  useEffect(() => {
    getBookins();
  }, [ActiveTab]);

  const getBookins = async () => {
    setloder(true);
    try {
      const data = await getMyBooking(
        ActiveTab ? ActiveTab.toLowerCase() : "upcoming"
      );
      if (ActiveTab == "Upcoming") {
        setupComingData(data);
      }
      if (ActiveTab == "Ongoing") {
        setonGoingData(data);
      }
      if (ActiveTab == "Completed") {
        setcompletedData(data);
      }
      if (ActiveTab == "Cancelled") {
        setcanelledData(data);
      }
      setloder(false);
    } catch (error) {
      setloder(false);
    }
  };

  if (loder) {
    return <Spinner />;
  } else {
    return (
      <>
        <span ref={myRef}></span>
        <section id="about">
          <div className="container">
            <header>
              <h3 className="mb-1">My Bookings</h3>
              <p>Manage your bookings!</p>
            </header>
            <div className="row" style={{ minHeight: "50vh" }}>
              <div className="col-md-9">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li
                    onClick={() => {
                      setActiveTab("Upcoming");
                      localStorage.setItem(
                        "@courseHarbour_activeTab",
                        "Upcoming"
                      );
                    }}
                    className="nav-item"
                    role="presentation"
                  >
                    <button
                      className={`nav-link ${
                        ActiveTab === "Upcoming" && "active"
                      }`}
                      id="upcoming-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#upcoming"
                      type="button"
                      role="tab"
                      aria-controls="upcoming"
                      aria-selected="true"
                      style={{ cursor: "pointer" }}
                    >
                      Upcoming
                    </button>
                  </li>
                  <li
                    onClick={() => {
                      setActiveTab("Ongoing");
                      localStorage.setItem(
                        "@courseHarbour_activeTab",
                        "Ongoing"
                      );
                    }}
                    className="nav-item"
                    role="presentation"
                  >
                    <button
                      className={`nav-link ${
                        ActiveTab === "Ongoing" && "active"
                      }`}
                      id="profile-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#profile"
                      type="button"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                      style={{ cursor: "pointer" }}
                    >
                      Ongoing
                    </button>
                  </li>
                  <li
                    onClick={() => {
                      setActiveTab("Completed");
                      localStorage.setItem(
                        "@courseHarbour_activeTab",
                        "Completed"
                      );
                    }}
                    className="nav-item"
                    role="presentation"
                  >
                    <button
                      className={`nav-link ${
                        ActiveTab === "Completed" && "active"
                      }`}
                      id="completed-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#completed"
                      type="button"
                      role="tab"
                      aria-controls="completed"
                      aria-selected="false"
                      style={{ cursor: "pointer" }}
                    >
                      Completed
                    </button>
                  </li>
                  <li
                    onClick={() => {
                      setActiveTab("Cancelled");
                      localStorage.setItem(
                        "@courseHarbour_activeTab",
                        "Cancelled"
                      );
                    }}
                    className="nav-item"
                    role="presentation"
                  >
                    <button
                      className={`nav-link ${
                        ActiveTab === "Cancelled" && "active"
                      }`}
                      id="cancelled-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#cancelled"
                      type="button"
                      role="tab"
                      aria-controls="cancelled"
                      aria-selected="false"
                      style={{ cursor: "pointer" }}
                    >
                      Cancelled
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  {ActiveTab === "Upcoming" && (
                    <div
                      className={`tab-pane fade show active`}
                      id="upcoming"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                    >
                      <div className="pt-3">
                        {upComingData.length > 0 ? (
                          upComingData.map((item, index) => (
                            <CourseCard
                              reloadData={(data: boolean) => {
                                if (data) {
                                  getBookins();
                                }
                              }}
                              item={item}
                              share={true}
                              cancelled={true}
                            />
                          ))
                        ) : (
                          <div
                            style={{
                              height: "100%",
                              width: "100%",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                              marginTop: 100,
                            }}
                          >
                            <span>No upcoming bookings!!!</span>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {ActiveTab === "Ongoing" && (
                    <div
                      className={`tab-pane fade show active`}
                      id="profile"
                      role="tabpanel"
                      aria-labelledby="profile-tab"
                    >
                      <div className="pt-3">
                        {onGoingData.length > 0 ? (
                          onGoingData.map((item, index) => (
                            <CourseCard
                              reloadData={(data: boolean) => {
                                if (data) {
                                  getBookins();
                                }
                              }}
                              item={item}
                              share={true}
                              cancelled={false}
                            />
                          ))
                        ) : (
                          <div
                            style={{
                              height: "100%",
                              width: "100%",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                              marginTop: 100,
                            }}
                          >
                            <span>No ongoing bookings!!!</span>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {ActiveTab === "Completed" && (
                    <div
                      className={`tab-pane fade show active`}
                      id="completed"
                      role="tabpanel"
                      aria-labelledby="contact-tab"
                    >
                      <div className="pt-3">
                        {completedData.length > 0 ? (
                          completedData.map((item, index) => (
                            <CourseCard
                              review={true}
                              reloadData={(data: boolean) => {
                                if (data) {
                                  getBookins();
                                }
                              }}
                              item={item}
                              share={true}
                              cancelled={false}
                            />
                          ))
                        ) : (
                          <div
                            style={{
                              height: "100%",
                              width: "100%",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                              marginTop: 100,
                            }}
                          >
                            <span>No Completed bookings!!!</span>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {ActiveTab === "Cancelled" && (
                    <div
                      className={`tab-pane fade show active`}
                      id="cancelled"
                      role="tabpanel"
                      aria-labelledby="contact-tab"
                    >
                      <div className="pt-3">
                        {canelledData.length > 0 ? (
                          canelledData.map((item, index) => (
                            <CourseCard
                              reloadData={(data: boolean) => {
                                if (data) {
                                  getBookins();
                                }
                              }}
                              item={item}
                              share={true}
                              refund
                              cancelled={false}
                            />
                          ))
                        ) : (
                          <div
                            style={{
                              height: "100%",
                              width: "100%",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                              marginTop: 100,
                            }}
                          >
                            <span>No cancelled bookings!!!</span>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
};

export default MyBookings;
