import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

const AboutUs: React.FC = () => {
  const myRef: any = useRef(null);
  const executeScroll = () => {
    if (myRef && myRef.current) {
      myRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  useEffect(() => {
    executeScroll();
  }, []);

  return (
    <>
      <span ref={myRef}></span>
      <section id="about">
        <div className="container">
          <header className="section-header">
            <h3>About Us</h3>
            <p></p>
          </header>
          <div className="row about-container">
            <div className="col-lg-6 content order-lg-1 order-2">
              <p className="text-5lines">
                Courseharbour is a first of its kind course booking platform
                exclusively developed to serve the maritime community of India
                and soon around the world. The platform itself has been
                brainstormed, developed and run by seafarers which makes it even
                more special. We wanted to solve some of the day-to-day problems
                faced by us (seafarers) in the maritime industry and also to
                make some technological advancements in our field on par with
                the advancements in other sectors, first step of which is
                “Courseharbour”. Always we have some courses to do when we sign
                off and what we do is to search and call institutions for
                availability/details, through Courseharbour we intend to bring
                in a seamless course search, booking, course managing experience
                to seafarers (Literally on just 3 clicks) and as well as to
                maritime institutions, all in one website/app. Yes, you read
                that right, in just 3 clicks! Coursearbour is available on all
                platforms i.e., desktop, android and apple. We welcome you
                onboard!
              </p>
              <Link to="/about-us-more" style={{ textDecoration: "underline" }}>
                View More Details
              </Link>
            </div>
            <div className="col-lg-6 background order-lg-2 order-1">
              <img
                style={{ maxHeight: 250 }}
                src={require("./../../../assets/img/Group794.png")}
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
          <div className="row about-extra">
            <div className="col-lg-6">
              <img
                style={{ maxHeight: 250 }}
                src={require("./../../../assets/img/undraw_certificate_343v.png")}
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="col-lg-6 pt-5 pt-lg-0">
              <h4>Terms and Conditions</h4>
              <p className="text-5lines">
                Courseharbour only provides a technology platform that connects
                intending students/maritime professionals with colleges. It
                doesn’t conduct/operate any courses or college/institution User.
                Courseharbour also doesn’t act as an agent of any college /
                institution in the process of providing the above-mentioned
                technology platform services. Courseharbour acts as a
                facilitator connecting intending students/maritime professionals
                college / institution offering sale of courses. Accordingly, the
                contract of sale of services on the Website is strictly a
                bipartite contract between the User and the college /
                institution listed on the Website. The course booking voucher
                which Courseharbour issues to a User is solely based on the
                information provided or updated by the college/institution
                regarding the seat availability.
              </p>
              <Link
                to="/terms-and-conditions"
                style={{ textDecoration: "underline" }}
              >
                View More Details
              </Link>
            </div>
          </div>
          <div className="row about-extra">
            <div className="col-lg-6 order-1 order-lg-2">
              <img
                style={{ maxHeight: 250 }}
                src={require("./../../../assets/img/termsre6ak4.png")}
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1">
              <h4>Privacy Policy</h4>
              <p>
                Matelots corp. (hereinafter “CourseHarbour”) recognizes the
                importance of privacy of its users and also of maintaining
                confidentiality of the information provided by its users as a
                responsible data controller and data processor.
              </p>
              <Link
                to="/privacy-policy"
                style={{ textDecoration: "underline" }}
              >
                View More Details
              </Link>
            </div>
          </div>
          <div className="row about-extra">
            <div className="col-lg-6">
              <img
                style={{ maxHeight: 250 }}
                src={require("./../../../assets/img/Group795.png")}
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="col-lg-6 pt-5 pt-lg-0">
              <h4>User Agreement</h4>
              <p className="text-5lines">
                1. This User Agreement along with Terms of Service
                (collectively, the "User Agreement”) forms the terms and
                conditions for the use of services and products of
                Courseharbour, which is a brand owned, managed, and operated by
                MATELOTS CORP.
              </p>
              <Link
                to="/user-agreement"
                style={{ textDecoration: "underline" }}
              >
                View More Details
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUs;
