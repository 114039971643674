import { useCallback, useEffect, useState } from "react";
import axios, { AxiosError } from "axios";
import {
  StudentDetails,
  userInterface,
  UserRegisterRequest,
} from "../state/actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../state/reducers";
import { bindActionCreators } from "redux";
import { userActionCreator } from "../state";
import {
  CollageDetail,
  CourseDetail,
} from "../state/actions/courseDataActions";
import { singleCourseData } from "../state/actions/universityData";

export const useCourse = () => {
  const dispatch = useDispatch();
  const { updateStudentDetails } = bindActionCreators(
    userActionCreator,
    dispatch
  );
  const currentUser = useSelector((state: State) => state.currentUser);

  //get student details
  const getCourses = useCallback(async () => {
    let getstorageData = localStorage.getItem("@coureHarbourLogin") || "";
    const { data, status, statusText } = await axios.get<CourseDetail[]>(
      `${process.env.REACT_APP_BASE_URL}/courses/getAllScheduledCourses`
    );
    return data;
  }, []);

  //get student details
  const getRecentCourses = useCallback(async () => {
    let getstorageData = localStorage.getItem("@coureHarbourLogin") || "";
    if (getstorageData) {
      const { data, status, statusText } = await axios.get<
        { courseId: string; courseName: string }[]
      >(
        `${process.env.REACT_APP_BASE_URL}/analytics/getRecentCourseSearch`,
        getstorageData
          ? {
              headers: {
                Authorization: "Bearer " + JSON.parse(getstorageData).token,
              },
            }
          : {}
      );
      return data;
    } else {
      return [];
    }
  }, []);

  // get collages
  const getCollages = useCallback(async () => {
    const { data, status, statusText } = await axios.get<CollageDetail[]>(
      `${process.env.REACT_APP_BASE_URL}/colleges/public`
    );
    data.map((v) => Object.assign(v, { value: v.id, label: v.collegeName }));
    return data;
  }, []);

  //get Filter University
  const getUnivercity = useCallback(async (url: string) => {
    let getstorageData = localStorage.getItem("@coureHarbourLogin") || "";
    const { data, status, statusText } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/search/advanced?${url}&pageNo=1`,
      getstorageData
        ? {
            headers: {
              Authorization: "Bearer " + JSON.parse(getstorageData).token,
            },
          }
        : {}
    );
    return data;
  }, []);

  //get Filter University By Course
  const getUnivercitybyCourse = useCallback(async (id: string) => {
    let getstorageData = localStorage.getItem("@coureHarbourLogin") || "";
    const { data, status, statusText } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/search?courseId=${id}`,
      getstorageData
        ? {
            headers: {
              Authorization: "Bearer " + JSON.parse(getstorageData).token,
            },
          }
        : {}
    );
    return data;
  }, []);

  //get All Review
  const getAllReView = useCallback(
    async (id: number, courseId: number | string) => {
      const { data, status, statusText } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/reviews/collegeCourse/rating?collegeId=${id}&reviewStatus=Approved&courseId=${courseId}`
      );
      return data;
    },
    []
  );

  //get Collage
  const getCollage = useCallback(async (id: string | undefined) => {
    let getstorageData = localStorage.getItem("@coureHarbourLogin") || "";
    const { data, status, statusText } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/colleges/college/${id}`,
      {
        headers: {
          Authorization: "Bearer " + JSON.parse(getstorageData).token,
        },
      }
    );
    return data;
  }, []);

  // get Schedules
  const getSchedules = useCallback(async (id: string | undefined) => {
    let getstorageData = localStorage.getItem("@coureHarbourLogin") || "";
    const { data, status, statusText } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/schedules/${id}`,
      {
        headers: {
          Authorization: "Bearer " + JSON.parse(getstorageData).token,
        },
      }
    );
    return data;
  }, []);

  //get All Promocode
  const getPromocode = useCallback(async () => {
    let getstorageData = localStorage.getItem("@coureHarbourLogin") || "";
    const { data, status, statusText } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/couponcode/getAllActiveCoupon`,
      {
        headers: {
          Authorization: "Bearer " + JSON.parse(getstorageData).token,
        },
      }
    );
    return data;
  }, []);

  //get My Booking
  const getMyBooking = useCallback(async (type: string) => {
    let getstorageData = localStorage.getItem("@coureHarbourLogin") || "";
    const { data, status, statusText } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/bookings?status=${type}`,
      {
        headers: {
          Authorization: "Bearer " + JSON.parse(getstorageData).token,
        },
      }
    );
    return data;
  }, []);

  //get Create Payment Order
  const getCreatePaymentOrder = useCallback(async (body) => {
    let getstorageData = localStorage.getItem("@coureHarbourLogin") || "";
    const { data, status, statusText } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/payment/order`,
      body,
      {
        headers: {
          Authorization: "Bearer " + JSON.parse(getstorageData).token,
        },
      }
    );
    return data;
  }, []);

  //submit enquire now
  const enquireNow = useCallback(async (body) => {
    let getstorageData = localStorage.getItem("@coureHarbourLogin") || "";
    console.log(getstorageData);

    const { data, status, statusText } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/LeadGeneration/addLead`,
      body,
      getstorageData
        ? {
            headers: {
              Authorization: "Bearer " + JSON.parse(getstorageData)?.token,
            },
          }
        : undefined
    );
    return data;
  }, []);

  //get Collage Review
  const getCollageReview = useCallback(async (id: string) => {
    let getstorageData = localStorage.getItem("@coureHarbourLogin") || "";
    const { data, status, statusText } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/reviews/collegeCourse/${id}/rating`,
      {
        headers: {
          Authorization: "Bearer " + JSON.parse(getstorageData).token,
        },
      }
    );
    return data;
  }, []);

  //Apply PromoCode
  const applyPromoCode = useCallback(
    async (id: string | undefined, promoName: string, userId: string) => {
      let getstorageData = localStorage.getItem("@coureHarbourLogin") || "";
      const { data, status, statusText } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/couponcode/applyCoupon/${userId}/${id}/${promoName}`,
        {
          headers: {
            Authorization: "Bearer " + JSON.parse(getstorageData).token,
          },
        }
      );
      return data;
    },
    []
  );

  //get Create Payment Order
  const PaymentDone = useCallback(async (body) => {
    let getstorageData = localStorage.getItem("@coureHarbourLogin") || "";
    const { data, status, statusText } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/payment`,
      body,
      {
        headers: {
          Authorization: "Bearer " + JSON.parse(getstorageData).token,
        },
      }
    );
    return data;
  }, []);

  //Get Booking Details
  const getBookingDetails = useCallback(async (id: string | undefined) => {
    let getstorageData = localStorage.getItem("@coureHarbourLogin") || "";
    const { data, status, statusText } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/bookings/booking/${id}`,
      {
        headers: {
          Authorization: "Bearer " + JSON.parse(getstorageData).token,
        },
      }
    );
    return data;
  }, []);

  //get Delete Booking
  const deleteBooking = useCallback(async (body) => {
    let getstorageData = localStorage.getItem("@coureHarbourLogin") || "";
    const { data, status, statusText } = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}/cancel-booking`,
      body,
      {
        headers: {
          Authorization: "Bearer " + JSON.parse(getstorageData).token,
        },
      }
    );
    return data;
  }, []);

  //get exportBooking
  const exportBooking = useCallback(async (id: number, userId: number) => {
    let getstorageData = localStorage.getItem("@coureHarbourLogin") || "";
    const { data, status, statusText } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/bookings/exportBooking/${id}/${userId}`,
      {
        headers: {
          Authorization: "Bearer " + JSON.parse(getstorageData).token,
        },
      }
    );
    return data;
  }, []);

  //get Review Submit
  const reviewSubmit = useCallback(async (body) => {
    let getstorageData = localStorage.getItem("@coureHarbourLogin") || "";
    const { data, status, statusText } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/reviews`,
      body,
      {
        headers: {
          Authorization: "Bearer " + JSON.parse(getstorageData).token,
        },
      }
    );
    return data;
  }, []);

  //get All Review
  const getAllBlogs = useCallback(async () => {
    const { data, status, statusText } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/forum/getAllPublicPost`
    );
    return data;
  }, []);

  const getBlogDetail = useCallback(async (id: string | undefined) => {
    let getstorageData = localStorage.getItem("@coureHarbourLogin") || "";
    const { data, status, statusText } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/forum/getPublicPostById/${id}`
    );
    return data;
  }, []);

  return {
    getCourses,
    getRecentCourses,
    getCollages,
    getUnivercity,
    getAllReView,
    getUnivercitybyCourse,
    getCollage,
    getPromocode,
    getMyBooking,
    getCreatePaymentOrder,
    getSchedules,
    enquireNow,
    getCollageReview,
    applyPromoCode,
    PaymentDone,
    getBookingDetails,
    deleteBooking,
    exportBooking,
    reviewSubmit,
    getAllBlogs,
    getBlogDetail,
  };
};
