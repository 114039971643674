import { useCallback, useState } from "react";

export const useToggle = (initial: boolean = false) => {
  const [IsLoading, setIsLoading] = useState(false);

  const setLoadingTrue = useCallback(() => setIsLoading(true), []);
  const setLoadingFalse = useCallback(() => setIsLoading(false), []);
  const setLoadingToggle = useCallback(() => setIsLoading((prev) => !prev), []);

  return {
    IsLoading,
    setLoadingFalse,
    setLoadingTrue,
    setLoadingToggle,
  };
};
