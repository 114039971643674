import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Button from "../../../components/ui/Button";
import ReactDrawer from "./../../../components/ui/sliderdrawer";
import ReactStars from "react-stars";
import { univerCityInterface } from "../../../state/actions/universityData";
import CONFIGURATION from "../../../Config";
import moment from "moment";
import { useCourse } from "../../../hooks/useCourse";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import { State } from "../../../state";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import TextInput from "../../../components/ui/TextInput";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

interface CourseCardProps {
  courseData: univerCityInterface;
}

interface reViewData {
  reviewId: string;
  description: string;
  userName: string;
  title: string;
  stars: number;
  isApproved: boolean;
  reviewTime: string;
  reviewTitle: string;
  reviewDescription: string;
}

const CourseCard: React.FC<CourseCardProps> = ({ courseData }) => {
  const currentUser = useSelector((state: State) => state.currentUser);
  const [isOpen, setisOpen] = useState(false);
  const [EnquireModal, setEnquireModal] = useState(false);
  const [ActiveTab, setActiveTab] = useState("");
  const [Loading, setLoading] = useState(false);
  const [reViewData, setreViewData] = useState<reViewData[]>([]);
  const { getAllReView, enquireNow } = useCourse();
  const getAllReview = async () => {
    try {
      const data = await getAllReView(
        courseData.collegeId,
        courseData.courseId
      );
      if (data) {
        setreViewData(data.reviewList);
        setisOpen(true);
      }
    } catch (error) {}
  };

  useEffect(() => {
    setActiveTab("");
  }, [courseData]);

  const handleEnquireNow = async () => {
    if (currentUser.userId) {
      console.log(courseData);

      setLoading(true);
      let dataSend = {
        collegeCourseId: courseData?.collegeCourseId,
        studentFirstName: currentUser.studentDetails.firstName,
        studentLastName: currentUser.studentDetails.lastName,
        emailId: currentUser.studentDetails.user.email,
        phoneNumber: currentUser.studentDetails.phoneNumber,
      };
      try {
        const data = await enquireNow(dataSend);
        if (data) {
          setLoading(false);
          swal(data.message, {
            icon: "success",
          });
        }
      } catch (error) {
        setLoading(false);
      }
    } else {
      setEnquireModal(true);
    }
  };

  const handleSubmitEnquireNow = async (dataSend: {
    collegeCourseId: string | number;
    studentFirstName: string;
    studentLastName: string;
    emailId: string;
    phoneNumber: string;
  }) => {
    try {
      setLoading(true);
      const data = await enquireNow(dataSend);
      if (data) {
        setLoading(false);
        setEnquireModal(false);
        swal(data.message, {
          icon: "success",
        });
      }
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <>
      <div
        className="mb-3 p-3"
        style={{
          backgroundColor: "white",
          borderRadius: 7,
          border: "1px solid lightgrey",
        }}
      >
        <div style={{ display: "flex", paddingBottom: 10 }}>
          <div style={{ width: "12%" }}>
            {courseData.collegeImgPath ? (
              <img
                className="img-fluid"
                style={{ borderRadius: 150, height: 55, width: 55 }}
                src={CONFIGURATION.Image_Base_Url + courseData.collegeImgPath}
              />
            ) : (
              <div
                style={{
                  height: 55,
                  width: 55,
                  backgroundColor: "#0a385c20",
                  borderRadius: "50%",
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: 20,
                }}
              >
                {courseData.collegeName.substring(0, 1)}
              </div>
            )}
          </div>
          <div
            style={{
              width: "88%",
              paddingLeft: 10,
              paddingRight: 10,
              paddingTop: 5,
            }}
          >
            <h5 style={{ fontWeight: "bold", marginBottom: 5 }}>
              {courseData.collegeName}
            </h5>
            <span>{courseData.city + " " + courseData.state}</span>
          </div>
        </div>
        <div
          className="row mb-2"
          style={{ paddingTop: 10, borderTop: "1px solid #e2e2e2" }}
        >
          <div className="col-md-6">
            <div className="row">
              <div className="col-4">
                <span style={{ fontSize: 14, fontWeight: "bold" }}>
                  Course Name:
                </span>
              </div>
              <div className="col-8">
                <span style={{ fontSize: 14 }}>{courseData.courseName}</span>
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <span style={{ fontSize: 14, fontWeight: "bold" }}>Mode:</span>
              </div>
              <div className="col-8">
                <span style={{ fontSize: 14 }}>{courseData.mode}</span>
              </div>
            </div>
            {!courseData.isLead && !courseData.specialCourse && (
              <div className="row">
                <div className="col-4">
                  <span style={{ fontSize: 14, fontWeight: "bold" }}>
                    Date:
                  </span>
                </div>
                <div className="col-8">
                  {courseData.mode == "Blended" ? (
                    <>
                      <span style={{ display: "block" }}>
                        <span
                          style={{
                            fontSize: 14,
                            fontWeight: 600,
                            display: "block",
                          }}
                        >
                          Online
                        </span>
                        <span style={{ fontSize: 14, display: "block" }}>
                          {moment(courseData.scheduleOnlineFromDate).format(
                            "MMM DD"
                          )}{" "}
                          to{" "}
                          {moment(courseData.scheduleOnlineToDate).format(
                            "MMM DD,YYYY"
                          )}
                        </span>
                        <span style={{ fontSize: 12, display: "block" }}>
                          {moment(courseData.scheduleOnlineFromDate).format(
                            "hh:mm A"
                          )}{" "}
                          to{" "}
                          {moment(courseData.scheduleOnlineToDate).format(
                            "hh:mm A"
                          )}
                        </span>
                      </span>
                      <span style={{ display: "block", marginTop: 10 }}>
                        <span
                          style={{
                            fontSize: 14,
                            fontWeight: 600,
                            display: "block",
                          }}
                        >
                          Physical
                        </span>
                        <span style={{ fontSize: 14, display: "block" }}>
                          {moment(courseData.schedulePhysicalFromDate).format(
                            "MMM DD"
                          )}{" "}
                          to{" "}
                          {moment(courseData.schedulePhysicalToDate).format(
                            "MMM DD,YYYY"
                          )}
                        </span>
                        <span style={{ fontSize: 12, display: "block" }}>
                          {moment(courseData.schedulePhysicalFromDate).format(
                            "hh:mm A"
                          )}{" "}
                          to{" "}
                          {moment(courseData.schedulePhysicalToDate).format(
                            "hh:mm A"
                          )}
                        </span>
                      </span>
                    </>
                  ) : courseData.schedulePhysicalFromDate &&
                    courseData.schedulePhysicalToDate ? (
                    <span style={{ display: "block", marginTop: 10 }}>
                      <span
                        style={{
                          fontSize: 14,
                          fontWeight: 600,
                          display: "block",
                        }}
                      >
                        Physical
                      </span>
                      <span style={{ fontSize: 14, display: "block" }}>
                        {moment(courseData.schedulePhysicalFromDate).format(
                          "MMM DD"
                        )}{" "}
                        to{" "}
                        {moment(courseData.schedulePhysicalToDate).format(
                          "MMM DD,YYYY"
                        )}
                      </span>
                      <span style={{ fontSize: 12, display: "block" }}>
                        {moment(courseData.schedulePhysicalFromDate).format(
                          "hh:mm A"
                        )}{" "}
                        to{" "}
                        {moment(courseData.schedulePhysicalToDate).format(
                          "hh:mm A"
                        )}
                      </span>
                    </span>
                  ) : (
                    <span style={{ display: "block" }}>
                      <span
                        style={{
                          fontSize: 14,
                          fontWeight: 600,
                          display: "block",
                        }}
                      >
                        Online
                      </span>
                      <span style={{ fontSize: 14, display: "block" }}>
                        {moment(courseData.scheduleOnlineFromDate).format(
                          "MMM DD"
                        )}{" "}
                        to{" "}
                        {moment(courseData.scheduleOnlineToDate).format(
                          "MMM DD,YYYY"
                        )}
                      </span>
                      <span style={{ fontSize: 12, display: "block" }}>
                        {moment(courseData.scheduleOnlineFromDate).format(
                          "hh:mm A"
                        )}{" "}
                        to{" "}
                        {moment(courseData.scheduleOnlineToDate).format(
                          "hh:mm A"
                        )}
                      </span>
                    </span>
                  )}
                </div>
              </div>
            )}
            {courseData.specialCourse && (
              <div className="row">
                <div className="col-4">
                  <span style={{ fontSize: 14, fontWeight: "bold" }}>
                    Remark:
                  </span>
                </div>
                <div className="col-8">
                  <span
                    style={{
                      fontSize: 14,
                      fontWeight: "bold",
                      display: "block",
                    }}
                  >
                    From:
                  </span>
                  <span style={{ fontSize: 14, display: "block" }}>
                    {courseData.fromRemarks}
                  </span>
                  <span
                    style={{
                      fontSize: 14,
                      fontWeight: "bold",
                      display: "block",
                    }}
                  >
                    To:
                  </span>
                  <span style={{ fontSize: 14, display: "block" }}>
                    {courseData.toRemarks}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className="col-md-6">
            {!courseData.isLead && !courseData.specialCourse && (
              <div className="row">
                <div className="col-4">
                  <span style={{ fontSize: 14, fontWeight: "bold" }}>
                    Seats Available:
                  </span>
                </div>
                <div className="col-8">
                  <span style={{ fontSize: 14 }}>
                    {courseData.seatsAvailable}
                  </span>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-4">
                <span style={{ fontSize: 14, fontWeight: "bold" }}>
                  Ratings:
                </span>
              </div>
              <div className="col-8">
                {courseData.rating == 0 || courseData.rating == 0.0 ? (
                  <span style={{ fontSize: 14 }}>Yet to be reviwed!</span>
                ) : (
                  <>
                    <ReactStars
                      count={5}
                      value={courseData.rating}
                      size={20}
                      edit={false}
                      half={false}
                      color2={"#ffd700"}
                    />
                    <small className="d-block ">
                      {courseData.rating} out of 5
                    </small>
                  </>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <span style={{ fontSize: 14, fontWeight: "bold" }}>Fees:</span>
              </div>
              <div className="col-8">
                <span style={{ fontSize: 14 }}>₹ {courseData.fee}/-</span>
              </div>
            </div>
            <div className="mt-3 text-right">
              {courseData.isLead ? (
                <Button
                  title="Enquire now"
                  className="btn-sm"
                  type="primary"
                  isLoading={Loading}
                  onClick={handleEnquireNow}
                />
              ) : (
                <Link
                  className="btn btn-sm btn-primary"
                  to={`/course-booking/${courseData.scheduleId}`}
                >
                  Book Now{" "}
                  <i className="fa fa-chevron-right" aria-hidden="true"></i>
                </Link>
              )}
            </div>
          </div>
        </div>
        <span
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            paddingTop: 5,
            borderTop: "1px solid #e2e2e2",
          }}
        >
          <span
            className="courseFeature"
            onClick={() => {
              if (ActiveTab === "Amenities") {
                setActiveTab("");
              } else {
                setActiveTab("Amenities");
              }
            }}
            style={{
              fontSize: 14,
              display: "inline-block",
              paddingRight: 20,
              paddingTop: 5,
              cursor: "pointer",
              fontWeight: ActiveTab === "Amenities" ? "bold" : "normal",
              color: ActiveTab === "Amenities" ? "#004289" : "inherit",
              textDecoration: `${
                ActiveTab === "Amenities" ? "underline" : "none"
              }`,
            }}
          >
            Amenities
          </span>
          <span
            className="courseFeature"
            onClick={() => {
              if (ActiveTab === "Review") {
                setActiveTab("");
              } else {
                setActiveTab("Review");
              }
            }}
            style={{
              fontSize: 14,
              display: "inline-block",
              paddingRight: 20,
              paddingTop: 5,
              cursor: "pointer",
              fontWeight: ActiveTab === "Review" ? "bold" : "normal",
              color: ActiveTab === "Review" ? "#004289" : "inherit",
              textDecoration: `${
                ActiveTab === "Review" ? "underline" : "none"
              }`,
            }}
          >
            Review
          </span>
          <span
            className="courseFeature"
            onClick={() => {
              if (ActiveTab === "BookingPolicy") {
                setActiveTab("");
              } else {
                setActiveTab("BookingPolicy");
              }
            }}
            style={{
              fontSize: 14,
              display: "inline-block",
              paddingRight: 20,
              paddingTop: 5,
              cursor: "pointer",
              fontWeight: ActiveTab === "BookingPolicy" ? "bold" : "normal",
              color: ActiveTab === "BookingPolicy" ? "#004289" : "inherit",
              textDecoration: `${
                ActiveTab === "BookingPolicy" ? "underline" : "none"
              }`,
            }}
          >
            Cancellation Policy
          </span>
          <span
            className="courseFeature"
            onClick={() => {
              if (ActiveTab === "Approvals") {
                setActiveTab("");
              } else {
                setActiveTab("Approvals");
              }
            }}
            style={{
              fontSize: 14,
              display: "inline-block",
              paddingRight: 20,
              paddingTop: 5,
              cursor: "pointer",
              fontWeight: ActiveTab === "Approvals" ? "bold" : "normal",
              color: ActiveTab === "Approvals" ? "#004289" : "inherit",
              textDecoration: `${
                ActiveTab === "Approvals" ? "underline" : "none"
              }`,
            }}
          >
            Approvals
          </span>
          <span
            className="courseFeature"
            onClick={() => {
              if (ActiveTab === "Eligibilityreq.") {
                setActiveTab("");
              } else {
                setActiveTab("Eligibilityreq.");
              }
            }}
            style={{
              fontSize: 14,
              display: "inline-block",
              paddingRight: 20,
              paddingTop: 5,
              cursor: "pointer",
              fontWeight: ActiveTab === "Eligibilityreq." ? "bold" : "normal",
              color: ActiveTab === "Eligibilityreq." ? "#004289" : "inherit",
              textDecoration: `${
                ActiveTab === "Eligibilityreq." ? "underline" : "none"
              }`,
            }}
          >
            Eligibility req.
          </span>
        </span>
        {ActiveTab === "Amenities" && (
          <div style={{ minHeight: 50 }}>
            <div className="row">
              {courseData?.amenities &&
                courseData.amenities.length > 0 &&
                courseData.amenities.map((item) => {
                  return (
                    <div className="col-6">
                      <span style={{ paddingTop: 10, display: "block" }}>
                        - {item}
                      </span>
                    </div>
                  );
                })}
              {courseData?.amenities && courseData?.amenities.length === 0 && (
                <div className="col-md-12 text-center">
                  <small className="d-block pt-4">No amenities!</small>
                </div>
              )}
            </div>
          </div>
        )}
        {ActiveTab === "Review" && (
          <div style={{ minHeight: 50 }}>
            <div>
              {courseData.review ? (
                <>
                  <div className="mb-2 mt-3" style={{ display: "flex" }}>
                    <div
                      style={{
                        width: "100%",
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingTop: 5,
                      }}
                    >
                      <h6
                        style={{
                          fontWeight: "bold",
                          marginBottom: 0,
                          fontSize: 16,
                        }}
                      >
                        {courseData.review?.userName}
                      </h6>
                      <ReactStars
                        count={5}
                        value={courseData.review?.stars}
                        size={24}
                        edit={false}
                        half={false}
                        color2={"#ffd700"}
                      />
                      <span style={{ fontSize: 14 }}>
                        {courseData.review?.reviewDescription}
                      </span>
                    </div>
                  </div>
                  <div className="mt-3 text-center">
                    <span
                      onClick={() => getAllReview()}
                      className="text-primary"
                      style={{
                        textDecoration: "underline",
                        fontSize: 14,
                        cursor: "pointer",
                      }}
                    >
                      View All
                    </span>
                  </div>
                </>
              ) : (
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <small className="d-block pt-4">No Review Yet</small>
                </div>
              )}
            </div>
          </div>
        )}
        {ActiveTab === "BookingPolicy" && (
          <div style={{ minHeight: 50 }}>
            {!courseData?.isLead &&
              courseData?.cancellationCharges &&
              courseData?.cancellationCharges?.length > 0 && (
                <div>
                  {courseData?.cancellationCharges?.map((item) => {
                    return (
                      <>
                        <span style={{ marginTop: 10, display: "block" }}>
                          - {item.cancellationTime}
                        </span>
                        <span style={{ display: "block", fontSize: 12 }}>
                          - ₹{item.cancellationCharges} (
                          {item.cancellationPercentage}%)
                        </span>
                      </>
                    );
                  })}
                  <span className="d-block pt-3">
                    *{" "}
                    {
                      "Please note the mentioned time line is with respect to schedule start date and time"
                    }
                  </span>
                </div>
              )}
            {courseData?.isLead && (
              <div>
                <small className="d-block pt-3">
                  {"Kindly co-ordinate with the institute/college"}
                </small>
              </div>
            )}
          </div>
        )}
        {ActiveTab === "Approvals" && (
          <div style={{ minHeight: 50 }}>
            <div className="row">
              {courseData?.courseApprovals &&
                courseData?.courseApprovals.length > 0 &&
                courseData.courseApprovals.map((item) => {
                  return (
                    <div className="col-6">
                      <span style={{ marginTop: 10, display: "block" }}>
                        - {item}
                      </span>
                    </div>
                  );
                })}
              {courseData?.courseApprovals &&
                courseData?.courseApprovals.length === 0 && (
                  <div className="col-md-12 text-center">
                    <small className="d-block pt-4">No Approvals!</small>
                  </div>
                )}
            </div>
          </div>
        )}
        {ActiveTab === "Eligibilityreq." && (
          <div style={{ minHeight: 50 }}>
            <div className="row">
              {courseData?.eligibilities &&
                courseData.eligibilities.length > 0 &&
                courseData.eligibilities.map((item) => {
                  return (
                    <div className="col-6">
                      <span style={{ marginTop: 10, display: "block" }}>
                        - {item}
                      </span>
                    </div>
                  );
                })}
              {courseData?.eligibilities &&
                courseData?.eligibilities.length === 0 && (
                  <div className="col-md-12 text-center">
                    <small className="d-block pt-4">
                      No Eligibility requirement!
                    </small>
                  </div>
                )}
            </div>
          </div>
        )}
      </div>
      <ReactDrawer onClose={() => setisOpen(false)} isOpen={isOpen}>
        <div className="container">
          <div className="row" style={{ paddingTop: 20 }}>
            <div className="col-md-8">
              <h6 style={{ fontWeight: "bold" }}>Reviews</h6>
            </div>
            <div className="col-md-4 text-right">
              <span
                onClick={() => setisOpen(false)}
                className="text-primary"
                style={{ textDecoration: "underline", cursor: "pointer" }}
              >
                Close
              </span>
            </div>
          </div>
          <div>
            {reViewData.length > 0 &&
              reViewData.map((item, index) => {
                return (
                  <div
                    key={index.toString()}
                    className="mb-2 mt-3"
                    style={{ display: "flex" }}
                  >
                    <div
                      style={{
                        width: "100%",
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingTop: 5,
                      }}
                    >
                      <h6
                        style={{
                          fontWeight: "bold",
                          marginBottom: 0,
                          fontSize: 16,
                        }}
                      >
                        {item.userName} - <small>{item.reviewTitle}</small>
                      </h6>
                      <ReactStars
                        count={5}
                        value={item.stars}
                        size={24}
                        edit={false}
                        half={false}
                        color2={"#ffd700"}
                      />
                      <span style={{ fontSize: 14 }}>
                        {item.reviewDescription}
                      </span>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </ReactDrawer>
      <ReactDrawer onClose={() => setEnquireModal(false)} isOpen={EnquireModal}>
        <div className="container">
          <div className="row" style={{ paddingTop: 20 }}>
            <div className="col-md-8">
              <h6 style={{ fontWeight: "bold" }}>Enquire now</h6>
            </div>
            <div className="col-md-4 text-right">
              <span
                onClick={() => setEnquireModal(false)}
                className="text-primary"
                style={{ textDecoration: "underline", cursor: "pointer" }}
              >
                Close
              </span>
            </div>
          </div>
          <div className="mt-4">
            <Formik
              initialValues={{
                studentFirstName: "",
                studentLastName: "",
                emailId: "",
                phoneNumber: "",
              }}
              validationSchema={Yup.object({
                studentFirstName: Yup.string().required(
                  "please enter your first name!"
                ),
                studentLastName: Yup.string().required(
                  "please enter your last name!"
                ),
                emailId: Yup.string()
                  .email("please enter your valid email address!")
                  .required("please enter your email address!"),
                phoneNumber: Yup.string()
                  .matches(phoneRegExp, "Phone number is not valid")
                  .max(10, "please enter your valid mobile number!")
                  .min(10, "please enter your valid mobile number!")
                  .required("please enter your phone number!"),
              })}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                try {
                  setLoading(true);
                  handleSubmitEnquireNow({
                    ...values,
                    phoneNumber: "+91" + values.phoneNumber,
                    collegeCourseId: courseData?.collegeCourseId,
                  });
                  resetForm();
                  setLoading(false);
                } catch (error: any) {
                  setLoading(false);
                  swal(error.response.data.responseMessage, {
                    icon: "error",
                  });
                }
              }}
            >
              <Form className="row">
                <div className="col-md-12 mb-3">
                  <TextInput
                    placeHolder="First name"
                    label="First name"
                    type="text"
                    name="studentFirstName"
                    required
                  />
                </div>
                <div className="col-md-12 mb-3">
                  <TextInput
                    placeHolder="Last name"
                    label="Last name"
                    type="text"
                    name="studentLastName"
                    required
                  />
                </div>
                <div className="col-md-12 mb-3">
                  <TextInput
                    placeHolder="Email Id"
                    label="Email Id"
                    type="text"
                    name="emailId"
                    required
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                    spellCheck="false"
                  />
                </div>
                <div className="col-md-12 mb-3">
                  <TextInput
                    placeHolder="Phone Number"
                    label="Phone Number"
                    type="text"
                    name="phoneNumber"
                    required
                  />
                </div>
                <div className="mb-4 col-md-12">
                  <Button
                    title="Enquire Now"
                    className="btn-block"
                    isLoading={Loading}
                  />
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </ReactDrawer>
    </>
  );
};

export default CourseCard;
