import React, { useEffect, useRef, useState } from "react";
import BackDrop from "./Backdrop";
import "./index.css";

interface TextInputProps {
  isOpen: boolean;
  onClose: () => void;
}

const TextInput: React.FC<TextInputProps> = ({ isOpen, onClose, children }) => {
  return (
    <>
      <div className={isOpen ? "side-drawer open" : "side-drawer"}>
        {children}
      </div>
      {isOpen && <BackDrop onClose={onClose} />}
    </>
  );
};

export default TextInput;
