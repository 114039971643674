import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Spinner from "../../../components/ui/Spinner";
import { useCourse } from "../../../hooks/useCourse";
import moment from "moment";

const Blog: React.FC = () => {
  const { id, from } = useParams();
  const { getBlogDetail } = useCourse();
  const myRef: any = useRef(null);
  const [loder, setloder] = useState(false);
  const [Blog, setBlog] = useState<any>(null);
  const navigate = useNavigate();
  const executeScroll = () => {
    if (myRef && myRef.current) {
      myRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  useEffect(() => {
    executeScroll();
    getdetails();
  }, []);

  const getdetails = async () => {
    setloder(true);
    try {
      const data = await getBlogDetail(id);
      setBlog(data);
      setloder(false);
    } catch (error) {
      setloder(false);
      console.log(error);
    }
  };

  if (loder) {
    return <Spinner />;
  }

  return (
    <>
      <span ref={myRef}></span>
      <section id="about">
        <div className="container">
          <header>
            <h3 className="text-left mb-1" style={{ fontWeight: "bold" }}>
              {Blog?.title}
            </h3>
            <p className="text-left mb-2">{Blog?.description}</p>
            <div className="block-inline">
              <img
                src={require("./../../../assets/img/roundlogocourse.png")}
                className="img-fluid inline-block"
                style={{ height: 25, borderRadius: 25 }}
              />
              <span
                className="inline-block ml-2"
                style={{ fontWeight: 500, fontSize: 12 }}
              >
                {Blog?.displayFirstName} {Blog?.displayLastName}
              </span>
            </div>
          </header>
          <div className="row about-container">
            <div className="col-lg-6">
              <span
                className="d-block mt-2 mb-2"
                style={{ opacity: 0.5, fontSize: 14 }}
              >
                Updated on:{"  "}
                {moment(Blog?.postUpdatedDate, "DD-MM-YYYY HH:mm").format(
                  "DD MMM YY"
                )}{" "}
                ·{" "}
                {moment(Blog?.postUpdatedDate, "DD-MM-YYYY HH:mm").format(
                  "hh:mm A"
                )}
              </span>
            </div>
            <div className="col-lg-6 mt-1 text-right">
              <span
                onClick={() => {
                  navigate(-1);
                }}
                style={{
                  cursor: "pointer",
                  display: "block",
                  marginBottom: 20,
                  color: "#004289",
                }}
              >
                <i className="fa fa-arrow-left" aria-hidden="true"></i>
                <span style={{ marginLeft: 10 }}>Go back</span>
              </span>
            </div>
            <div
              className="col-lg-12 mt-1 blog-content"
              dangerouslySetInnerHTML={{ __html: Blog?.content }}
            ></div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Blog;
