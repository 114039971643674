import { Action, filterDataInterface } from "../actions/filterData";
import { ActionType } from "../action-types/index";

const intitialState = {
  insitute: localStorage.getItem("courseHarbour_insitute") || "",
  state: localStorage.getItem("courseHarbour_state") || "",
  city: localStorage.getItem("courseHarbour_city") || "",
  year: localStorage.getItem("courseHarbour_year") || "",
  month: localStorage.getItem("courseHarbour_month") || "",
  course: localStorage.getItem("courseHarbour_course") || "",
  courseId: localStorage.getItem("courseHarbour_courseId") || "",
  instituteId: localStorage.getItem("courseHarbour_instituteId") || "",
  institute: localStorage.getItem("courseHarbour_instituteName") || "",
};

const bankReducer = (
  state: filterDataInterface = intitialState,
  action: Action
) => {
  switch (action.type) {
    case ActionType.FILTER_DATA:
      return action.payload;
    default:
      return state;
  }
};

export default bankReducer;
