import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCourse } from "../../../hooks/useCourse";
import moment from "moment";
import Spinner from "../../../components/ui/Spinner";
import CONFIGURATION from "../../../Config";

const Blogs: React.FC = () => {
  const [Loading, setLoading] = useState(false);
  const [Blogs, setBlogs] = useState<any>([]);
  const navigate = useNavigate();
  const { getAllBlogs } = useCourse();

  const getAllPromocode = async () => {
    try {
      setLoading(true);
      const data = await getAllBlogs();
      if (data) {
        setBlogs(data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getAllPromocode();
  }, []);

  return (
    <>
      {Loading ? (
        <Spinner />
      ) : (
        <section id="services" className="section-bg mb-5 enable-animation">
          <div className="container">
            <header className="mb-5">
              <span className="h1" style={{ fontSize: 20 }}>
                Trending on CourseHarbour
              </span>
            </header>
            <div className="marquee marquee--hover-pause">
              <ul className="marquee__content">
                {Blogs.slice(0, 5).map((item: any, index: number) => (
                  <li>
                    <div className="row">
                      <div className="col-1">
                        <span
                          className="h1 text-primary"
                          style={{ opacity: 0.3 }}
                        >
                          {index + 1}
                        </span>
                      </div>
                      <div
                        onClick={() => {
                          navigate(`/blog/${item.id}`);
                        }}
                        className="col-8"
                      >
                        <div className="d-flex">
                          <img
                            src={require("./../../../assets/img/roundlogocourse.png")}
                            className="img-fluid inline-block"
                            style={{ height: 25, borderRadius: 25 }}
                          />
                          <span
                            className="inline-block ml-2 d-flex"
                            style={{ fontWeight: 500, fontSize: 12 }}
                          >
                            {item.displayFirstName} {item.displayLastName}
                          </span>
                        </div>
                        <h4 className="title mb-0 max-twoline">{item.title}</h4>
                        <span
                          style={{ fontSize: 12 }}
                          className="d-block mt-1 mb-2 max-twoline-description"
                        >
                          {item.description}
                        </span>
                        <p className="description" style={{ fontSize: 12 }}>
                          {moment(
                            item.postUpdatedDate,
                            "DD-MM-YYYY HH:mm"
                          ).format("DD MMM YY")}{" "}
                          ·{" "}
                          {moment(
                            item.postUpdatedDate,
                            "DD-MM-YYYY HH:mm"
                          ).format("hh:mm A")}
                        </p>
                      </div>
                      <div className="col-3" />
                    </div>
                  </li>
                ))}
              </ul>
              <ul aria-hidden="true" className="marquee__content">
                {Blogs.slice(0, 5).map((item: any, index: number) => (
                  <li>
                    <div className="row">
                      <div className="col-1">
                        <span
                          className="h1 text-primary"
                          style={{ opacity: 0.3 }}
                        >
                          {index + 1}
                        </span>
                      </div>
                      <div
                        onClick={() => {
                          navigate(`/blog/${item.id}`);
                        }}
                        className="col-8"
                      >
                        <div className="d-flex">
                          <img
                            src={require("./../../../assets/img/roundlogocourse.png")}
                            className="img-fluid inline-block"
                            style={{ height: 25, borderRadius: 25 }}
                          />
                          <span
                            className="inline-block ml-2 d-flex"
                            style={{ fontWeight: 500, fontSize: 12 }}
                          >
                            {item.displayFirstName} {item.displayLastName}
                          </span>
                        </div>
                        <h4 className="title mb-0 max-twoline">{item.title}</h4>
                        <span
                          style={{ fontSize: 12 }}
                          className="d-block mt-1 mb-2 max-twoline-description"
                        >
                          {item.description}
                        </span>
                        <p className="description" style={{ fontSize: 12 }}>
                          {moment(
                            item.postUpdatedDate,
                            "DD-MM-YYYY HH:mm"
                          ).format("DD MMM YY")}{" "}
                          ·{" "}
                          {moment(
                            item.postUpdatedDate,
                            "DD-MM-YYYY HH:mm"
                          ).format("hh:mm A")}
                        </p>
                      </div>
                      <div className="col-3" />
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Blogs;
