import React, { useEffect, useRef } from "react";
import Button from "../../../components/ui/Button";
import { useToggle } from "../../../hooks/useToggle";
import { useSelector } from "react-redux";
import { State } from "../../../state";
import swal from "sweetalert";
import { useUser } from "../../../hooks/useUser";

const UserAgreement: React.FC = () => {
  const myRef: any = useRef(null);
  const { IsLoading, setLoadingFalse, setLoadingToggle, setLoadingTrue } =
    useToggle();
  const { deactivateUser } = useUser();
  const currentUser = useSelector((state: State) => state.currentUser);
  const executeScroll = () => {
    if (myRef && myRef.current) {
      myRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  useEffect(() => {
    executeScroll();
  }, []);

  const deactivateAccount = async () => {
    const data = await deactivateUser(currentUser.studentDetails.id);
    if (data.success) {
      swal("Deleted!", "Your imaginary file has been deleted!", "success");
      setTimeout(() => {
        localStorage.removeItem("@coureHarbourLogin");
        localStorage.removeItem("@coureHarbourLoginTime");
        window.location.href = "/";
      }, 1000);
    }
  };
  return (
    <>
      <span ref={myRef}></span>
      <section id="about">
        <div className="container">
          <header className="section-header">
            <h3>Help</h3>
            <p></p>
          </header>
          <div className="row about-container">
            <div className="col-lg-12 content order-lg-1 order-2">
              <ol>
                <li>
                  <b>
                    Is that true we can book a course in just 3 clicks using
                    COURSEHARBOUR?
                  </b>
                  <ol className="mt-2">
                    <li>Enter the desired course and click SEARCH</li>
                    <li>
                      Courses offered by various colleges will be displayed on
                      search results with all required details, click BOOK,
                    </li>
                    <li>
                      Click to make the fee payment and that's it! your courses
                      are booked in 3 clicks without breaking a sweat.
                    </li>
                  </ol>
                </li>
                <li className="mt-4">
                  <b>
                    Is there a mobile application available for COURSEHARBOUR?
                  </b>
                  <p className="mb-1 mt-2">
                    YES! Now book courses at your convenience, we are live on
                  </p>
                  <ol>
                    <li>WEBSITE</li>
                    <li>ANDROID</li>
                    <li>IOS</li>
                  </ol>
                  <p className="mb-1 mt-2">
                    Kindly find the links below, download application now.
                  </p>
                </li>
                <li className="mt-4">
                  <b>
                    Can I trust the approvals a college has listed? Are they
                    verified?
                  </b>
                  <p className="mb-1 mt-2">
                    YES! As COURSEHARBOUR has been developed and run by
                    seafarers we know the importance of approvals, so the
                    approvals are physically verified using our platform. We do
                    all the heavy lifting so that you guys can have a peaceful
                    vacation!
                  </p>
                </li>
                <li className="mt-4">
                  <b>Do I need to be vaccinated to attend courses?</b>
                  <p className="mb-1 mt-2">
                    YES! We kindly request you to be vaccinated and follow all
                    government regulations regarding vaccination and travel.
                    Most of the colleges do not accept the applicant if they are
                    not vaccinated, so get your dose soon.
                  </p>
                </li>
                <li className="mt-4">
                  <b>How do I get the link to attend online courses?</b>
                  <p className="mb-1 mt-2">
                    We at COURSEHARBOUR will forward all your details including
                    the contact details to the respective institute where you
                    have booked the course, from there the institute will take
                    charge and they will contact you to provide all necessary
                    details. WRT kindly provide active contact details which you
                    use during registration.
                  </p>
                </li>
                <li className="mt-4">
                  <b>
                    How can I change a wrongly entered fields after
                    registration?
                  </b>
                  <p className="mb-1 mt-2">
                    Except for mobile number and rank other details can't be
                    edited after registration. You can mail us on
                    sailorsupport@courseharbour.com to change any details
                    entered wrongly.
                  </p>
                </li>
              </ol>
              {currentUser.isLoggedIn && (
                <div className="mt-5 pt-5 text-center">
                  <Button
                    onClick={() => {
                      swal({
                        title: "Are you sure?",
                        text: "Are you sure that you want to deactivate account?",
                        icon: "warning",
                        buttons: ["Cancel", true],
                        dangerMode: true,
                      }).then((willDelete) => {
                        if (willDelete) {
                          deactivateAccount();
                        } else {
                          swal("Your account is safe");
                        }
                      });
                    }}
                    title="Deactivate Account"
                    type="danger"
                    isLoading={IsLoading}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UserAgreement;
