import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { State } from "../../../state";
import Features from "../../components/home/Features";
import Header from "../../components/home/Header";
import Blogs from "../../components/home/Blogs";

const Home: React.FC = () => {
  const myRef: any = useRef(null);
  const currentUser = useSelector((state: State) => state.currentUser);
  const navigate = useNavigate();
  const executeScroll = () => {
    if (myRef && myRef.current) {
      myRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  useEffect(() => {
    executeScroll();
    document.title = "Courseharbour Appoinment Booking of Colleges";
  }, []);

  useEffect(() => {
    if (currentUser.isLoggedIn) {
      setTimeout(() => {
        let courseLoginRemembered = localStorage.getItem(
          "courseLoginRemembered"
        );
        if (courseLoginRemembered) {
          localStorage.removeItem("courseLoginRemembered");
          navigate(courseLoginRemembered);
        }
      }, 1000);
    }
  }, [currentUser.isLoggedIn]);

  return (
    <>
      <span ref={myRef}></span>
      <Header />
      <div className="section-bg pt-5">
        <img
          className="img-fluid"
          src={require("./../../../assets/img/Group 554.png")}
        />
      </div>
      <Features />
      <Blogs />
    </>
  );
};

export default Home;
