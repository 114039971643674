import { Dispatch } from "redux";
import { ActionType } from "../action-types";
import { Action, siteSettingInterface } from "../actions";

export const updateSiteSetting = (data: siteSettingInterface) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_SITE_SETTINGS,
      payload: data,
    });
  };
};
