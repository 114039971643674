import React, {
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
  Ref,
} from "react";
import ReactDOM from "react-dom";
import { Formik, Form, useField } from "formik";

const RadioButton = ({ type, className, label, ...props }: any) => {
  const [field, meta] = useField(props);

  return (
    <>
      <div className="input-group" style={{ alignItems: "center" }}>
        <label
          className="label"
          style={{
            display: "inline-block",
            marginBottom: 0,
            paddingLeft: 5,
            cursor: "pointer",
          }}
        >
          <input
            type={"radio"}
            className={` ${className}`}
            {...field}
            {...props}
          />
          <span style={{ display: "inline-block", marginLeft: 5 }}>
            {label}
          </span>
        </label>
      </div>
      {meta.touched && meta.error ? (
        <div className="text-danger" style={{ fontSize: 12, marginTop: 5 }}>
          {meta.error}
        </div>
      ) : null}
    </>
  );
};

export default RadioButton;
