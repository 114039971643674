import { Dispatch } from "redux";
import { ActionType } from "../action-types";
import { Action, filterDataInterface } from "../actions/filterData";

export const updatefilterData = (data: filterDataInterface) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.FILTER_DATA,
      payload: data,
    });
  };
};
