import React, { useEffect } from "react";
import Footer from "./component/Footer";
import Navbar from "./component/Navbar";

interface LayoutProps {
  children: JSX.Element;
  title?: string;
}

const Layout: React.FC<LayoutProps> = ({ children, title }) => {
  useEffect(() => {}, []);
  return (
    <>
      <Navbar />
      {children}
      <Footer />
    </>
  );
};

export default Layout;
