export enum ActionType {
  // Update site settings
  UPDATE_SITE_SETTINGS = "UPDATE_SITE_SETTINGS",

  //User API
  CURRENT_USER = "CURRENT_USER",
  LOGOUT_USER = "LOGOUT_USER",
  UPDATE_STUDENT = "UPDATE_STUDENT",

  //update course filter data
  UPDATE_COURSE_LIST = "UPDATE_COURSE_LIST",
  UPDATE_COLLAGE_LIST = "UPDATE_COLLAGE_LIST",

  //Filter Data
  FILTER_DATA = "FILTER_DATA",

  //University Data
  UNIVERSITY_DATA = "UNIVERSITY_DATA",

  //University Data
  UPDATE_RECENT_SEARCH = "UPDATE_RECENT_SEARCH",
}
