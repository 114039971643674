import React, { useEffect, useRef } from "react";

const AboutusMore: React.FC = () => {
  const myRef: any = useRef(null);
  const executeScroll = () => {
    if (myRef && myRef.current) {
      myRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  useEffect(() => {
    executeScroll();
  }, []);

  return (
    <>
      <span ref={myRef}></span>
      <section id="about">
        <div className="container">
          <header className="section-header">
            <h3>About Us</h3>
            <p></p>
          </header>
          <div className="row about-container">
            <div className="col-lg-12 content order-lg-1 order-2">
              <p>
                Courseharbour is a first of its kind course booking platform
                exclusively developed to serve the maritime community of India
                and soon around the world. The platform itself has been
                brainstormed, developed and run by seafarers which makes it even
                more special. We wanted to solve some of the day-to-day problems
                faced by us (seafarers) in the maritime industry and also to
                make some technological advancements in our field on par with
                the advancements in other sectors, first step of which is
                “Courseharbour”. Always we have some courses to do when we sign
                off and what we do is to search and call institutions for
                availability/details, through Courseharbour we intend to bring
                in a seamless course search, booking, course managing experience
                to seafarers (Literally on just 3 clicks) and as well as to
                maritime institutions, all in one website/app. Yes, you read
                that right, in just 3 clicks! Courseharbour is available on all
                platforms i.e., desktop, android and apple. We welcome you
                onboard!
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutusMore;
