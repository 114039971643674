import React, { useEffect, useRef, useState } from "react";
import AdvanceSearch from "../../components/bookcourses/AdvanceSearch";
import CourseCard from "../../components/bookcourses/CourseCard";
import ReactDrawer from "./../../../components/ui/sliderdrawer";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../../state";
import { univerCityInterface } from "../../../state/actions/universityData";
import { number } from "yup/lib/locale";
import Spinner from "../../../components/ui/Spinner";
const BookCourses: React.FC = () => {
  const [isOpen, setisOpen] = useState(false);
  const [Loader, setLoader] = useState(true);
  const myRef: any = useRef(null);
  const UnivercityData = useSelector((state: State) => state.universityData);
  const [uniData, setuniData] = useState<univerCityInterface[]>([]);
  const [selectSortTag, setselectSortTag] = useState("Start Date");
  const executeScroll = () => {
    if (myRef && myRef.current) {
      myRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  useEffect(() => {
    executeScroll();
  }, []);

  useEffect(() => {
    setuniData(UnivercityData);
    console.log(UnivercityData);

    executeScroll();
    setTimeout(() => setLoader(false), 1000);
  }, [UnivercityData]);

  useEffect(() => {
    if (uniData.length) {
      var copy: univerCityInterface[] = [...UnivercityData];
      if (selectSortTag == "Fees") {
        const sortedActivities = copy.sort((a: any, b: any) =>
          b.fee < a.fee ? 1 : -1
        );
        setuniData(sortedActivities);
      }
      if (selectSortTag == "Seats Available") {
        const sortedActivities = copy.sort((a: any, b: any) => {
          return b.seatsAvailable < a.seatsAvailable ? 1 : -1;
        });
        setuniData(sortedActivities);
      }
      if (selectSortTag == "Start Date") {
        setuniData(UnivercityData);
      }
    }
  }, [selectSortTag]);

  return (
    <>
      <span ref={myRef}></span>
      <section
        id="about"
        style={{ minHeight: "90vh", backgroundColor: "transparent" }}
      >
        <div className="container">
          <header>
            <h3 className="mb-1">Book Courses</h3>
            <p>Search course & colleges and can book from here.</p>
          </header>
          <div className="row about-container">
            <div className="col-md-3 d-none d-md-block">
              <AdvanceSearch isclose={() => {}} />
            </div>
            <div className="col-md-9">
              <div className="row mb-3">
                <div className="col-6">
                  <span style={{ fontWeight: "bold", display: "block" }}>
                    Search Results
                  </span>
                  <span style={{ fontSize: 12 }}>
                    {uniData.length} Listing's found
                  </span>
                </div>
                {uniData.length > 0 && (
                  <div className="col-6 text-right">
                    <span
                      style={{
                        display: "block",
                        fontWeight: "bold",
                        fontSize: 14,
                      }}
                    >
                      Sort By:
                    </span>
                    <span style={{ display: "block" }}>
                      <div className="dropdown">
                        <button
                          style={{ fontSize: 14 }}
                          className="btn btn-default dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          {selectSortTag}
                        </button>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <a
                            onClick={() => {
                              setselectSortTag("Start Date");
                            }}
                            className="dropdown-item"
                            href="#"
                          >
                            Start Date
                          </a>
                          <a
                            onClick={() => {
                              setselectSortTag("Fees");
                            }}
                            className="dropdown-item"
                            href="#"
                          >
                            Fees <small>(low to high)</small>
                          </a>
                          <a
                            onClick={() => {
                              setselectSortTag("Seats Available");
                            }}
                            className="dropdown-item"
                            href="#"
                          >
                            Seats Available
                          </a>
                        </div>
                      </div>
                    </span>
                  </div>
                )}
              </div>
              {uniData.length > 0 ? (
                uniData.map((item, index) => {
                  return (
                    <CourseCard courseData={item} key={index.toString()} />
                  );
                })
              ) : (
                <div
                  style={{
                    height: "50%",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  {Loader ? (
                    <Spinner />
                  ) : (
                    <span style={{ fontSize: 16, fontWeight: "bold" }}>
                      we will come back with more listings as per your
                      requirement in the future!!!!!
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <ReactDrawer onClose={() => setisOpen(false)} isOpen={isOpen}>
        <div className="container">
          {isOpen && (
            <AdvanceSearch
              isclose={() => {
                setisOpen(false);
              }}
            />
          )}
        </div>
      </ReactDrawer>
      <button
        style={{
          position: "fixed",
          bottom: 0,
          zIndex: 1,
          width: "100%",
          backgroundColor: "white",
          color: "#212529",
          fontWeight: "bold",
          borderRadius: 0,
          borderTop: "1px solid lightgrey",
        }}
        onClick={() => setisOpen(true)}
        type="button"
        className="btn btn-default d-lg-none"
      >
        <i className="fa fa-filter" aria-hidden="true"></i> Advance Search
      </button>
    </>
  );
};

export default BookCourses;
