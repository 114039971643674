import { useCallback, useEffect, useState } from "react";
import axios, { AxiosError } from "axios";
import {
  StudentDetails,
  userInterface,
  UserRegisterRequest,
} from "../state/actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../state/reducers";
import { bindActionCreators } from "redux";
import { userActionCreator } from "../state";
import moment from "moment";

export const useUser = () => {
  const dispatch = useDispatch();
  const { updateStudentDetails } = bindActionCreators(
    userActionCreator,
    dispatch
  );
  const currentUser = useSelector((state: State) => state.currentUser);

  //Register new user
  const register = useCallback(async (body: UserRegisterRequest) => {
    const { data, status, statusText } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/student/auth/register`,
      body
    );
    return data;
  }, []);

  //Login existing user
  const login = useCallback(
    async (body: { email: string; password: string }) => {
      const { data, status, statusText } = await axios.post<userInterface>(
        `${process.env.REACT_APP_BASE_URL}/student/auth/login`,
        body
      );
      return data;
    },
    []
  );

  //Refresh token of logged user
  const refreshToken = useCallback(async () => {
    let getstorageData = localStorage.getItem("@coureHarbourLogin") || "";
    const { data, status, statusText } = await axios.post<userInterface>(
      `${process.env.REACT_APP_BASE_URL}/refresh`,
      {},
      {
        headers: {
          Authorization: "Bearer " + JSON.parse(getstorageData).token,
          refresh: JSON.parse(getstorageData).refreshToken,
        },
      }
    );
    getStundentDetails();
    return data;
  }, []);

  //get student details
  const getStundentDetails = useCallback(async () => {
    let getstorageData = localStorage.getItem("@coureHarbourLogin") || "";
    const { data, status, statusText } = await axios.get<StudentDetails>(
      `${process.env.REACT_APP_BASE_URL}/student/me`,
      {
        headers: {
          Authorization: "Bearer " + JSON.parse(getstorageData).token,
        },
      }
    );
    updateStudentDetails(data);
    return data;
  }, []);

  //Update user profile
  const updateProfile = useCallback(
    async (
      body: {
        rank: string;
        phoneNumber: string;
        passportNumber: string;
      },
      id: number
    ) => {
      let getstorageData = localStorage.getItem("@coureHarbourLogin") || "";
      const { data, status, statusText } = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/student/${id}`,
        body,
        {
          headers: {
            Authorization: "Bearer " + JSON.parse(getstorageData).token,
          },
        }
      );
      return data;
    },
    []
  );

  //deactivate user
  const deactivateUser = useCallback(async (id: number) => {
    let getstorageData = localStorage.getItem("@coureHarbourLogin") || "";
    const { data, status, statusText } = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/student/deactivateStudent/${id}`,
      {
        headers: {
          Authorization: "Bearer " + JSON.parse(getstorageData).token,
        },
      }
    );
    return data;
  }, []);

  //Contact us
  const sendContactMail = useCallback(
    async (body: { name: string; email: string; message: string }) => {
      let getstorageData = localStorage.getItem("@coureHarbourLogin") || "";
      const { data, status, statusText } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/contactus/sendmail`,
        body
      );
      return data;
    },
    []
  );

  //ForgotPassWord
  const sendForgotLink = useCallback(async (body) => {
    let getstorageData = localStorage.getItem("@coureHarbourLogin") || "";
    const { data, status, statusText } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/reset`,
      body,
      {
        headers: { "Content-Type": `application/json` },
      }
    );
    return data;
  }, []);

  //Reset Password
  const resetPassword = useCallback(async (body) => {
    let getstorageData = localStorage.getItem("@coureHarbourLogin") || "";
    const { data, status, statusText } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/reset/password`,
      body,
      {
        headers: { "Content-Type": `application/json` },
      }
    );
    return data;
  }, []);

  //ImageUpload
  const ImageUpload = useCallback(async (body: any) => {
    let getstorageData = localStorage.getItem("@coureHarbourLogin") || "";
    const { data, status, statusText } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/image/upload`,
      body,
      {
        headers: {
          Authorization: "Bearer " + JSON.parse(getstorageData).token,
        },
      }
    );
    return data;
  }, []);

  //ImageUploadUpdateData
  const ImageUploadUpdateData = useCallback(async (body: any, id: number) => {
    let getstorageData = localStorage.getItem("@coureHarbourLogin") || "";
    const { data, status, statusText } = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}/student/${id}/update-image`,
      body,
      {
        headers: {
          Authorization: "Bearer " + JSON.parse(getstorageData).token,
        },
      }
    );
    return data;
  }, []);

  return {
    login,
    refreshToken,
    register,
    getStundentDetails,
    updateProfile,
    sendContactMail,
    sendForgotLink,
    ImageUpload,
    ImageUploadUpdateData,
    resetPassword,
    deactivateUser,
  };
};
