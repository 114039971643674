import { Dispatch } from "redux";
import { ActionType } from "../action-types";
import {
  CollageDetail,
  CourseDataAction,
  CourseDetail,
} from "../actions/courseDataActions";

export const updateCourses = (data: CourseDetail[]) => {
  return (dispatch: Dispatch<CourseDataAction>) => {
    dispatch({
      type: ActionType.UPDATE_COURSE_LIST,
      payload: data,
    });
  };
};

export const updateCollages = (data: CollageDetail[]) => {
  return (dispatch: Dispatch<CourseDataAction>) => {
    dispatch({
      type: ActionType.UPDATE_COLLAGE_LIST,
      payload: data,
    });
  };
};

export const updateRecentSearch = (
  data: { label: string; value: string }[]
) => {
  return (dispatch: Dispatch<CourseDataAction>) => {
    dispatch({
      type: ActionType.UPDATE_RECENT_SEARCH,
      payload: data,
    });
  };
};
